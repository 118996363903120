import * as types from '../ActionTypes';

const initialState = {
  vouchersListLoader: false,
  vouchersListSuccess: false,
  VouchersList: [],
  vouchersListPagination: {},
  offsetValue: 0,
  searchValue: '',
  sortBy: null,
  sortOrder: null,
  addVoucherLoader: false,
  addVoucherSuccess: false,
  addVoucherDetail: {},
  deleteVoucherLoader: false,
  deleteVoucherSuccess: false
}

export const vouchers = (state = initialState, { payload, key, type }) => {
  switch (type) {
    case types.GET_VOUCHERS_LIST_INITIATE:
      return {
        ...state,
        vouchersListLoader: true,
        vouchersListSuccess: false
      }

    case types.GET_VOUCHERS_LIST_SUCCESS:
      return {
        ...state,
        vouchersListLoader: false,
        vouchersListSuccess: true,
        addVoucherSuccess: false,
        VouchersList: payload.list,
        vouchersListPagination: payload.pagination
      }

    case types.GET_VOUCHERS_LIST_FAILURE:
      return {
        ...state,
        vouchersListLoader: false,
      }

    case types.ADD_VOUCHER_INITIATE:
      return {
        ...state,
        addVoucherLoader: true,
        addVoucherSuccess: false
      }

    case types.ADD_VOUCHER_SUCCESS:
      return {
        ...state,
        addVoucherLoader: false,
        addVoucherSuccess: true,
        addVoucherDetail: payload
      }

    case types.ADD_VOUCHER_FAILURE:
      return {
        ...state,
        addVoucherLoader: false,
      }

    case types.UPDATE_VOUCHERS_STATES:
      state[key] = payload
      return { ...state, };

    case types.CLEAR_VOUCHERS_DATA:
      return {
        ...state,
        vouchersListSuccess: false,
        addVoucherSuccess: false
      }

    case types.DELETE_VOUCHER_INITIATE:
      return {
        ...state,
        deleteVoucherLoader: true,
        deleteVoucherSuccess: false
      }

    case types.DELETE_VOUCHER_SUCCESS:
      console.log(payload, "delete in reducer****", state)
      let gameIndexToDelete = state.VouchersList.findIndex(value => value._id === payload)
      state.VouchersList.splice(gameIndexToDelete, 1)
      return {
        ...state,
        deleteVoucherLoader: false,
        deleteVoucherSuccess: true,
        VouchersList: [...state.VouchersList]
      }

    case types.DELETE_VOUCHER_FAILURE:
      return {
        ...state,
        deleteVoucherLoader: false,
      }


    default:
      return state
  }
}

