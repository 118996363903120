import React, { useState, useEffect } from "react"
import { Layout, Pagination } from 'antd';
import images from '../../themes/appImage'
import appconstant from "../../themes/appconstant";

import {
    BrowserRouter as Router,
    Link,
    useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from 'antd';
import { FirebaseMethods } from '../../helper/firebase'
import { getContactList, deleteContact, updateContactUsAction } from '../../redux/Action/contactUs'
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import { toast } from "react-toastify";

const { Header, Content, Footer, Sider } = Layout;



const ContactUS = (props) => {

    const [isVisible, setVisible] = useState(false)
    const {
        contactListArr,
        contactPagination,
        offsetValue,
        searchValue,
        sortBy,
        sortOrder,
    } = useSelector((state) => state.contactUs)
    const history = useHistory();
    const dispatch = useDispatch()

    const handlewClick = () => {
        setVisible(!isVisible)
    }
    useEffect(() => {
        document.title = 'Disparity Trap';
        window.scrollTo(0, 0)
        let data = {
            "offset": searchValue ? 0 : offsetValue,
            "limit": 20,
            "search": searchValue,
            "sort": "",
            "order": 1
        }
        dispatch(getContactList(data))

    }, [])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleAdd, setIsModalVisibleAdd] = useState(false);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const showModaladd = () => {
        setIsModalVisibleAdd(true);
    };

    const handleOkadd = () => {
        setIsModalVisibleAdd(false);
    };

    const handleCanceladd = () => {
        setIsModalVisibleAdd(false);
    };
    const deleteModel = (record) => {

        if (!navigator.onLine) {
            toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
        } else {
            Modal.confirm({
                title: "Delete Messages",
                content: `Are you sure, you want to delete messages?`,
                okText: 'Yes',
                centered: true,
                cancelText: 'No',
                onOk() {
                    if (!navigator.onLine) {
                        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                    } else {
                        let data = {
                            "offset": searchValue ? 0 : offsetValue,
                            "limit": 20,
                            "search": searchValue,
                            "sort": "",
                            "order": 1
                        }
                        dispatch(deleteContact(record._id, data, record.userId._id))
                    }

                },
                onCancel() {

                },
                className: "new-button"
            });
        }
    };


    const dataSource = [{
        key: '1',
        username: 'John pal',
        email: 'John@yopmail.com',
        date: '01/01/2022 8:00 PM',
    },
    ];

    const columns = [{
        title: 'Sr. No.',
        dataIndex: 'srno',
        key: 'srno',
        sorter: true,
        render: (value, item, index) => <div style={{ width: '60px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{(index + 1 + offsetValue)}</div>,
    },
    {
        title: 'Username',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (value, item, index) => {
            return (
                // <span>{item.userId.name}</span>
                <div style={{ width: '160px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.userId?.name}</div>
            )
        }
    },
    {
        title: 'Email Address ',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        render: (value, item, index) => {
            return (
                <span>{item?.userId?.email}</span>
            )
        }
    },
    {
        title: 'Date/Time of Message ',
        dataIndex: 'createdOn',
        key: 'createdOn',
        sorter: true,
        render: (value, item, index) => item?.date ? moment(item?.date).format('DD/MM/YYYY hh:mm A') : 'N/A',
    },
    {
        title: 'Action',
        dataIndex: 'Posted',
        key: 'Posted',
        render: (posted, record) => {
            return (<div>
                <Link
                    to={{
                        pathname: `chatr/`,
                        state: { record }
                    }}
                >
                    <button type="submit sizetext" style={{ width: 'auto' }}>{appconstant.viewmessage}</button>
                </Link>
                <button type="submit" onClick={() => deleteModel(record)}>{appconstant.delete}</button>
            </div>
            )
        }
    },
    ];

    const _onSearchData = (e) => {
        if (!navigator.onLine) {
            toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
        } else {
            dispatch(updateContactUsAction(e.target.value, 'searchValue'))
            let data = {
                "offset": 0,
                "limit": 20,
                "search": e.target.value,
                "sort": "",
                "order": 1
            }
            dispatch(getContactList(data))
        }

    }

    const handlTableChange = (pagination, filter, sorter) => {
        console.log("this is event = ", sorter)
        if (!navigator.onLine) {
            toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
        } else {
            if (sorter.order == "ascend") {
                const data = {
                    search: searchValue,
                    sort: sorter.field,
                    order: 1,
                    offset: searchValue ? 0 : offsetValue,
                    limit: 20,
                }
                dispatch(updateContactUsAction(1, 'sortOrder'))
                dispatch(updateContactUsAction(sorter.field, 'sortBy'))
                dispatch(getContactList(data, history))

            } //To fetch listing in descending order
            else if (sorter.order === "descend") {
                const data = {
                    search: searchValue,
                    sort: sorter.field,
                    order: -1,
                    offset: searchValue ? 0 : offsetValue,
                    limit: 20,
                }
                dispatch(updateContactUsAction(-1, 'sortOrder'))
                dispatch(updateContactUsAction(sorter.field, 'sortBy'))
                dispatch(getContactList(data, history))
            } //To fetch listing in normal order
            else {
                const data = {
                    search: searchValue,
                    sort: null,
                    order: null,
                    offset: searchValue ? 0 : offsetValue,
                    limit: 20,
                }
                dispatch(updateContactUsAction(null, 'sortOrder'))
                dispatch(updateContactUsAction(null, 'sortBy'))
                dispatch(getContactList(data, history))
            }
        }
    }

    return (
        <div>
            <Layout>
                <Sidebar />
                <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />

                <Layout className="new_layout">
                    <HeaderAdmin handlewClick={handlewClick} {...props} />
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>{appconstant.contactus}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Content style={{ margin: '24px 16px 0' }}>
                        {/* <div className="top_button_item">
                         <button type="submit" className="button-list top_new" onClick={showModaladd}>Add </button>
                         </div> */}
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            <div className="content-e">
                                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                                    <h6 class="text-white text-capitalize ps-3">{appconstant.contactus}</h6>
                                    <input type="text" value={searchValue} onChange={(e) => _onSearchData(e)} className="search-bar" placeholder="Search" />
                                </div>
                                <Table dataSource={contactListArr}
                                    columns={columns}
                                    showSorterTooltip={false}
                                    pagination={false}
                                    onChange={handlTableChange}
                                />
                                <Pagination
                                    pageSize="20"
                                    total={contactPagination?.totalCount}
                                    onChange={(page) => {
                                        if (!navigator.onLine) {
                                            toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                                        } else {
                                            let data = (page - 1) * 20;
                                            const obj = {
                                                search: searchValue,
                                                sort: sortBy,
                                                order: sortOrder,
                                                offset: data,
                                                limit: 20,
                                            }
                                            dispatch(updateContactUsAction(data, 'offsetValue'))
                                            dispatch(getContactList(obj, history))
                                        }
                                    }}
                                    current={contactPagination?.currentPage}
                                    showSizeChanger={false}
                                />
                            </div>
                        </div>
                    </Content>
                </Layout>
            </Layout>
            {/* {vouchercode()}
            {vouchercodeadd()} */}
        </div>
    )
}
export default ContactUS;
