//Local URL
// export const SERVER_URL = 'http://192.168.3.173:3000/api/v3';
// export const imageUrl = 'http://192.168.3.173:3000/';

//Staging URL
// export const SERVER_URL = 'http://1.6.98.141:3001/api/v3';
// export const imageUrl = 'http://1.6.98.141:3001/';

// Public URL
// export const SERVER_URL = 'http://1.6.98.139:4010/api/v3';
// export const imageUrl = 'http://1.6.98.139:4010/';

// Live URL
export const SERVER_URL = 'https://api.disparitytrap.net/api/v3';
export const imageUrl = 'https://api.disparitytrap.net/';