import React from "react";
import './styles.css';
import { removeEmojis } from '../common/utils'

const Input = ({
    placeholder,
    onChangeText,
    onBlur,
    type,
    value,
    touched,
    disabled,
    error,
    addCategoryForm,
    maxLength,
    fieldType,
    noMargin,
    ...props
}) => {


    function removeEmojis(string) {
        var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        return string.replace(regex, '');
    }

    const _checkSpecialChar = (fieldValue) => {
        let specialChar = ['`', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '+', '=', '{', '}', '[', ']', '|', ';', ':', '"', ',', '<', '>', '?', '/', '~', '.', '_']
        let isSpecialChar = false;
        for (let i in specialChar) {
            if (fieldValue.includes(specialChar[i])) {
                isSpecialChar = true
            }
        }

        return isSpecialChar
    }

    // const _checkSpecialChar = (fieldValue) => {
    //     console.log('_checkSpecialChar', fieldValue)
    //     let specialChar = ['`', '+', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '+', '=', '{', '}', '[', ']', '|', ';', ':', '"', ',', '<', '>', '?', '/', '~', '_']
    //     let isSpecialChar = false;
    //     for (let i in specialChar) {
    //       if (fieldValue.includes(specialChar[i])) {
    //         isSpecialChar = true
    //       }
    //     }
    //     return isSpecialChar
    //   }


    return (
        <div className={addCategoryForm ? "txtb mb-0" : noMargin ? null : "txtb"}>
            <input
                className="form-control"
                maxLength={maxLength}
                type={type}
                // className="ant-input"
                placeholder={placeholder}
                disabled={disabled}
                value={fieldType == 'email' ? value?.trim().toLowerCase() : type == 'noSpace' ? value?.trim() : value}
                onKeyDown={(e) => {
                    if (fieldType === 'phone') {
                        if ((e.key === " " && e.target.value.length < 1) || e.key === "-" || e.key === "." || e.key === "e") {
                            e.preventDefault()
                        }
                    } else {
                        if (e.keyCode == 32 && (fieldType == 'email' || fieldType == 'password')) {
                            // e.preventDefault()
                            onChangeText(e.target.value.replace(/[\W_]+/g, ' '))
                        } else if (fieldType === 'spaceRestrict') {
                            if (e.key === " ") {
                                e.preventDefault();
                            }
                        }
                    }

                }}
                onSelect={(e) => {
                    if (fieldType === 'phone') {
                        if (e.target.value === "") {
                            onChangeText(e.target.value.replace(/[\W_]+/g, ' '))
                        }
                    }
                }}
                onChange={(e) => {
                    console.log('checkData', e.target.value)
                    let data = fieldType === "addFaqQuestion" ? e.target.value : removeEmojis(e.target.value)
                    if (fieldType === 'userName') {

                        data = removeEmojis(e.target.value).toLowerCase()
                    }
                    if (type == 'password') {
                        if (data.charAt(0) !== " ") {
                            onChangeText(data.replace(/\s/g, ''))
                        }
                    } else if (fieldType == 'email') {
                        var letterNumber = /^[0-9a-zA-Z]+$/
                        if ((data.match(letterNumber))) {
                            if (_checkSpecialChar(data)) {
                                e.preventDefault()
                            } else if (/[!@#$%^&*()+~\-=\[\]{};'`:"\\|,<>\/?]+/.test(data)) {
                                onChangeText(data.replace(/[&\/\\#,+()$%'"`:*?@~!^=;<>{}]/g, ""))
                            } else {
                                onChangeText(data.replace(/\s/g, ""))
                            }
                        } else {
                            let textData = data.split(" ").join("")
                            onChangeText(textData.replace(/[\W_]+/g, ' '))
                        }

                    } else if (fieldType === 'phone') {
                        console.log('e.target.value', e.target.value)

                        const re = /^[0-9\b\+]+$/;
                        if (re.test(e.target.value)) {
                            onChangeText(e.target.value)
                        } else {
                            if (e.target.value !== '') {

                            } else {
                                onChangeText('')
                            }
                        }

                        // data = data.replace(/[-[\]{}()+-.*+?.,\\^$|#\s]/g, '\\$&')
                        // data = data.slice(0, 15)
                        // return onChangeText(data.replace(/\s/g, ''))
                    } else {
                        if (data == ' ') {
                            onChangeText("")
                        } else {
                            if (fieldType == 'title') {
                                onChangeText(data)
                            } else {
                                onChangeText(data.trimLeft())
                            }
                        }
                    }
                }}
                onBlur={(value) => onBlur(value)}
            />
            {console.log('addCategoryForm', addCategoryForm, touched, error)}
            {addCategoryForm ?
                <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {<div className="errorInput-categoryForm pl-3">{error || touched}</div>}
                    <div className="category-mangement-message-count pr-3">{value ? `${value.length}/20` : '0/20'}</div>
                </div>
                :
                <div className="errorInput">{error || touched}</div>
            }

        </div>
    );
};

export default Input;