import * as types from '../ActionTypes';
import { usersList } from '../Api';

const initialState = {
  gamesListLoader: false,
  gamesListSuccess: false,
  gamesList: [],
  offsetValue: 0,
  searchValue: '',
  sortBy: null,
  sortOrder: null,
  startDate: null,
  endDate: null,
  gamesListPagination: {},
  exportGamesListLoader: false,
  exportGamesListSuccess: false,
  exportGamesList: [],
  preListedListLoader: false,
  preListedListSuccess: false,
  preListedList: [],
  preListedOffsetValue: 0,
  preListedSearchValue: '',
  preListedSortBy: null,
  preListedSortOrder: null,
  preListedListPagination: {},
  addPreListedWordLoader: false,
  addPreListedWordSuccess: false,
  editPreListedWordLoader: false,
  editPreListedWordSuccess: false,
  editPreListedWordDetail: {},
  deletePreListedWordLoader: false,
  deletePreListedWordSuccess: false
}

export const games = (state = initialState, { payload, key, type }) => {
  switch (type) {
    case types.GET_GAMES_LIST_INITIATE:
      return {
        ...state,
        gamesListLoader: true,
        gamesListSuccess: false
      }

    case types.GET_GAMES_LIST_SUCCESS:
      return {
        ...state,
        gamesListLoader: false,
        gamesListSuccess: true,
        gamesList: payload.list,
        gamesListPagination: payload.pagination
      }

    case types.GET_GAMES_LIST_FAILURE:
      return {
        ...state,
        gamesListLoader: false,
      }

    case types.EXPORT_GAMES_LIST_INITIATE:
      return {
        ...state,
        exportGamesListLoader: true,
        exportGamesListSuccess: false
      }

    case types.EXPORT_GAMES_LIST_SUCCESS:
      return {
        ...state,
        exportGamesListLoader: false,
        exportGamesListSuccess: true,
        exportGamesList: payload
      }

    case types.EXPORT_GAMES_LIST_FAILURE:
      return {
        ...state,
        exportGamesListLoader: false,
      }

    case types.GET_PRE_LISTED_LIST_INITIATE:
      return {
        ...state,
        preListedListLoader: true,
        preListedListSuccess: false
      }

    case types.GET_PRE_LISTED_LIST_SUCCESS:
      return {
        ...state,
        preListedListLoader: false,
        preListedListSuccess: true,
        preListedList: payload.list,
        preListedListPagination: payload.pagination
      }

    case types.GET_PRE_LISTED_LIST_FAILURE:
      return {
        ...state,
        preListedListLoader: false,
      }

    case types.ADD_PRE_LISTED_INITIATE:
      return {
        ...state,
        addPreListedWordLoader: true,
        addPreListedWordSuccess: false
      }

    case types.ADD_PRE_LISTED_SUCCESS:
      return {
        ...state,
        addPreListedWordLoader: false,
        addPreListedWordSuccess: true,
      }

    case types.ADD_PRE_LISTED_FAILURE:
      return {
        ...state,
        addPreListedWordLoader: false,
      }

    case types.EDIT_PRE_LISTED_INITIATE:
      return {
        ...state,
        editPreListedWordLoader: true,
        editPreListedWordSuccess: false
      }

    case types.EDIT_PRE_LISTED_SUCCESS:
      return {
        ...state,
        editPreListedWordLoader: false,
        editPreListedWordSuccess: true,
        editPreListedWordDetail: payload
      }

    case types.EDIT_PRE_LISTED_FAILURE:
      return {
        ...state,
        editPreListedWordLoader: false,
      }

    case types.DELETE_GAMES_INITIATE:
      return {
        ...state,
        deletePreListedWordLoader: true,
        deletePreListedWordSuccess: false
      }

    case types.DELETE_GAMES_SUCCESS:
      console.log(payload, "delete in reducer****", state)
      let gameIndexToDelete = state.gamesList.findIndex(value => value._id === payload)
      state.gamesList.splice(gameIndexToDelete, 1)
      return {
        ...state,
        deletePreListedWordLoader: false,
        deletePreListedWordSuccess: true,
        gamesList: [...state.gamesList]
      }

    case types.DELETE_GAMES_FAILURE:
      return {
        ...state,
        deletePreListedWordLoader: false,
      }


    case types.DELETE_PRE_LISTED_INITIATE:
      return {
        ...state,
        deletePreListedWordLoader: true,
        deletePreListedWordSuccess: false
      }

    case types.DELETE_PRE_LISTED_SUCCESS:
      console.log(payload, "delete in reducer****", state)
      let indexToDelete = state.preListedList.filter(value => value._id !== payload)
      return {
        ...state,
        deletePreListedWordLoader: false,
        deletePreListedWordSuccess: true,
        preListedList: indexToDelete
      }

    case types.DELETE_PRE_LISTED_FAILURE:
      return {
        ...state,
        deletePreListedWordLoader: false,
      }

    case types.UPDATE_GAMES_STATES:
      state[key] = payload
      return { ...state, };

    case types.UPDATE_PRELISTED_STATES:
      state[key] = payload
      return { ...state, };

    case types.CLEAR_GAMES_DATA:
      return {
        ...state,
        gamesListSuccess: false,
        exportGamesListSuccess: false,
        preListedListSuccess: false,
        addPreListedWordSuccess: false,
        editPreListedWordSuccess: false,
        deletePreListedWordSuccess: false
      }

    default:
      return state
  }
}

