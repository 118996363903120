import * as types from '../ActionTypes'

export const getGamesListInitiate = (data, history) => {
  const action = {
    type: types.GET_GAMES_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getGamesListSuccess = (data) => {
  const action = {
    type: types.GET_GAMES_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const getGamesListFailure = (error) => {
  const action = {
    type: types.GET_GAMES_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const exportGamesListInitiate = (data, history) => {
  const action = {
    type: types.EXPORT_GAMES_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const exportGamesListSuccess = (data) => {
  const action = {
    type: types.EXPORT_GAMES_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const exportGamesListFailure = (error) => {
  const action = {
    type: types.EXPORT_GAMES_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const getPreListedListInitiate = (data, history) => {
  const action = {
    type: types.GET_PRE_LISTED_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getPreListedListSuccess = (data) => {
  const action = {
    type: types.GET_PRE_LISTED_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const getPreListedListFailure = (error) => {
  const action = {
    type: types.GET_PRE_LISTED_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const addPreListedListInitiate = (data, history) => {
  const action = {
    type: types.ADD_PRE_LISTED_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const addPreListedListSuccess = (data) => {
  const action = {
    type: types.ADD_PRE_LISTED_SUCCESS,
    payload: data,
  }
  return action
}

export const addPreListedListFailure = (error) => {
  const action = {
    type: types.ADD_PRE_LISTED_FAILURE,
    payload: error,
  }
  return action
}

export const editPreListedListInitiate = (data, history) => {
  const action = {
    type: types.EDIT_PRE_LISTED_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const editPreListedListSuccess = (data) => {
  const action = {
    type: types.EDIT_PRE_LISTED_SUCCESS,
    payload: data,
  }
  return action
}

export const editPreListedListFailure = (error) => {
  const action = {
    type: types.EDIT_PRE_LISTED_FAILURE,
    payload: error,
  }
  return action
}

export const deleteGamesListInitiate = (data, history) => {
  const action = {
    type: types.DELETE_GAMES_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const deleteGamesListSuccess = (data) => {
  const action = {
    type: types.DELETE_GAMES_SUCCESS,
    payload: data,
  }
  return action
}

export const deleteGamesListFailure = (error) => {
  const action = {
    type: types.DELETE_GAMES_FAILURE,
    payload: error,
  }
  return action
}


export const deletePreListedListInitiate = (data, history) => {
  const action = {
    type: types.DELETE_PRE_LISTED_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const deletePreListedListSuccess = (data) => {
  const action = {
    type: types.DELETE_PRE_LISTED_SUCCESS,
    payload: data,
  }
  return action
}

export const deletePreListedListFailure = (error) => {
  const action = {
    type: types.DELETE_PRE_LISTED_FAILURE,
    payload: error,
  }
  return action
}

export const updateGamesAction = (data, key) => ({
  type: types.UPDATE_GAMES_STATES,
  payload: data,
  key
})

export const updatePreListedAction = (data, key) => ({
  type: types.UPDATE_PRELISTED_STATES,
  payload: data,
  key
})

export const clearGamesData = () => ({
  type: types.CLEAR_GAMES_DATA
})