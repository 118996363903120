import * as types from '../ActionTypes'

export const getVouchersListInitiate = (data, history) => {
  const action = {
    type: types.GET_VOUCHERS_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getVouchersListSuccess = (data) => {
  const action = {
    type: types.GET_VOUCHERS_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const getVouchersListFailure = (error) => {
  const action = {
    type: types.GET_VOUCHERS_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const addVoucherInitiate = (data, history) => {
  const action = {
    type: types.ADD_VOUCHER_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const uploadCSVAction = (data, history) => {
  const action = {
    type: types.UPLOAD_CSV_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const addVoucherSuccess = (data) => {
  const action = {
    type: types.ADD_VOUCHER_SUCCESS,
    payload: data,
  }
  return action
}

export const addVoucherFailure = (error) => {
  const action = {
    type: types.ADD_VOUCHER_FAILURE,
    payload: error,
  }
  return action
}

export const updateVouchersAction = (data, key) => ({
  type: types.UPDATE_VOUCHERS_STATES,
  payload: data,
  key
})

export const clearVouchersData = () => ({
  type: types.CLEAR_VOUCHERS_DATA
})



export const deleteVoucherListInitiate = (data, history) => {
  const action = {
    type: types.DELETE_VOUCHER_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const deleteVoucherListSuccess = (data) => {
  const action = {
    type: types.DELETE_VOUCHER_SUCCESS,
    payload: data,
  }
  return action
}

export const deleteVoucherListFailure = (error) => {
  const action = {
    type: types.DELETE_VOUCHER_FAILURE,
    payload: error,
  }
  return action
}
