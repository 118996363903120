import { createSelector } from 'reselect'

export const getMemoizedGameSettingsData = createSelector(
  (state) => state.gamesSettings,
  (gameSettingsState) => {
    const {
      faqListLoader,
      faqListSuccess,
      faqList,
      offsetValue,
      searchValue,
      sortBy,
      sortOrder,
      faqListPagination,
      faqDetailLoader,
      faqDetailSuccess,
      faqDetail,
      addFaqLoader,
      addFaqSuccess,
      editFaqLoader,
      editFaqSuccess,
      editFaqDetail,
      deleteFaqLoader,
      deleteFaqSuccess,
      contactDetailLoader,
      contactDetailSuccess,
      contactDetail,
      editContactLoader,
      editContactSuccess,
      editContactDetail,
      aboutDetailLoader,
      aboutDetailSuccess,
      aboutDetail,
      editAboutLoader,
      editAboutSuccess,
      editAboutDetail,
      privacyDetailLoader,
      privacyDetailSuccess,
      privacyDetail,
      termsLoader,
      termsSuccess,
      terms,
      editPrivacyLoader,
      editPrivacySuccess,
      editPrivacyDetail,
      editTermsLoader,
      editTermsSuccess,
      editTermsDetail,
      faq
    } = gameSettingsState

    return {
      faqListLoader,
      faqListSuccess,
      faqList,
      offsetValue,
      searchValue,
      sortBy,
      sortOrder,
      faqListPagination,
      faqDetailLoader,
      faqDetailSuccess,
      faqDetail,
      addFaqLoader,
      addFaqSuccess,
      editFaqLoader,
      editFaqSuccess,
      editFaqDetail,
      deleteFaqLoader,
      deleteFaqSuccess,
      contactDetailLoader,
      contactDetailSuccess,
      contactDetail,
      editContactLoader,
      editContactSuccess,
      editContactDetail,
      aboutDetailLoader,
      aboutDetailSuccess,
      aboutDetail,
      editAboutLoader,
      editAboutSuccess,
      editAboutDetail,
      privacyDetailLoader,
      privacyDetailSuccess,
      privacyDetail,
      termsLoader,
      termsSuccess,
      terms,
      editPrivacyLoader,
      editPrivacySuccess,
      editPrivacyDetail,
      editTermsLoader,
      editTermsSuccess,
      editTermsDetail,
      faq
    }
  }
)