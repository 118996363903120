import { createSelector } from 'reselect'

export const getMemoizedUsersData = createSelector(
  (state) => state.users,
  (usersState) => {
    const {
      userListLoader,
      usersListSuccess,
      usersList,
      offsetValue,
      searchValue,
      sortBy,
      sortOrder,
      startDate,
      endDate,
      usersListPagination,
      userDetailLoader,
      userDetailSuccess,
      userDetail,
      userEditLoader,
      userEditSuccess,
      userEditDetail,
      blockUnblockUserLoader,
      blockUnblockUserSuccess,
      exportUserListLoader,
      exportUserListSuccess,
      exportUserList,
      userGameOrgListLoader,
      userGameOrgListSuccess,
      userGameOrgList,
      userGameOrgListPagination,
      gameOrgOffsetValue,
      gameOrgSearchValue,
      gameOrgSortBy,
      gameOrgSortOrder,
      userGamePlayListLoader,
      userGamePlayListSuccess,
      userGamePlayList,
      userGamePlayListPagination,
      gamePlayOffsetValue,
      gamePlaySearchValue,
      gamePlaySortBy,
      gamePlaySortOrder,
      userAddLoader,
      userAddSuccess,
      userAddDetail,
    } = usersState

    return {
      userListLoader,
      usersListSuccess,
      usersList,
      offsetValue,
      searchValue,
      sortBy,
      sortOrder,
      startDate,
      endDate,
      usersListPagination,
      userDetailLoader,
      userDetailSuccess,
      userDetail,
      userEditLoader,
      userEditSuccess,
      userEditDetail,
      blockUnblockUserLoader,
      blockUnblockUserSuccess,
      exportUserListLoader,
      exportUserListSuccess,
      exportUserList,
      userGameOrgListLoader,
      userGameOrgListSuccess,
      userGameOrgList,
      userGameOrgListPagination,
      gameOrgOffsetValue,
      gameOrgSearchValue,
      gameOrgSortBy,
      gameOrgSortOrder,
      userGamePlayListLoader,
      userGamePlayListSuccess,
      userGamePlayList,
      userGamePlayListPagination,
      gamePlayOffsetValue,
      gamePlaySearchValue,
      gamePlaySortBy,
      gamePlaySortOrder,
      userAddLoader,
      userAddSuccess,
      userAddDetail,
    }
  }
)