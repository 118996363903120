import { takeEvery, takeLatest, all, fork, } from "redux-saga/effects";
import * as types from '../ActionTypes';
import {
  LoginSaga,
  ForgotPasswordSaga,
  resetPasswordSaga,
  checkResetLinksaga,
  LogoutSaga,
  changePasswordSaga
} from '../Saga/AuthenticationSaga'

import { DashboardSaga } from "./DashboardSaga";

import {
  getUsersListSaga,
  getUsersDetailsSaga,
  userEditSaga,
  userAddSaga,
  exportUsersListSaga,
  getUserGameOrgListSaga,
  getUserGamePlayedListSaga,
  userBlockUnblockSaga
} from "../Saga/users"

import {
  getVouchersListSaga,
  addVoucherSaga,
  uploadCSVSaga,
  exportVouchersListSaga,
  voucherDeleteSaga
} from "../Saga/vouchers"

import {
  getGamesListSaga,
  exportGamesListSaga,
  getpreListedListSaga,
  preListedWordAddSaga,
  preListedWordEditSaga,
  preListedWordDeleteSaga,
  gameDeleteSaga
} from "../Saga/games"

import {
  getContactUsListSaga,
  getContactUsDetailsSaga,
  deleteContactUsDetailSaga
} from "../Saga/contactUs"

import {
  getFaqListSaga,
  addFaqSaga,
  deleteFaqSaga,
  editFaqSaga,
  getFaqDetailSaga,
  getContactDetailSaga,
  editContactSaga,
  getAboutDetailSaga,
  editAboutSaga,
  getPrivacyDetailSaga,
  editPrivacySaga,
  getTermsSaga,
  editTermsSaga
} from "../Saga/gameSettings"

import {
  getNotificationsListSaga
} from "../Saga/notifications"

import rootContactSaga from './contactUs';

function* rootSaga() {
  //Auth
  yield takeEvery(types.API_LOGIN_LOAD, LoginSaga)
  yield takeEvery(types.FORGOT_PASSWORD_LOAD, ForgotPasswordSaga)
  yield takeEvery(types.API_RESET_PASSWORD_LOAD, resetPasswordSaga)
  yield takeEvery(types.CHECK_RESET_LIST_LOAD, checkResetLinksaga)
  yield takeEvery(types.API_LOGOUT_LOAD, LogoutSaga)
  yield takeLatest(types.CHANGE_PASSWORD_INITIATE, changePasswordSaga)

  //Dashboard
  yield takeEvery(types.API_DASHBOARD_LOAD, DashboardSaga)

  //User Management
  yield takeLatest(types.GET_USERS_LIST_INITIATE, getUsersListSaga);
  yield takeEvery(types.GET_USER_DETAIL_INITIATE, getUsersDetailsSaga);
  yield takeEvery(types.EDIT_USER_DETAIL_INITIATE, userEditSaga);
  yield takeEvery(types.ADD_USER_DETAIL_INITIATE, userAddSaga);
  yield takeLatest(types.EXPORT_USER_LIST_INITIATE, exportUsersListSaga);
  yield takeLatest(types.GET_USER_GAME_ORGANIZE_LIST_INITIATE, getUserGameOrgListSaga);
  yield takeLatest(types.GET_USER_GAME_PLAYED_LIST_INITIATE, getUserGamePlayedListSaga);
  yield takeEvery(types.BLOCK_UNBLOCK_USER_INITIATE, userBlockUnblockSaga);

  //Vouchers Management
  yield takeLatest(types.GET_VOUCHERS_LIST_INITIATE, getVouchersListSaga);
  yield takeLatest(types.ADD_VOUCHER_INITIATE, addVoucherSaga);
  yield takeLatest(types.UPLOAD_CSV_INITIATE, uploadCSVSaga);
  yield takeLatest(types.EXPORT_VOUCHER_LIST_INITIATE, exportVouchersListSaga);
  yield takeLatest(types.DELETE_VOUCHER_INITIATE, voucherDeleteSaga);


  //Games Management
  yield takeLatest(types.GET_GAMES_LIST_INITIATE, getGamesListSaga);
  yield takeLatest(types.EXPORT_GAMES_LIST_INITIATE, exportGamesListSaga);
  yield takeLatest(types.GET_PRE_LISTED_LIST_INITIATE, getpreListedListSaga);
  yield takeLatest(types.ADD_PRE_LISTED_INITIATE, preListedWordAddSaga);
  yield takeLatest(types.EDIT_PRE_LISTED_INITIATE, preListedWordEditSaga);
  yield takeLatest(types.DELETE_PRE_LISTED_INITIATE, preListedWordDeleteSaga);
  yield takeLatest(types.DELETE_PRE_LISTED_INITIATE, preListedWordDeleteSaga);

  //Contact Us
  yield takeLatest(types.GET_CONTACT_US_LIST_INITIATE, getContactUsListSaga);
  yield takeLatest(types.GET_CONTACT_US_DETAILS_INITIATE, getContactUsDetailsSaga);
  yield takeLatest(types.DELETE_GAMES_INITIATE, gameDeleteSaga);

  yield all([
    fork(rootContactSaga),
  ]);

  // Game Settings
  yield takeLatest(types.GET_FAQ_LIST_INITIATE, getFaqListSaga);
  yield takeLatest(types.ADD_FAQ_INITIATE, addFaqSaga);
  yield takeLatest(types.DELETE_FAQ_DETAIL_INITIATE, deleteFaqSaga);
  yield takeLatest(types.EDIT_FAQ_DETAIL_INITIATE, editFaqSaga);
  yield takeLatest(types.GET_FAQ_DETAILS_INITIATE, getFaqDetailSaga);
  yield takeLatest(types.GET_CONTACT_DETAILS_INITIATE, getContactDetailSaga);
  yield takeLatest(types.EDIT_CONTACT_DETAIL_INITIATE, editContactSaga);
  yield takeLatest(types.GET_ABOUT_DETAILS_INITIATE, getAboutDetailSaga)
  yield takeLatest(types.EDIT_ABOUT_DETAIL_INITIATE, editAboutSaga);
  yield takeLatest(types.GET_PRIVACY_DETAILS_INITIATE, getPrivacyDetailSaga);
  yield takeLatest(types.GET_TERMS_INITIATE, getTermsSaga);
  yield takeLatest(types.EDIT_PRIVACY_DETAIL_INITIATE, editPrivacySaga);
  yield takeLatest(types.EDIT_TERMS_INITIATE, editTermsSaga);

  // Notifications
  yield takeLatest(types.GET_NOTIFICATIONS_LIST_INITIATE, getNotificationsListSaga);

}
export default rootSaga