import React, { useState, useEffect, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify';
import moment from 'moment'
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import {
    Link,
    useHistory
} from "react-router-dom";
import { Layout, Pagination, DatePicker, Space, Table, Breadcrumb, Modal, Tooltip } from 'antd';

import images from '../../themes/appImage'
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import appconstant from "../../themes/appconstant";
import { getMemoizedNotificationsData } from "../../redux/selectors/notifications"
import { getNotificationsInitiate, updateNotificationsAction } from "../../redux/Action/notifications"

const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;



const Notifications = (props) => {


    const dispatch = useDispatch()
    const history = useHistory()

    const notificationData = useSelector(getMemoizedNotificationsData)

    console.log("this is data = ", notificationData)
    const {
        notificationList,
        notificationListLoader,
        notificationListPagination,
        notificationListSuccess,
        offsetValue,
        searchValue,
        sortBy,
        sortOrder
    } = notificationData

    const [search, setSearch] = useState('')
    const [dates, setDates] = useState([]);

    useLayoutEffect(() => {

        const data = {
            search: searchValue,
            sort: sortBy,
            order: sortOrder,
            offset: searchValue ? 0 : offsetValue,
            limit: 20
        }
        dispatch(getNotificationsInitiate(data, history))
    }, [])


    const columns = [{
        title: 'Sr. No.',
        dataIndex: 'srno',
        key: 'srno',
        render: (value, item, index) => <div style={{ width: '60px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{(index + 1 + offsetValue)}</div>,
    },
    {
        title: 'Title',
        dataIndex: 'descripition',
        key: 'descripition',
        render: (value, item, index) => <div style={{ width: '300px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><Tooltip placement="right" title={item?.descripition}>{item?.descripition ? item?.descripition : 'N/A'}</Tooltip></div>,
        sorter: true,
    },
    {
        title: 'Pre-Listed Word',
        dataIndex: 'word',
        key: 'word',
        sorter: true,
        render: (value, item, index) => <div style={{ width: '130px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.word.word ? item?.word.word : 'N/A'}</div>,
    },
    {
        title: 'Time',
        dataIndex: 'createdOn',
        key: 'createdOn',
        render: (value, item, index) => <div style={{ width: '200px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.createdOn ? moment(item?.createdOn).format('DD/MM/YYYY hh:mm A') : 'N/A'}</div>,
        elipses: true,
        sorter: true,
    },
    ];



    const [isVisible, setVisible] = useState(false)
    const handlewClick = () => {
        setVisible(!isVisible)
    }
    useEffect(() => {
        document.title = 'Disparity Trap';
    }, [])


    const handlTableChange = (pagination, filter, sorter) => {
        console.log("this is event = ", sorter)
        if (!navigator.onLine) {
            toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
        } else {
            if (sorter.order == "ascend") {
                const data = {
                    search: searchValue,
                    sort: sorter.field,
                    order: 1,
                    offset: searchValue ? 0 : offsetValue,
                    limit: 20
                }
                dispatch(updateNotificationsAction(1, 'sortOrder'))
                dispatch(updateNotificationsAction(sorter.field, 'sortBy'))
                dispatch(getNotificationsInitiate(data, history))

            } //To fetch listing in descending order
            else if (sorter.order === "descend") {
                const data = {
                    search: searchValue,
                    sort: sorter.field,
                    order: -1,
                    offset: searchValue ? 0 : offsetValue,
                    limit: 20
                }
                dispatch(updateNotificationsAction(-1, 'sortOrder'))
                dispatch(updateNotificationsAction(sorter.field, 'sortBy'))
                dispatch(getNotificationsInitiate(data, history))
            } //To fetch listing in normal order
            else {
                const data = {
                    search: searchValue,
                    sort: null,
                    order: null,
                    offset: searchValue ? 0 : offsetValue,
                    limit: 20,
                }
                dispatch(updateNotificationsAction(null, 'sortOrder'))
                dispatch(updateNotificationsAction(null, 'sortBy'))
                dispatch(getNotificationsInitiate(data, history))
            }
        }
    }

    const handleSearch = (e) => {
        let searchWord = e.target.value
        let isOnlyBlankSpace = false
        if (searchWord.charAt(0) === " ") {
            if (searchWord.length == 1 && searchWord == " ") {
                isOnlyBlankSpace = true
            }
            searchWord = searchWord.replace(/^ +/gm, '')
            setSearch(searchWord.replace(/^ +/gm))
            dispatch(updateNotificationsAction(searchWord, 'searchValue'))
        } else {
            dispatch(updateNotificationsAction(searchWord, 'searchValue'))
            setSearch(searchWord)
        }
        if (isOnlyBlankSpace === false) {
            isOnlyBlankSpace = false
            const data = {
                search: searchWord,
                sort: null,
                order: null,
                offset: searchWord.trim().length === 0 ? offsetValue : 0,
                limit: 20,
            }
            if (!navigator.onLine) {
                toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
            } else {

                dispatch(getNotificationsInitiate(data, history))
            }
        } else if (searchWord !== search) {
            isOnlyBlankSpace = false
            const data = {
                search: searchWord,
                sort: "",
                order: null,
                offset: searchWord.trim().length == 0 ? offsetValue : 0,
                limit: 20
            }
            if (!navigator.onLine) {
                toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
            } else {

                dispatch(getNotificationsInitiate(data, history))
            }
        }
    }

    return (
        <div>
            <Layout>
                <Sidebar />
                <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
                <Layout className="new_layout">
                    <HeaderAdmin handlewClick={handlewClick} {...props} />
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {appconstant.notifications}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Content style={{ margin: '24px 16px 0' }}>

                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            <div className="content-e">
                                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                                    <h6 class="text-white text-capitalize ps-3">{appconstant.notifications}</h6>
                                    <input
                                        type="text"
                                        className="search-bar"
                                        placeholder="Search"
                                        value={searchValue}
                                        autoComplete="off"
                                        name="search"
                                        onChange={(e) => {
                                            handleSearch(e)
                                        }}
                                    />
                                </div>
                                <Table
                                    dataSource={notificationList}
                                    columns={columns}
                                    showSorterTooltip={false}
                                    loading={notificationListLoader}
                                    pagination={false}
                                    onChange={handlTableChange}
                                />
                                <Pagination
                                    pageSize="20"
                                    total={notificationListPagination?.totalCount}
                                    onChange={(page) => {
                                        if (!navigator.onLine) {
                                            toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                                        } else {
                                            let data = (page - 1) * 20;
                                            const dta = {
                                                search: searchValue,
                                                sort: sortBy,
                                                order: sortOrder,
                                                offset: data,
                                                limit: 20
                                            }
                                            dispatch(updateNotificationsAction(data, 'offsetValue'))
                                            dispatch(getNotificationsInitiate(dta, history))
                                        }
                                    }}
                                    current={notificationListPagination?.currentPage}
                                    showSizeChanger={false}
                                />
                            </div>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}
export default Notifications
