import { put, call, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import {
  usersList,
  usersListDetails,
  userEditApi,
  exportUsersList,
  userAddApi,
  userGamesOrgList,
  userGamesPlayedList,
  userBlockUnblockApi,
  contactList,
  deleteContact,
  addContact
} from '../Api'
import { toast } from 'react-toastify';
import * as types from '../ActionTypes';
import { isInternetConnected } from '../../common/checkInternet'
import {
  getContactUsListSuccess,
  getContactUsListFailure,
  getContactUsDetailSuccess,
  getContactUsDetailFailure,
  deleteContactUsDetailSuccess,
  deleteContactUsDetailFailure
} from "../Action/contactUs"
import { FirebaseMethods } from '../../helper/firebase'


function* loginAgain(action) {
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  localStorage.clear();
  action.history.push("/login");
}

export function* getContactUsListSaga(action) {
  console.log('new-item.........', action)
  // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
  // const token = localStorage.getItem('token')
  try {
    const response = yield call(usersList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      // toast.success(message, { position: "top-right" })
      yield put(getContactUsListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactUsListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactUsListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactUsListFailure(err))
    }
  }
}

export function* getContactUsDetailsSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(usersListDetails, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      yield put(getContactUsDetailSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactUsDetailFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactUsDetailFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactUsDetailFailure(err))
    }

  }
}

export function* deleteContactUsDetailSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(userBlockUnblockApi, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right" })
      yield put(deleteContactUsDetailSuccess(action.payload))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteContactUsDetailFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteContactUsDetailFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteContactUsDetailFailure(err))
    }

  }
}

//// Add Contact Saga
function* addContactSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(addContact, action.data)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      yield put({ type: types.ADD_CONTACT_SUCCESS, result: data })
      FirebaseMethods.saveAdminMessageToFirebase(action.messageObj, action.data.userId)
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put({ type: types.ADD_CONTACT_FAIL })
    }
  }

  catch (err) {
    console.log('resultSaga.......,', err)
    yield put({ type: types.ADD_CONTACT_FAIL })
    toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
  }
}


////  Contact List Saga
function* contactListSaga(action) {
  try {
    const response = yield call(contactList, action.payload)
    let { data, message } = response.result;
    if (response.status === 1) {
      yield put({ type: types.GET_CONTACT_LIST_SUCCESS, result: data })
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put({ type: types.GET_CONTACT_LIST_FAIL })
    }
  }
  catch (err) {
    yield put({ type: types.GET_CONTACT_LIST_FAIL })
  }
}

////  Contact List Saga
function* deleteContactSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(deleteContact, action.contactId)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put({ type: types.DELETE_CONTACT_SUCCESS, result: data })
      yield put({ type: types.GET_CONTACT_LIST_LOAD, payload: action.data })
      FirebaseMethods.deleteAllMessageFromAdmin(action.userId)
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put({ type: types.DELETE_CONTACT_FAIL })
    }
  }

  catch (err) {
    yield put({ type: types.DELETE_CONTACT_FAIL })
  }
}

export default function* rootContactSaga() {
  yield takeLatest(types.ADD_CONTACT_LOAD, addContactSaga);
  yield takeLatest(types.GET_CONTACT_LIST_LOAD, contactListSaga);
  yield takeLatest(types.DELETE_CONTACT_LOAD, deleteContactSaga);
}
