import { put, call } from 'redux-saga/effects';

import {
  faqList,
  addFaq,
  editFaq,
  deleteFaq,
  viewFaq,
  viewContactDetail,
  editContactDetail,
  viewAboutDetail,
  editAboutDetail,
  viewPrivacyDetail,
  editPrivacyDetail,
  viewTerms,
  editTermsDetail
} from '../Api'
import { toast } from 'react-toastify';
import * as types from '../ActionTypes';
import {
  getFAQListSuccess,
  getFAQListFailure,
  addFAQSuccess,
  addFAQFailure,
  getFAQDetailSuccess,
  getFAQDetailFailure,
  editFAQSuccess,
  editFAQFailure,
  deleteFAQSuccess,
  deleteFAQFailure,
  getContactDetailSuccess,
  getContactDetailFailure,
  editContactSuccess,
  editContactFailure,
  getAboutDetailSuccess,
  getAboutDetailFailure,
  editAboutSuccess,
  editAboutFailure,
  getPrivacyDetailSuccess,
  getPrivacyDetailFailure,
  editPrivacySuccess,
  editPrivacyFailure,
  getTermsSuccess,
  getTermsFailure,
  editTermsSuccess,
  editTermsFailure
} from "../Action/gameSettings"

function* loginAgain(action) {
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  localStorage.clear();
  action.history.push("/login");
}

export function* getFaqListSaga(action) {
  console.log('new-item.........', action)
  // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
  // const token = localStorage.getItem('token')
  try {
    const response = yield call(faqList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      // toast.success(message, { position: "top-right" })
      yield put(getFAQListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getFAQListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      console.log('resultSaga.......,', err)
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getFAQListFailure(err))
    } else {
      console.log('resultSaga.......,', err)
      toast.error(err.message || err.result.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getFAQListFailure(err))
    }

  }
}

export function* addFaqSaga(action) {
  console.log('new-item.........', action)
  // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
  // const token = localStorage.getItem('token')
  try {
    const response = yield call(addFaq, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      toast.success(message, { position: "top-right" })
      yield put(addFAQSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addFAQFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(addFAQFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addFAQFailure(err))
    }

  }
}

export function* editFaqSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(editFaq, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editFAQSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editFAQFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(editFAQFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editFAQFailure(err))
    }

  }
}

export function* deleteFaqSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(deleteFaq, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteFAQSuccess(data))
    }
    else {
      console.log('resultSaga.......,')
      if (response.status === 0) {
        toast.error('FAQ has been deleted.', { position: "top-right", toastId: 'custom_msg' })
        yield put(deleteFAQFailure(message))
      } else {
        toast.error(message, { position: "top-right", toastId: 'custom_msg' })
        yield put(deleteFAQFailure(message))
      }

    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteFAQFailure(err))
    } else {
      console.log('resultSaga.......,', err)
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteFAQFailure(err))
    }

  }
}

export function* getFaqDetailSaga(action) {
  console.log('delete in reducer****', action)
  try {
    const response = yield call(viewFaq, action.payload)
    let { data, message } = response.result;
    console.log('delete in reducer****', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      // toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getFAQDetailSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getFAQDetailFailure(message))
    }
  }

  catch (err) {
    console.log('error in reducer****', err)
    if (err.status === 0) {
      toast.error('FAQ has been deleted.', { position: "top-right", toastId: 'custom_msg' })
      yield put(getFAQDetailFailure(err))
    } else if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getFAQDetailFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getFAQDetailFailure(err))
    }

  }
}

export function* getContactDetailSaga(action) {
  console.log('delete in reducer****', action)
  try {
    const response = yield call(viewContactDetail, action.payload)
    let { data, message } = response.result;
    console.log('delete in reducer****', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      // toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactDetailSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactDetailFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactDetailFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getContactDetailFailure(err))
    }

  }
}

export function* editContactSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(editContactDetail, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editContactSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editContactFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(editContactFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editContactFailure(err))
    }

  }
}

export function* getAboutDetailSaga(action) {
  console.log('delete in reducer****', action)
  try {
    const response = yield call(viewAboutDetail, action.payload)
    let { data, message } = response.result;
    console.log('delete in reducer****', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      // toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getAboutDetailSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getAboutDetailFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getAboutDetailFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getAboutDetailFailure(err))
    }

  }
}

export function* editAboutSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(editAboutDetail, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editAboutSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editAboutFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(editAboutFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editAboutFailure(err))
    }

  }
}

export function* getPrivacyDetailSaga(action) {
  console.log('delete in reducer****', action)
  try {
    const response = yield call(viewPrivacyDetail, action.payload)
    let { data, message } = response.result;
    console.log('delete in reducer****', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      // toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getPrivacyDetailSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getPrivacyDetailFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getPrivacyDetailFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getPrivacyDetailFailure(err))
    }

  }
}

export function* editPrivacySaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(editPrivacyDetail, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editPrivacySuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editPrivacyFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(editPrivacyFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editPrivacyFailure(err))
    }

  }
}

export function* getTermsSaga(action) {
  try {
    const response = yield call(viewTerms)
    let { data, message } = response.result;
    if (response.status == 1) {
      yield put(getTermsSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getTermsFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getTermsFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getTermsFailure(err))
    }

  }
}

export function* editTermsSaga(action) {
  try {
    const response = yield call(editTermsDetail, action.payload)
    let { data, message } = response.result;
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editTermsSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editTermsFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(editTermsFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editTermsFailure(err))
    }

  }
}