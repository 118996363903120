import * as types from '../ActionTypes'
export const LoginAction = (userinfo, history) => {
    const action = {
        type: types.API_LOGIN_LOAD,
        payload: userinfo,
        history: history
    }
    return action
}

export const ForgotPasswordAction = (email, history) => {
    console.log("action-- hit",email);
    const action = {
        type: types.FORGOT_PASSWORD_LOAD,
        payload: email,
        history: history
    }
    return action
}


export const checkResetPasswordAction = (data) => {

    const action = {
        type: types.CHECK_RESET_LIST_LOAD,
        data
    }
    return action;
}

export const resetPasswordAction = (data, history) => {
    const action = {
        type: types.API_RESET_PASSWORD_LOAD,
        data,
        history
    }
    return action;
}

export const changePasswordInitiate = (data, history) => {
    const action = {
      type: types.CHANGE_PASSWORD_INITIATE,
      payload: data,
      history: history
    }
    return action
  }
  
  export const changePasswordSuccess = (data) => {
    const action = {
      type: types.CHANGE_PASSWORD_SUCCESS,
      payload: data,
    }
    return action
  }
  
  export const changePasswordFailure = (error) => {
    const action = {
      type: types.CHANGE_PASSWORD_FAILURE,
      payload: error,
    }
    return action
  }

//Logout
export const LogoutAction = (history) => {
    const action = {
        type: types.API_LOGOUT_LOAD,
        history: history
    }
    return action;
}

