import React, { useState, useEffect } from "react"
import { Layout, Menu, Pagination } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux"
import images from '../../themes/appImage'
import { DatePicker, Space } from 'antd';


import {
  BrowserRouter as Router,
  Link,
  useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";

import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from 'antd';
import MobileSidebar from "../../layout/mobilesidebar";
import appconstant from "../../themes/appconstant";
import {
  getUserGameOrganizeListInitiate,
  updateUsersAction
} from "../../redux/Action/users"
import { getMemoizedUsersData } from "../../redux/selectors/users"

const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;




const GameOrganized = (props) => {
  console.log(' props.history.location.state-------', props.history.location.state);
  const history = useHistory()
  const dispatch = useDispatch()

  const gameOrganizedData = useSelector(getMemoizedUsersData)
  const {
    userGameOrgListLoader,
    userGameOrgList,
    userGameOrgListPagination,
    gameOrgOffsetValue,
    gameOrgSearchValue,
    gameOrgSortBy,
    gameOrgSortOrder
  } = gameOrganizedData

  const [search, setSearch] = useState('')
  const [isVisible, setVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    if (props.history.location.state == undefined) {
      history.push('/usermanagement')

    } else {
      if (localStorage.getItem('token')) {

        const data = {
          offset: gameOrgSearchValue ? 0 : gameOrgOffsetValue,
          limit: 20,
          search: gameOrgSearchValue,
          sort: gameOrgSortBy,
          order: gameOrgSortOrder,
          type: "host",
          userId: props.history.location.state._id
        }
        dispatch(getUserGameOrganizeListInitiate(data, history))
      }
    }

  }, [])

  const columns = [{
    title: 'Sr. No.',
    dataIndex: 'srno',
    key: 'srno',
    render: (value, item, index) => (index + 1 + gameOrgOffsetValue)
  },
  {
    title: 'Room ID',
    dataIndex: 'roomId',
    key: 'roomId',
    sorter: true,
  },
  {
    title: 'Created Date/Time ',
    dataIndex: 'createdOn',
    key: 'createdOn',
    render: (value, item, index) => item?.createdOn ? moment(item?.createdOn).format('DD/MM/YYYY HH:mm A') : 'N/A',
    sorter: true,
  },
  {
    title: 'Winner Name',
    dataIndex: 'winner',
    key: 'winner',
    render: (value, item, index) => item?.winner ? item?.winner?.name : 'N/A',
    sorter: true,
  }
  ];

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlewClick = () => {
    setVisible(!isVisible)
  }


  const handlTableChange = (pagination, filter, sorter) => {
    console.log("this is event = ", sorter)
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      if (sorter.order == "ascend") {
        const data = {
          search: gameOrgSearchValue,
          sort: sorter.field,
          order: 1,
          offset: gameOrgSearchValue ? 0 : gameOrgOffsetValue,
          limit: 20,
          type: "host",
          userId: props.history.location.state._id
        }
        dispatch(updateUsersAction(1, 'gameOrgSortOrder'))
        dispatch(updateUsersAction(sorter.field, 'gameOrgSortBy'))
        dispatch(getUserGameOrganizeListInitiate(data, history))

      } //To fetch listing in descending order
      else if (sorter.order === "descend") {
        const data = {
          search: gameOrgSearchValue,
          sort: sorter.field,
          order: -1,
          offset: gameOrgSearchValue ? 0 : gameOrgOffsetValue,
          limit: 20,
          type: "host",
          userId: props.history.location.state._id
        }
        dispatch(updateUsersAction(-1, 'gameOrgSortOrder'))
        dispatch(updateUsersAction(sorter.field, 'gameOrgSortBy'))
        dispatch(getUserGameOrganizeListInitiate(data, history))
      } //To fetch listing in normal order
      else {
        const data = {
          search: gameOrgSearchValue,
          sort: null,
          order: null,
          offset: gameOrgSearchValue ? 0 : gameOrgOffsetValue,
          limit: 20,
          type: "host",
          userId: props.history.location.state._id
        }
        dispatch(updateUsersAction(null, 'gameOrgSortOrder'))
        dispatch(updateUsersAction(null, 'gameOrgSortBy'))
        dispatch(getUserGameOrganizeListInitiate(data, history))
      }
    }
  }

  const handleSearch = (e) => {
    let searchWord = e.target.value
    let isOnlyBlankSpace = false
    if (searchWord.charAt(0) === " ") {
      if (searchWord.length == 1 && searchWord == " ") {
        isOnlyBlankSpace = true
      }
      searchWord = searchWord.replace(/^ +/gm, '')
      setSearch(searchWord.replace(/^ +/gm))
      dispatch(updateUsersAction(searchWord, 'gameOrgSearchValue'))
    } else {
      dispatch(updateUsersAction(searchWord, 'gameOrgSearchValue'))
      setSearch(searchWord)
    }
    if (isOnlyBlankSpace === false) {
      isOnlyBlankSpace = false

      const data = {
        search: searchWord,
        sort: null,
        order: null,
        offset: searchWord.trim().length === 0 ? gameOrgOffsetValue : 0,
        limit: 20,
        type: "host",
        userId: props.history.location.state._id
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {

        dispatch(getUserGameOrganizeListInitiate(data, history))
      }
    } else if (searchWord !== search) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: "",
        order: null,
        offset: searchWord.trim().length == 0 ? gameOrgOffsetValue : 0,
        limit: 20,
        type: "host",
        userId: props.history.location.state._id
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {
        dispatch(getUserGameOrganizeListInitiate(data, history))
      }
    }
  }

  return (
    <div>
      <Layout>
        <Sidebar />
        <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
        <Layout className="new_layout">
          <HeaderAdmin handlewClick={handlewClick} {...props} />
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/usermanagement">{appconstant.UserManagement}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span
                className="special_breadcrumb"
                onClick={() => {
                  props.history.push('/userview', props.history.location.state)
                }}
              >
                {appconstant.UserDetails}
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {appconstant.GameOrganized}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Content style={{ margin: '24px 16px 0' }}>

            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <div className="content-e">
                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 class="text-white text-capitalize ps-3"> {appconstant.GameOrganized}</h6>
                  <input
                    type="text"
                    className="search-bar"
                    placeholder="Search"
                    value={gameOrgSearchValue}
                    autoComplete="off"
                    name="search"
                    onChange={(e) => {
                      handleSearch(e)
                    }}
                  />

                </div>
                <Table
                  dataSource={userGameOrgList}
                  columns={columns}
                  showSorterTooltip={false}
                  loading={userGameOrgListLoader}
                  pagination={false}
                  onChange={handlTableChange}
                />
                <Pagination
                  pageSize="20"
                  total={userGameOrgListPagination?.totalCount}
                  onChange={(page) => {
                    if (!navigator.onLine) {
                      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                    } else {
                      let data = (page - 1) * 20;
                      const dta = {
                        search: gameOrgSearchValue,
                        sort: gameOrgSortBy,
                        order: gameOrgSortOrder,
                        offset: data,
                        limit: 20,
                        type: "host",
                        userId: props.history.location.state._id
                      }
                      dispatch(updateUsersAction(data, 'gameOrgOffsetValue'))
                      dispatch(getUserGameOrganizeListInitiate(dta, history))
                    }
                  }}
                  current={userGameOrgListPagination?.currentPage}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
      <Modal title="Delete"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}>
        <div className="text-center  model1">
          <p>Are you sure want to delete?</p>
        </div>
        <div className="text-center  model1">
        </div>
      </Modal>
    </div>

  )
}
export default GameOrganized
