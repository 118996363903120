import { createSelector } from 'reselect'

export const getMemoizedNotificationsData = createSelector(
    (state) => state.notifications,
    (notificationState) => {
        const {
            notificationListLoader,
            notificationListSuccess,
            notificationList,
            offsetValue,
            searchValue,
            sortBy,
            sortOrder,
            notificationListPagination,
            totalCount
        } = notificationState

        return {
            notificationListLoader,
            notificationListSuccess,
            notificationList,
            offsetValue,
            searchValue,
            sortBy,
            sortOrder,
            notificationListPagination,
            totalCount
        }
    }
)