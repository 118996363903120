import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
import 'firebase/compat/messaging'
const firebaseConfig = {
    apiKey: "AIzaSyDRTXqtozJ42Li1CK8YdQuZY-bIInTY6GQ",
    authDomain: "disparity-trap-board-game.firebaseapp.com",
    projectId: "disparity-trap-board-game",
    storageBucket: "disparity-trap-board-game.appspot.com",
    messagingSenderId: "597667129909",
    appId: "1:597667129909:web:2bc586c05f23017f1ddf7c",
    measurementId: "G-E9X3JT25BH"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);
const firestore = firebase.firestore();
const messagesRef = firestore.collection('chatWithAdmin');
const usersList = firestore.collection('users');

export default messagesRef;

export const FirebaseMethods = {
    //// Chat With Admin
    async createRoomWithAdmin(userId) {
        messagesRef.doc(userId)
            .set({ userId: userId })
    },

    ///Saving messages and all other data in firbase store.
    async saveAdminMessageToFirebase(messagePayload, userId) {
        const { id } = await messagesRef.doc(userId).collection('chatList')
            .add(messagePayload)
        if (id) {
            messagePayload.messageId = id
            messagesRef.doc(userId).collection('chatList').doc(id).set(messagePayload)
                .then((res) => {
                }).catch((e) => { alert(e) });
        }
    },

    ///Gettng all messages from firestore
    async getAllMessagesWithAdmin(callback, userId, limit, msgFrom) {
        console.log('getAllMessages', msgFrom)
        if (msgFrom) {
            await messagesRef
                .doc(userId)
                .collection("chatList")
                .limitToLast(15)
                .orderBy("createdAt")
                .endBefore(msgFrom)
                .onSnapshot(querySnapshot => {
                    console.log('querySnapshot', querySnapshot)
                    if (querySnapshot.size != 0) {
                        let arr = [];
                        querySnapshot.forEach(doc => {
                            arr.push(doc.data());
                        });

                        arr.sort(function (a, b) {
                            a = new Date(a.createdAt);
                            b = new Date(b.createdAt);

                            return a > b ? -1 : a < b ? 1 : 0;
                        });
                        callback(arr);
                    } else {
                        let arr = [];
                        callback(arr);
                    }
                });
        } else {
            await messagesRef
                .doc(userId)
                .collection("chatList")
                .orderBy("createdAt")
                // .limitToLast(15)
                .onSnapshot(querySnapshot => {
                    console.log('querySnapshot', querySnapshot)
                    if (querySnapshot.size != 0) {
                        let arr = [];
                        querySnapshot.forEach(doc => {
                            arr.push(doc.data());
                        });

                        arr.sort(function (a, b) {
                            a = new Date(a.createdAt);
                            b = new Date(b.createdAt);

                            return a > b ? -1 : a < b ? 1 : 0;
                        });
                        callback(arr.reverse());
                    } else {
                        let arr = [];
                        callback(arr);
                    }
                });
        }

    },

    async getUserOnlineStatus(callback, userId) {
        await usersList.doc(userId)
            .onSnapshot((data) => {
                if (data._delegate._document) {

                    callback(data._delegate._document.data.value.mapValue.fields.isOnline.booleanValue);
                }
            });
    },

    //// Delete single message from admin side
    async updateMessageData(userId, msgObject) {
        console.log('createUserList', userId)
        messagesRef
            .doc(userId)
            .collection("chatList")
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const data = doc.data();
                    if (doc.id == msgObject.messageId) {
                        msgObject.isDeleted = true
                        messagesRef.doc(userId).collection('chatList').doc(doc.id).set(msgObject)
                            .then((res) => {
                            }).catch((e) => { alert(e) });

                    }
                });
            }).catch((error) => {
                console.log('errorUpdate', error)
                return false
            })
    },


    //// Delete all message from admin side
    async deleteAllMessageFromAdmin(userId) {
        console.log('userId')
        messagesRef
            .doc(userId)
            .collection("chatList")
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const data = doc.data();
                    console.log('delete All message', data)
                    data.isDeleted = true
                    messagesRef.doc(userId).collection('chatList').doc(doc.id).set(data)
                        .then((res) => {
                        }).catch((e) => { alert(e) });

                });
            }).catch((error) => {
                console.log('errorUpdate', error)
                return false
            })
    },


}

// export const fetchToken = (setTokenFound) => {
//     return getToken(messaging, { vapidKey: 'BPwQeeMetmL-B_lkIn6p646I9-sDWMFSFefAibudOy7dI5Tdm_F5QJ6kVi7BBIkmjwoewpvKoiqmIviNS090NUs' }).then((currentToken) => {
//         if (currentToken) {
//             console.log('current token for client: ', currentToken);
//             setTokenFound(true);
//             // Track the token -> client mapping, by sending to backend server
//             // show on the UI that permission is secured
//         } else {
//             console.log('No registration token available. Request permission to generate one.');
//             setTokenFound(false);
//             // shows on the UI that permission is required 
//         }
//     }).catch((err) => {
//         console.log('An error occurred while retrieving token. ', err);
//     });
// }

// export const onMessageListener = () =>
//     // console.log('onMessageListener')
//     new Promise((resolve) => {
//         onMessage(messaging, (payload) => {
//             console.log('payload', payload)
//             resolve(payload);
//         });
//     });



