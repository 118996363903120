import React, { useState, useEffect } from "react"
import { Layout, Menu } from 'antd';
import images from '../../themes/appImage'

import {
    BrowserRouter as Router,
    Link,
    useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { Table } from 'antd';
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import InputField from '../../customComponent/input'
import { ForgotPasswordAction } from "../../redux/Action/Authentication"
import { isInternetConnected } from '../../common/checkInternet'

import { Formik } from 'formik';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ValidationConstants from '../../themes/ValidationConstants'

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const { Header, Content, Footer, Sider } = Layout;

const ForgotPassword = () => {

    const dispatch = useDispatch();
    const history = useHistory();



    useEffect(() => {
        document.title = 'Disparity Trap';
        window.scrollTo(0, 0)

    }, [])

    const initialValues = {
        email: '',
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(ValidationConstants.valEmail).required(ValidationConstants.valEmail),
    });

    const onSubmit = (values, { setSubmitting }) => {
        console.log('on Submit hit ------', values);
        isInternetConnected() && dispatch(ForgotPasswordAction(values, history))
    }

    const validate = values => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Please enter email address.';
        } else if (!/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/.test(values.email)) {
            errors.email = ValidationConstants.valEmail;
        }
        return errors;
    };



    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,

        validate
    });

    return (
        <div className="image-bg">
            <div className="page-header">
                <div className="line_true">
                    <div className="content-e login_1">
                        <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                            <h6 class="text-white line_element text-capitalize ps-3" style={{ position: 'absolute' }}>
                                <Link to="/login"> <FontAwesomeIcon icon={faArrowLeft} /></Link>{appconstant.ForgotPassword}</h6>
                        </div>
                        <div className="wrapper_line">
                            <div className="text-center cost">

                                <img src={images.logo} />
                                <h6 class="text-white text-capitalize ps-3 login_2">Forgot Password</h6>
                            </div>
                            <form noValidate onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    {/* <label>{appconstant.EmailAddress}</label> */}
                                    <div className="form-group">
                                        <InputField
                                            onChangeText={formik.handleChange('email')}
                                            value={formik.values.email.trim()}
                                            touched={formik.touched.email}
                                            error={formik.errors.email}
                                            placeholder="Email Address"
                                            // feildType='email'
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className="text-center button">
                                    <button type="submit" className="button-list">{appconstant.submit}</button>
                                </div>
                            </form>
                            <div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    )
}
export default ForgotPassword;
