import * as types from '../ActionTypes';
import { usersList } from '../Api';

const initialState = {
    notificationListLoader: false,
    notificationListSuccess: false,
    notificationList: [],
    offsetValue: 0,
    searchValue: '',
    sortBy: null,
    sortOrder: null,
    notificationListPagination: {},
    totalCount: 0
}

export const notifications = (state = initialState, { payload, key, type }) => {
    switch (type) {
        case types.GET_NOTIFICATIONS_LIST_INITIATE:
            return {
                ...state,
                notificationListLoader: true,
                notificationListSuccess: false
            }

        case types.GET_NOTIFICATIONS_LIST_SUCCESS:
            return {
                ...state,
                notificationListLoader: false,
                notificationListSuccess: true,
                notificationList: payload.list,
                notificationListPagination: payload.paginateData,
                totalCount: payload.paginateData.totalCount
            }

        case types.GET_NOTIFICATIONS_LIST_FAILURE:
            return {
                ...state,
                notificationListLoader: false,
            }

        case types.UPDATE_NOTIFICATION_STATES:
            state[key] = payload
            return { ...state, };

        case types.CLEAR_USERS_DATA:
            return {
                ...state
            }

        default:
            return state
    }
}

