import React, { useState, useEffect } from "react"
import { Layout, Menu, Spin } from 'antd';
import { toast } from 'react-toastify';
import images from '../../themes/appImage'

import {
  BrowserRouter as Router,
  Link,
  useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { getUserDetailInitiate } from "../../redux/Action/users"
import { userEditInitiate } from "../../redux/Action/users"

import { useDispatch, useSelector } from "react-redux";
import { getMemoizedUsersData } from "../../redux/selectors/users"

import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from 'antd';
import appconstant from "../../themes/appconstant";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// import Loader from '../../helper/reactLoader'
import InputField from '../../customComponent/input'
import { imageUrl } from '../../constant';


const { Header, Content, Footer, Sider } = Layout;

const EditUser = (props) => {
  const [isVisible, setVisible] = useState(false)

  const history = useHistory();
  const dispatch = useDispatch();

  const handlewClick = () => {
    setVisible(!isVisible)
  }
  const usersData = useSelector(getMemoizedUsersData)

  const {
    userDetailLoader,
    userDetailSuccess,
    userDetail,
    userEditLoader,
    userEditSuccess,
    userEditDetail,
  } = usersData

  console.log('usersData----', usersData)

  useEffect(() => {
    document.title = 'Disparity Trap';

    if (localStorage.token) {

      if (props.history.location.state == undefined) {
        history.push('/usermanagement')

      } else {
        const data = {
          userId: props.history.location.state._id
        }
        dispatch(getUserDetailInitiate(data, history))
      }
    }
    if (!localStorage.getItem('token')) {
      history.push('/login')
    }
  }, [])

  const [username_, setUsername] = useState();
  const [email_, setemail] = useState();
  const [userid_, setuserid] = useState();
  const [profileImg, setProfileImg] = useState(null);
  const [title, setTitle] = useState("Click to upload image.");
  const [tooltip, setTooltip] = useState("Click to change image.");
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [imageUpload, setImageUpload] = useState("");
  const [imageError, setImageError] = useState(null)

  const imageValidation = (value) => {
    let imageType = ["jpeg", "jpg", "png", "svg"]
    return imageType.indexOf(value) > -1;

  }

  console.log('imageFile', file, profileImg)

  const imageChange = (e) => {
    if (e.target.files) {
      console.log('CheckinFile', e.target.files)
      let file = e.target.files[0]
      if (!file.name.match(/\.(jpg|png|jpeg|gif)$/)) {
        setImageError('Only .jpeg, .jpg, .png format images are allowed.')
        return false;
      }
      const extentType = ['jpeg', 'jpg', 'png'];
      const extent = file?.name?.split('.');
      const extentT = extent && extent[extent.length - 1];
      console.log('imageChange', extentType, extentType.indexOf(extentT) >= 0, extentType.indexOf(extentT))
      if (extentType.indexOf(extentT) >= 0) {
        if (file.size > 20971520) {
          setImageError('File size must be equal or less than 20 MB.')
        } else {
          setFile(URL.createObjectURL(file));
          setFileUrl(file)
          setImageUpload("")
          setImageError(null)
        }
      }
      else {
        if (e.target.files.length == 0) {

        } else {

          setImageError('Only .jpeg, .jpg, .png format images are allowed.')
        }
      }
    }
  }




  useEffect(() => {
    if (userDetail) {
      setUsername(userDetail.name)
      setemail(userDetail.email)
      setuserid(userDetail.userId)
      setProfileImg(userDetail?.profileImage ? `${imageUrl}${userDetail?.profileImage}` : null)

    }
  }, [userDetail])


  const validationSchema = Yup.object({
    fname: Yup.string()
      .test('trim', 'Please enter username.', value => value?.trim()?.length > 0)
      .min(3, 'Username should be at least 3 characters long.')
      // .min(20, 'Username should be at least 3 characters long.', value => value?.trim()?.length > 0)
      .required("Username is required"),
    email: Yup.string()
      // .email("Please enter valid email address.")
      .matches(
        /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/,
        "Please enter valid email address."
      )
      .required("Please enter email address."),
    userid: Yup.string()
      .test('trim', 'Please enter userid.', value => value?.trim()?.length > 0)
      // .min(1, 'userid should be at least 1 characters long.', value => value?.trim()?.length > 0)
      .required("Please enter userid."),

  })


  //formik initial state
  const initialValues = {
    userid: userid_,
    fname: username_,
    email: email_,
    profile: profileImg
  };


  const handleFormSubmit = (values, { setSubmitting }) => {
    console.log("this is")
    // const data = {
    //   userId: props.history.location.state._id,
    //   userID: values.userid,
    //   userName: values.fname,
    //   email: values.email
    // }
    let body = new FormData()
    if (file) {


      body.append('profileImage', fileUrl)


    }
    body.append('userId', userDetail._id)
    body.append('name', values.fname)
    body.append('email', values.email)
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      dispatch(userEditInitiate(body, history))
    }
  }



  return (

    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} {...props} />
        <Breadcrumb>
          <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/usermanagement">{appconstant.UserManagement}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{appconstant.edituserdetails}</Breadcrumb.Item>
        </Breadcrumb>
        <Content style={{ margin: '24px 16px 0' }}>

          <div className="site-layout-background label_space" style={{ padding: 24, minHeight: 360 }}>
            <Spin className="page_loading" tip="Loading..." spinning={userEditLoader} size={"large"}>
              <div className="content-e">
                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                  <h6 class="text-white text-capitalize ps-3">{appconstant.edituserdetails}</h6>
                </div>
                <div className="wrapper_line">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      values,
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        {console.log("errors = ", errors)}
                        <div className="upload-image">
                          <div className="upload-image">

                            <div
                              class="upload_img common-flex"

                            >
                              <input
                                type="file"
                                id="pic"
                                accept=".png,.jpg,.jpeg"
                                style={{ display: "none" }}
                                onChange={imageChange}
                              />


                              {file ? <div className='image_wrapper_style' style={{ cursor: 'default' }}>
                                <label for="pic" title={title}>
                                  <img
                                    alt=""
                                    className="table-img"
                                    src={file ? file : images.dummy}
                                    type="file"
                                    onChange={imageChange}
                                    height="100"
                                    width="100"
                                    style={{
                                      // padding: file ? '0px' : '0px .5px 1px 1px',
                                      cursor: 'pointer',
                                      borderRadius: '50%'
                                    }}
                                  />

                                  <img
                                    style={{ cursor: "pointer" }}
                                    className="plus_icon"
                                    src={images.plus}
                                    onClick={imageChange}
                                  ></img>
                                </label>
                              </div> : (
                                <div className='image_wrapper_style' style={{ cursor: 'default' }}>
                                  <label for="pic" title={title}>
                                    <img
                                      className="table-img"
                                      src={profileImg ? profileImg : images.dummy}
                                      type="file"
                                      onChange={imageChange}
                                      height="100"
                                      width="100"
                                      style={{
                                        // padding: file ? '0px' : '0px .5px 1px 1px',
                                        cursor: 'pointer',
                                        borderRadius: '50%'
                                      }}
                                    />

                                    <img
                                      style={{ cursor: "pointer" }}
                                      className="plus_icon"
                                      src={images.plus}
                                      onClick={imageChange}
                                    ></img>
                                  </label>
                                </div>
                              )}



                            </div>
                            <div
                              className="image-upload"
                              style={{
                                color: "red",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {imageUpload}
                              {/* {fileUrl} */}
                            </div>
                          </div>


                          <div
                            className="image-upload"
                            style={{
                              color: "red",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* {imageUpload} */}
                          </div>
                        </div>
                        {imageError ? (
                          <div class='color-error' style={{ width: '100%', textAlign: "center" }}>{imageError}</div>
                        ) : null}
                        <div className="form-group">
                          <label>{appconstant.Userid}</label>
                          <InputField
                            onChangeText={handleChange('userid')}
                            onBlur={handleBlur('userid')}
                            value={values.userid}
                            touched={touched.userid}
                            error={errors.userid}
                            disabled={true}
                            placeholder="User ID"
                            // type='noSpace'
                            maxLength={12}

                          />
                          {/* <input type="text" className="form-control" placeholder="Username" Value="1234" /> */}
                        </div>
                        <div className="form-group">
                          <label>{appconstant.UserName}</label>
                          <InputField
                            onChangeText={handleChange('fname')}
                            onBlur={handleBlur('fname')}
                            value={values.fname}
                            touched={touched.fname}
                            error={errors.fname}
                            fieldType='userName'
                            placeholder="User Name"
                            // type='noSpace'
                            maxLength={40}
                          />
                        </div>
                        <div className="form-group">
                          <label>{appconstant.EmailAddress}</label>
                          <InputField
                            onCh
                            disabled={true}
                            onChangeText={handleChange('email')}
                            onBlur={handleBlur('email')}
                            value={values.email}
                            touched={touched.email}
                            error={errors.email}
                            placeholder="Email Address"
                            // type='noSpace'
                            //maxLength={15}
                            fieldType='email'
                          />

                        </div>
                        <div className="text-center button">
                          <button
                            type="submit"
                            className="button-list"
                            loading={true}
                          >
                            {appconstant.update}
                          </button>
                        </div>
                        <div>
                        </div>

                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </Spin>
          </div>

        </Content>
      </Layout>
    </Layout>
  )
}
export default EditUser;
