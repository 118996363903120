import * as types from '../ActionTypes'

export const getUsersListInitiate = (data, history) => {
  const action = {
    type: types.GET_USERS_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getUsersListSuccess = (data) => {
  const action = {
    type: types.GET_USERS_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const getUsersListFailure = (error) => {
  const action = {
    type: types.GET_USERS_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const getUserDetailInitiate = (data, history) => {
  const action = {
    type: types.GET_USER_DETAIL_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getUserDetailSuccess = (data) => {
  const action = {
    type: types.GET_USER_DETAIL_SUCCESS,
    payload: data,
  }
  return action
}

export const getUserDetailFailure = (error) => {
  const action = {
    type: types.GET_USER_DETAIL_FAILURE,
    payload: error,
  }
  return action
}

export const userEditInitiate = (data, history) => {
  const action = {
    type: types.EDIT_USER_DETAIL_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const userEditSuccess = (data) => {
  const action = {
    type: types.EDIT_USER_DETAIL_SUCCESS,
    payload: data,
  }
  return action
}

export const userEditFailure = (error) => {
  const action = {
    type: types.EDIT_USER_DETAIL_FAILURE,
    payload: error,
  }
  return action
}

export const blockUnblockUserInitiate = (data, history) => {
  const action = {
    type: types.BLOCK_UNBLOCK_USER_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const blockUnblockUserSuccess = (data) => {
  const action = {
    type: types.BLOCK_UNBLOCK_USER_SUCCESS,
    payload: data,
  }
  return action
}

export const blockUnblockUserFailure = (error) => {
  const action = {
    type: types.BLOCK_UNBLOCK_USER_FAILURE,
    payload: error,
  }
  return action
}

export const exportUserListInitiate = (data, history) => {
  const action = {
    type: types.EXPORT_USER_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const exportUserListSuccess = (data) => {
  const action = {
    type: types.EXPORT_USER_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const exportUserListFailure = (error) => {
  const action = {
    type: types.EXPORT_USER_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const getUserGameOrganizeListInitiate = (data, history) => {
  const action = {
    type: types.GET_USER_GAME_ORGANIZE_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getUserGameOrganizeListSuccess = (data) => {
  const action = {
    type: types.GET_USER_GAME_ORGANIZE_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const getUserGameOrganizeListFailure = (error) => {
  const action = {
    type: types.GET_USER_GAME_ORGANIZE_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const userAddInitiate = (data, history) => {
  const action = {
    type: types.ADD_USER_DETAIL_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const userAddSuccess = (data) => {
  const action = {
    type: types.ADD_USER_DETAIL_SUCCESS,
    payload: data,
  }
  return action
}

export const userAddFailure = (error) => {
  const action = {
    type: types.ADD_USER_DETAIL_FAILURE,
    payload: error,
  }
  return action
}

export const getUserGamePlayedListInitiate = (data, history) => {
  const action = {
    type: types.GET_USER_GAME_PLAYED_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getUserGamePlayedListSuccess = (data) => {
  const action = {
    type: types.GET_USER_GAME_PLAYED_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const getUserGamePlayedListFailure = (error) => {
  const action = {
    type: types.GET_USER_GAME_PLAYED_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const updateUsersAction = (data, key) => ({
  type: types.UPDATE_USERS_STATES,
  payload: data,
  key
})

export const clearUsersData = () => ({
  type: types.CLEAR_USERS_DATA
})


export const exportVoucherList = (data, history) => {
  const action = {
    type: types.EXPORT_VOUCHER_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}