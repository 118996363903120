import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Table, Breadcrumb, Layout } from 'antd';
import images from '../../themes/appImage'

import {
  BrowserRouter as Router,
  Link,
  useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import InputField from '../../customComponent/input'
import appconstant from "../../themes/appconstant";

import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { changePasswordInitiate } from "../../redux/Action/Authentication"


const { Header, Content, Footer, Sider } = Layout;

const Changepassword = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [isVisible, setVisible] = useState(false)
  const handlewClick = () => {
    setVisible(!isVisible)
  }
  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
  }, [])

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, "Old password should be at least 8 characters long.")
      .required("Please enter old password."),
    newPassword: Yup.string()
      .min(8, "New password should be at least 8 characters long.")
      .required("Please enter new password."),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword")],
        "New password and confirm new password should be same."
      )
      .required("Please enter confirm new password.")
  });

  const handleFormSubmit = (values, { setSubmitting }) => {

    const data = {
      oldPassword: values.oldPassword,
      password: values.newPassword
    }
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      dispatch(changePasswordInitiate(data, history))
    }

  }


  return (

    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin
          {...props}
          handlewClick={handlewClick}
        />
        <Breadcrumb>
          <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
          <Breadcrumb.Item>{appconstant.changepassword}</Breadcrumb.Item>
        </Breadcrumb>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <div className="content-e">
              <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line change">
                <h6 class="text-white text-capitalize ps-3">{appconstant.changepassword}</h6>
                <input type="text" className="search-bar" placeholder="Search" style={{ visibility: "hidden" }} />

              </div>
              <div className="wrapper_line">
                <Formik
                  enableReinitialize
                  initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
                  validationSchema={validationSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>{appconstant.Oldpassword}</label>
                        <InputField
                          type="password"
                          onChangeText={handleChange('oldPassword')}
                          onBlur={handleBlur('oldPassword')}
                          value={values.oldPassword}
                          touched={touched.oldPassword}
                          error={errors.oldPassword}
                          noMargin
                          placeholder={appconstant.Oldpassword}
                        />

                      </div>
                      <div className="form-group">
                        <label>{appconstant.NewPassword}</label>
                        <InputField
                          type="password"
                          onChangeText={handleChange('newPassword')}
                          onBlur={handleBlur('newPassword')}
                          value={values.newPassword}
                          touched={touched.newPassword}
                          error={errors.newPassword}
                          noMargin
                          placeholder={appconstant.NewPassword}
                        />
                      </div>
                      <div className="form-group">
                        <label>{appconstant.ConfirmNewPassword}</label>
                        <InputField
                          type="password"
                          onChangeText={handleChange('confirmPassword')}
                          onBlur={handleBlur('confirmPassword')}
                          value={values.confirmPassword}
                          touched={touched.confirmPassword}
                          error={errors.confirmPassword}
                          noMargin
                          placeholder={appconstant.ConfirmNewPassword}
                        />
                      </div>
                      <div className="text-center button" style={{ marginBottom: '12px' }}>
                        <button type="submit" className="button-list">{appconstant.buttonupate}</button>
                      </div>
                    </form>
                  )}
                </Formik>
                <div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
export default Changepassword;
