import { API_Method } from './apiMethod';

//Authenticate api
export const loginApi = (userInfo) => API_Method.POST('admin/login', userInfo);
export const forgotPasswordApi = (email) => API_Method.POST('admin/forgotPassword', email);
export const resetPassword = (data) => API_Method.POST(`admin/resetPassword`, data);
export const checkResetPasswordLink = (data) => API_Method.POST(`admin/validationLink`, data);
export const changePassword = (data) => API_Method.PUT('admin/changePassword', data);

export const dashboardApi = (dashboardinfo) => API_Method.GET(`admin/dashboard`, dashboardinfo);

// Users Management
export const usersList = (data) => API_Method.POST('admin/userList', data);
export const userGamesOrgList = (data) => API_Method.POST('user/games', data);
export const userGamesPlayedList = (data) => API_Method.POST('user/games', data);
export const usersListDetails = (email) => API_Method.GET(`admin/details/${email.userId}`);
export const userEditApi = (data) => API_Method.PUT(`admin/edit`, data);
export const exportUsersList = (data) => API_Method.GETDATA("admin/userExport", data);

export const userAddApi = (details) => API_Method.POST('admin/addUser', details);
export const userBlockUnblockApi = (details) => API_Method.POST('admin/block', details);

// Vouchers Management
export const vouchersList = (data) => API_Method.POST('voucher/list', data);
export const addVoucher = (data) => API_Method.POST('voucher/add', data);
export const uploadCSV = (data) => API_Method.POST('voucher/export', data);
export const exportVoucherList = (data) => API_Method.GETDATA("voucher/exportVoucher", data)
export const deleteVoucher = (data) => API_Method.DELETE(`voucher/delete/${data}`);

//Game Management
export const gamesList = (data) => API_Method.POST('game/list', data);
export const exportGamesList = (data) => API_Method.GETDATA('game/export', data);
export const preListedWordList = (data) => API_Method.POST('prewords/list', data);
export const addPreListedWord = (data) => API_Method.POST('prewords/add', data);
export const editPreListedWord = (data) => API_Method.PUT('prewords/edit', data);
export const deletePreListedWord = (data) => API_Method.DELETE(`prewords/delete/${data}`);
export const deleteGame = (data) => API_Method.DELETE(`game/delete/${data}`);

//Contact Us
export const contactUsList = (data) => API_Method.POST('prewords/delete/62176e614b1ef56afe91ccc0');

export const addContact = (data) => API_Method.POST('contact/add', data)
export const contactList = (data) => API_Method.POST('contact/list', data);
export const deleteContact = (contactId) => API_Method.DELETE(`contact/delete/${contactId}`);


// Game Settings
export const faqList = (data) => API_Method.POST('faq/list', data);
export const addFaq = (data) => API_Method.POST('faq/add', data);
export const editFaq = (data) => API_Method.PUT('faq/edit', data);
export const deleteFaq = (data) => API_Method.DELETE(`faq/delete/${data}`);
export const viewFaq = (data) => API_Method.GET(`faq/details/${data}`);
export const viewContactDetail = (data) => API_Method.GET('main/contact');
export const editContactDetail = (data) => API_Method.PUT('main/contact', data);
export const viewAboutDetail = (data) => API_Method.GET('main/aboutUs');
export const editAboutDetail = (data) => API_Method.PUT('main/aboutUs', data);
export const viewPrivacyDetail = (data) => API_Method.GET('main/privacyPolicy');
export const editPrivacyDetail = (data) => API_Method.PUT('main/privacyPolicy', data)
export const viewTerms = (data) => API_Method.GET('main/terms');
export const editTermsDetail = (data) => API_Method.PUT('main/terms', data)

// Notification
export const notificationList = (data) => API_Method.POST('admin/notification', data)







