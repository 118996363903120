import React, { useState, Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import Changepassword from "../components/changepassword";
import ContactUS from "../components/contactus/contactus";
import Dashboard from "../components/dashboard";
import EditUser from "../components/usermanagement/edituser";
import GameSettings from "../components/gamesettings";
import PreListedWords from "../components/gamemangement/prelistedwords"
import UserManagement from "../components/usermanagement";
import ViewMessage from "../components/gamemangement/viewmessage";
import UserView from "../components/usermanagement/viewuser";
import VoucherCodeManagement from "../components/vouchermanagement";
import PrivateRoute from "./privateRoute";
import GameManagement from "../components/gamemangement";
import ChatTwo from "../components/contactus/chatcontactus";
import AddUser from "../components/usermanagement/adduser";
import Chat from "../components/gamemangement/viewmessage";
import GamePlayed from "../components/usermanagement/gameplayed";
import GameOrganized from "../components/usermanagement/gameorganised";
import Notifications from "../components/notifications";

const Routes = () => {
    return (
        <div>
            <Switch>
                <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/usermanagement" component={UserManagement} />
                <PrivateRoute path="/voucher" component={VoucherCodeManagement} />
                <PrivateRoute exact path="/gamemangement" component={GameManagement} />
                <PrivateRoute path="/prelistedwords" component={PreListedWords} />
                <PrivateRoute path="/viewmessage" component={ViewMessage} />
                <PrivateRoute path="/userview" component={UserView} />
                <PrivateRoute path="/useredit" component={EditUser} />
                <PrivateRoute path="/changepassword" component={Changepassword} />
                <PrivateRoute path="/contactus" component={ContactUS} />
                <PrivateRoute path="/notifications" component={Notifications} />
                <PrivateRoute path="/gamesettings" component={GameSettings} />
                <PrivateRoute path="/chat" component={Chat} />
                <PrivateRoute path="/chatr" component={ChatTwo} />
                <PrivateRoute path="/adduser" component={AddUser} />
                <PrivateRoute path="/gameplayed" component={GamePlayed} />
                <PrivateRoute path="/gameorganised" component={GameOrganized} />
            </Switch>
        </div>
    )

}
export default Routes;


