import * as types from '../ActionTypes';

const initialState = {
  onLoad: false,
  verificationCheckLoader: false,
  error: null,
  userData: null,
  forgotData: null,
  resetData: null,
  status: 0,
  data: {},
  checkResetLinkSuccess: false,
  isLinkExpire: false,
  passWordChangedSuccessfully: null,
  changePasswordLoader: false,
  changePasswordSuccess: false
}

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_LOGIN_START:
      return { ...state, onload: true }
    case types.API_LOGIN_SUCCESS:
      return { ...state, onload: false, userData: action.payload }
    case types.API_LOGIN_ERROR:
      return { ...state, onLoad: false }
    //forgot password

    case types.FORGOT_PASSWORD_START:
      return { ...state, onLoad: true }
    case types.FORGOT_PASSWORD_SUCCESS:
      return { ...state, onLoad: false, forgortData: action.payload }
    case types.FORGOT_PASSWORD_ERROR:
      return { ...state, onLoad: false }


    case types.CHECK_RESET_LIST_LOAD:
      return { ...state, verificationCheckLoader: true, error: null, checkResetLinkSuccess: false }

    case types.CHECK_RESET_LIST_SUCCESS:
      console.log('CHECK_RESET_LIST_SUCCESS', action)
      return {
        ...state,
        verificationCheckLoader: false,
        checkResetLinkSuccess: true,
        isLinkExpire: action.payload
      }
    case types.CHECK_RESET_LIST_ERROR:
      return { ...state, verificationCheckLoader: false, checkResetLinkSuccess: true, isLinkExpire: false }

    case types.CHANGE_PASSWORD_INITIATE:
      return { ...state, changePasswordLoader: true, changePasswordSuccess: false }
    case types.CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordLoader: false, changePasswordSuccess: true }
    case types.CHANGE_PASSWORD_FAILURE:
      return { ...state, changePasswordLoader: false }


    // reset password
    case types.API_RESET_PASSWORD_LOAD:
      return { ...state, onload: true, error: null }

    case types.API_RESET_PASSWORD_SUCCESS:
      console.log('API_RESET_PASSWORD_SUCCESS', action)
      return {
        ...state,
        onload: false,
        passWordChangedSuccessfully: 'Your password has been reset successfully.'
      }
    case types.API_RESET_PASSWORD_ERROR:
      console.log('API_RESET_PASSWORD_ERROR', action)
      if (action.result.message == "Link has been expired.") {
        state.isLinkExpire = false
      }
      return { ...state, onLoad: false }

    default: return { ...state }
  }
}

