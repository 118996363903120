import React, { useState, useEffect } from "react"
import { Layout, Menu, Spin, Modal } from 'antd';
import images from '../../themes/appImage'

import {
    BrowserRouter as Router,
    Link,
    useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from 'antd';
import appconstant from "../../themes/appconstant";
import moment from 'moment'
import { FirebaseMethods } from '../../helper/firebase'
import { useLongPress } from 'use-long-press';
import CustomAlertBox from '../../customComponent/customAlertBox';
import Linkify from 'react-linkify';
import { imageUrl } from '../../constant';
import { contactAddAction } from '../../redux/Action/contactUs'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";

const { Header, Content, Footer, Sider } = Layout;

const ChatTwo = (props) => {
    const [isVisible, setVisible] = useState(false)
    const [message, setMessage] = useState("")
    const [messageListArray, setMessageListArray] = useState([])
    const [userOnlineStatus, setuserOnlineStatus] = useState(false)
    const [loader, setLoader] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [singleMessageObject, setSingleMessageObject] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null)
    const userData = props?.location?.state?.record?.userId
    const userId = userData?._id
    const msgRef = React.useRef(null)
    const history = useHistory();
    const dispatch = useDispatch();

    console.log('propsCheck', props)


    const handlewClick = () => {
        setVisible(!isVisible)
    }
    useEffect(async () => {
        document.title = 'Disparity Trap';
        if (props.history.location.state == undefined) {
            history.push('/contactus')
        } else {
            if (navigator.onLine) {
                FirebaseMethods.createRoomWithAdmin(userId)
                FirebaseMethods.getAllMessagesWithAdmin(syncPosts, userId, 2)
                FirebaseMethods.getUserOnlineStatus(getUserOnlineStatus, userId)
            } else {
                toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
            }
        }

    }, [])

    useEffect(async () => {
        if (navigator.onLine) {
            FirebaseMethods.createRoomWithAdmin(userId)
            FirebaseMethods.getAllMessagesWithAdmin(syncPosts, userId, 2)
            FirebaseMethods.getUserOnlineStatus(getUserOnlineStatus, userId)
        }

    }, [navigator.onLine])

    const getUserOnlineStatus = async (object) => {
        console.log('getUserOnlineStatus', object)
        setuserOnlineStatus(object)
    };

    const syncPosts = async (object) => {
        let arr = []
        if (object.length > 0) {
            // setNoLoadMore(true)
            const d = [...object];
            await d.map(async (item) => {
                if (item?.isDeleted == false) {
                    item['user'] = userData
                    arr.push(item)
                }

            })
            setTimeout(async () => {
                await setMessageListArray([...messageListArray, ...arr]);
                setLoader(false)
                msgRef.current?.scrollIntoView({ behavior: "smooth" })
            }, 1500);
        } else {
            setLoader(false)
        }
    };

    const sendMessage = async (e) => {
        console.log('messageCheck', message)
        e && e.preventDefault()

        if (message.trim().length == 0) {
            // alert('Please Enter Message.')
            setErrorMsg(true)
        } else {
            if (!navigator.onLine) {
                toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
            } else {
                let messageObj = {
                    messageBody: message.trim(),
                    createdAt: moment(new Date()).utc().format(),
                    senderUserId: 'dispairyTrapGame_Admin',
                    receiverId: userId,
                    isDeleted: false
                }
                let data = {
                    userId: userId,
                    lastMessage: message.trim()
                }
                dispatch(contactAddAction(data, messageObj))
                setMessage('')
            }

        }

    }

    const _userList = () => {
        return (
            <div className="right_chat">
                <ul>
                    <li>
                        <div className="imge_wrappe active">
                            <div className="item_t">
                                <img src={images.usermessage} />
                                <span className="user-status"></span>
                            </div>
                            <span>Ankuj</span>
                        </div> </li>
                    <li>
                        <div className="imge_wrappe">
                            <div className="item_t">
                                <img src={images.usermessage} />
                                <span className="user-status"></span>
                            </div>
                            <span>Kite</span>
                        </div> </li>
                    <li>
                        <div className="imge_wrappe">
                            <div className="item_t">
                                <img src={images.usermessage} />
                                <span className="user-status"></span>
                            </div>
                            <span>John</span>
                        </div> </li>
                    <li>
                        <div className="imge_wrappe">
                            <div className="item_t">
                                <img src={images.usermessage} />
                                <span className="user-status"></span>
                            </div>
                            <span>Jack</span>
                        </div> </li>
                    <li>
                        <div className="imge_wrappe">
                            <div className="item_t">
                                <img src={images.usermessage} />
                                <span className="user-status"></span>
                            </div>
                            <span>Tom</span>
                        </div> </li>
                    <li>
                        <div className="imge_wrappe">
                            <div className="item_t">
                                <img src={images.usermessage} />
                                <span className="user-status"></span>
                            </div>
                            <span>Brad</span>
                        </div> </li>

                </ul>
            </div>
        )
    }

    const _chatHeaderComponent = () => {
        return (
            <div className="text-details header">
                <div className="item_t">
                    <img src={`${imageUrl}${userData?.profileImage}`} />
                    <span className={userOnlineStatus ? "user-status green" : "user-status"}></span>
                </div>
                <div className="lite_ew ">
                    <h4>{userData?.name}</h4>
                    {/* <p>Writing...</p> */}
                </div>
                {
                    (loader && navigator.onLine) &&
                    <div className="spin-loader">
                        <Spin />

                    </div>
                }
            </div>
        )
    }

    const bind = useLongPress((item) => {
        console.log('Long pressed!', item);
        // setIsModalVisible(true)
        Modal.confirm({
            title: "Delete Message",
            content: `Are you sure, you want to delete this message?`,
            okText: 'Yes',
            centered: true,
            cancelText: 'No',
            onOk() {
                FirebaseMethods.updateMessageData(userId, singleMessageObject)
            },
            onCancel() {

            },
            className: "new-button"
        });
    });

    const _rightMessageView = (item) => {
        let time = moment(moment(item.createdAt).toISOString()).format('hh:mm A DD/MM/YYYY')
        let string = item?.messageBody
        return (

            <div  {...bind} className="active_user wrapper_cli ">
                <div class="message-content " onMouseEnter={() => setSingleMessageObject(item)}>
                    {/* <h6 class="text-dark">Admin</h6> */}
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >{string}</Linkify>
                </div>
                <div class="message-options">
                    <div class="avatar avatar-sm"><img src={images.logo} /></div>
                    <span class="message-date">{time}</span>
                </div>
            </div>
        )
    }

    const _leftMessageView = (item) => {
        let time = moment(moment(item.createdAt).toISOString()).format('hh:mm A DD/MM/YYYY')
        let string = item?.messageBody
        return (
            <div  {...bind} className="wrapper_cli">
                <div class="message-content" onMouseEnter={() => setSingleMessageObject(item)}>
                    {/* <span class="text-dark">{item?.user?.username}</span> */}
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >{string}</Linkify>
                    {/* <span className="text-message">{item?.messageBody}</span> */}
                </div>
                <div class="message-options">
                    <div class="avatar avatar-sm"><img src={`${imageUrl}${userData?.profileImage}`} /></div>
                    <span class="message-date">{time}</span>
                </div>
            </div>
        )
    }

    const handleScroll = async (e) => {
        if (e.currentTarget.scrollTop === 0) {
            // await FirebaseMethods.getAllMessagesWithAdmin(syncPosts, userId, 1, messageListArray[messageListArray.length - 1]?.createdAt)
        }
    }

    const _showAllMessage = () => {
        return (
            <div className="home-chat" onScroll={handleScroll}>
                {
                    messageListArray.length > 0 ?

                        messageListArray.map((item, i) => {

                            return (
                                <div ref={msgRef} >
                                    {
                                        item.senderUserId == "dispairyTrapGame_Admin" ?
                                            _rightMessageView(item) :
                                            _leftMessageView(item)
                                    }
                                </div>
                            )
                        })
                        :
                        <div className="no-data">
                            <span>No Data</span>
                        </div>
                }
            </div>
        )
    }

    const _footerComponent = () => {
        return (
            <div className="main-footer">
                <div className={errorMsg ? "footer2" : "footer"}>
                    <div className="chat_point">
                        <textarea
                            className="form-control"
                            value={message}
                            onChange={(e) => {
                                setErrorMsg(false)
                                setLoader(false)
                                if (e.target.value.charAt(0) === " ") {
                                    setMessage(e.target.value.replace(/^ +/gm, ''))
                                } else {
                                    setMessage(e.target.value)
                                }
                            }}
                            placeholder="Type a message..."
                            onKeyPress={(e) => {

                                if (e.shiftKey) {
                                    setMessage(message.replace(/(?:\r\n|\r|\n)/g, "\n"));
                                } else {

                                    if (message.trim().length == 0 && e.key == "Enter") {
                                        e.preventDefault()
                                    } else {
                                        e.key == "Enter" && sendMessage(e);
                                    }
                                }
                            }}
                        />

                    </div>
                    <button type="submit" onClick={sendMessage} class="button-list top_new">Send </button>
                </div>
                {
                    errorMsg &&
                    <span className="error-msg">{'Please Enter Message.'}</span>
                }

            </div>
        )
    }

    const _deleteMessage = () => {
        FirebaseMethods.updateMessageData(userId, singleMessageObject)
        setIsModalVisible(false)
    }


    return (

        <Layout>
            <Sidebar />
            <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
            <Layout className="new_layout">
                <HeaderAdmin handlewClick={handlewClick} {...props} />
                <Breadcrumb>
                    <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to="/contactus">{appconstant.contactus}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{appconstant.viewmessage}</Breadcrumb.Item>
                </Breadcrumb>
                <Content className="chat_new_one" style={{ margin: '24px 16px 0' }}>
                    {/* <button type="submit" class="button-list data_R">{appconstant.IndividualMessages}</button>
                <button type="submit" class="button-list data_R">{appconstant.GroupMessages}</button> */}

                    <div className="chat_new">
                        {/* {_userList()} */}
                        <div className="right_column" >
                            {_chatHeaderComponent()}
                            {_showAllMessage()}
                            {_footerComponent()}
                        </div>
                    </div>
                    <CustomAlertBox
                        isModalVisible={isModalVisible}
                        onClick={_deleteMessage}
                        onCancelBtn={() => setIsModalVisible(false)}
                        onCancle={() => setIsModalVisible(false)}
                    />
                </Content>
            </Layout>
        </Layout>
    )
}
export default ChatTwo
