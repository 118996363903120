import React, { useState, useEffect, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify';
import moment from 'moment'
import { Layout, Pagination, DatePicker, Space, Table, Breadcrumb, Modal } from 'antd';
import {
  BrowserRouter as Router,
  Link,
  useHistory
} from "react-router-dom";

import images from '../../themes/appImage'
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { removeEmojis } from "../../common/utils"
import { getMemoizedGamesData } from "../../redux/selectors/games"
import {
  getPreListedListInitiate,
  addPreListedListInitiate,
  editPreListedListInitiate,
  deletePreListedListInitiate,
  updatePreListedAction
} from "../../redux/Action/games"
import { updateUsersAction, } from "../../redux/Action/users"
const { Header, Content, Footer, Sider } = Layout;



const PreListedWords = (props) => {

  const dispatch = useDispatch()
  const history = useHistory()

  const gamesData = useSelector(getMemoizedGamesData)
  const {
    addPreListedWordLoader,
    addPreListedWordSuccess,
    deletePreListedWordLoader,
    deletePreListedWordSuccess,
    editPreListedWordDetail,
    editPreListedWordLoader,
    editPreListedWordSuccess,
    preListedList,
    preListedListLoader,
    preListedListSuccess,
    preListedListPagination,
    preListedOffsetValue,
    preListedSearchValue,
    preListedSortBy,
    preListedSortOrder
  } = gamesData

  const [errorAddWord, setErrorAddWord] = useState('')
  const [addWordValue, setAddWordValue] = useState('')
  const [errorEditWord, setErrorEditWord] = useState('')
  const [editWordValue, setEditWordValue] = useState('')
  const [editWordId, setEditWordId] = useState('')
  const [search, setSearch] = useState('')
  useLayoutEffect(() => {

    if (addPreListedWordSuccess) {
      handleCanceladd()
    }
    if (editPreListedWordSuccess) {
      handleCancelEdit()
    }
    const data = {
      search: preListedSearchValue,
      sort: preListedSortBy,
      order: preListedSortOrder,
      offset: preListedSearchValue ? 0 : preListedOffsetValue,
      limit: 20
    }
    dispatch(getPreListedListInitiate(data, history))
  }, [addPreListedWordSuccess, editPreListedWordSuccess])


  const deleteModel = (record) => {
    console.log(record, "record******")
    Modal.confirm({
      title: "Delete Pre-Listed Word",
      content: `Are you sure, you want to delete this word?`,
      okText: 'Yes',
      centered: true,
      cancelText: 'No',
      onOk() {
        dispatch(deletePreListedListInitiate(record._id))
      },
      onCancel() {

      },
      className: "new-button"
    });
  };

  const [isVisible, setVisible] = useState(false)
  const handlewClick = () => {
    setVisible(!isVisible)
  }
  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

  }, [])
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [isModalVisibleAdd, setIsModalVisibleAdd] = useState(false);


  const showEditModal = (data) => {
    setIsModalVisibleEdit(true);
    setEditWordId(data?._id)
  };

  const handleOkAdd = () => {
    if (!addWordValue && !errorAddWord) {
      setErrorAddWord('Please enter word.')
      console.log("yes")
    } else if (addWordValue && !errorAddWord) {
      dispatch(addPreListedListInitiate({ word: addWordValue }, history))
    }
  };

  const handleOkEdit = () => {
    if (!editWordValue && !errorEditWord) {
      setErrorEditWord('Please enter word.')
      console.log("yes")
    } else if (editWordValue && !errorEditWord) {
      dispatch(editPreListedListInitiate({ word: editWordValue, wordId: editWordId }, history))
    }
  };

  const handleCancelEdit = () => {
    setIsModalVisibleEdit(false);
    setErrorEditWord('')
    setErrorAddWord('');
    setEditWordId('')
    setEditWordValue('')

  };
  const showModaladd = () => {
    setIsModalVisibleAdd(true);
  };

  const handleCanceladd = () => {
    setIsModalVisibleAdd(false);
    setErrorAddWord('');
    setErrorEditWord('')
    setAddWordValue('');
  };

  const handleAddWordChange = (data, name) => {
    let value = data.target.value
    let reg = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/
    if (value) {
      setErrorAddWord('')
      name(removeEmojis(value.trimLeft()))
      // if (value.match(reg)) {
      //   setErrorAddEmail('')
      //   setAddEmail(removeEmojis(value))
      // } else {
      //   setAddEmail(removeEmojis(value))
      //   setErrorAddEmail('Please enter valid email address.')
      // }
    } else {
      name(value.trimLeft())
      setErrorAddWord('Please enter word.')
    }

  }

  const editPreListedWordModal = () => {
    return (
      <Modal
        title="Edit Pre-Listed Word"
        visible={isModalVisibleEdit}
        onOk={handleOkEdit}
        onCancel={handleCancelEdit}
        centered
        okText='Update'
      >
        <div className="form-group" style={{ marginBottom: '0px' }}>
          {/* <label>{appconstant.edituserdetails}</label> */}
          <input
            type="text"
            className="form-control"
            placeholder="Input Word Here"
            autoComplete="off"
            name="editWordValue"
            value={editWordValue}
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              handleAddWordChange(e, setEditWordValue)
            }}
          />
          {errorAddWord && <div style={{ color: 'red' }}>
            {errorAddWord}
          </div>}
        </div>
      </Modal>
    )
  }
  const addPreListedWordModal = () => {
    return (
      <Modal
        title="Add Pre-Listed Word"
        visible={isModalVisibleAdd}
        onOk={handleOkAdd}
        onCancel={handleCanceladd}
        centered
        okText='Add'
      >
        <div className="form-group" style={{ marginBottom: '0px' }}>
          {/* <label>{appconstant.edituserdetails}</label> */}
          <input
            type="text"
            className="form-control"
            placeholder="Input Word Here"
            autoComplete="off"
            name="addWordValue"
            value={addWordValue}
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              handleAddWordChange(e, setAddWordValue)
            }}
          />
          {errorAddWord && <div style={{ color: 'red' }}>
            {errorAddWord}
          </div>}
        </div>
      </Modal>
    )
  }

  const handlTableChange = (pagination, filter, sorter) => {
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      if (sorter.order == "ascend") {
        const data = {
          search: preListedSearchValue,
          sort: sorter.field,
          order: 1,
          offset: preListedSearchValue ? 0 : preListedOffsetValue,
          limit: 20,
        }
        dispatch(updatePreListedAction(1, 'preListedSortOrder'))
        dispatch(updatePreListedAction(sorter.field, 'preListedSortBy'))
        dispatch(getPreListedListInitiate(data, history))

      } //To fetch listing in descending order
      else if (sorter.order === "descend") {
        const data = {
          search: preListedSearchValue,
          sort: sorter.field,
          order: -1,
          offset: preListedSearchValue ? 0 : preListedOffsetValue,
          limit: 20,
        }
        dispatch(updatePreListedAction(-1, 'preListedSortOrder'))
        dispatch(updatePreListedAction(sorter.field, 'preListedSortBy'))
        dispatch(getPreListedListInitiate(data, history))
      } //To fetch listing in normal order
      else {
        const data = {
          search: preListedSearchValue,
          sort: null,
          order: null,
          offset: preListedSearchValue ? 0 : preListedOffsetValue,
          limit: 20,
        }
        dispatch(updatePreListedAction(null, 'preListedSortOrder'))
        dispatch(updatePreListedAction(null, 'preListedSortBy'))
        dispatch(updatePreListedAction(data, history))
      }
    }
  }

  const handleSearch = (e) => {
    let searchWord = e.target.value
    let isOnlyBlankSpace = false
    if (searchWord.charAt(0) === " ") {
      if (searchWord.length == 1 && searchWord == " ") {
        isOnlyBlankSpace = true
      }
      searchWord = searchWord.replace(/^ +/gm, '')
      setSearch(searchWord.replace(/^ +/gm))
      dispatch(updatePreListedAction(searchWord, 'preListedSearchValue'))
    } else {
      dispatch(updatePreListedAction(searchWord, 'preListedSearchValue'))
      setSearch(searchWord)
    }
    if (isOnlyBlankSpace === false) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: null,
        order: null,
        offset: searchWord.trim().length === 0 ? preListedOffsetValue : 0,
        limit: 20,
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {

        dispatch(getPreListedListInitiate(data, history))
      }
    } else if (searchWord !== search) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: "",
        order: null,
        offset: searchWord.trim().length == 0 ? preListedOffsetValue : 0,
        limit: 20,
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {

        dispatch(getPreListedListInitiate(data, history))
      }
    }
  }

  const columns = [{
    title: 'Sr. No.',
    dataIndex: 'srno',
    key: 'srno',
    render: (value, item, index) => (index + 1)
  },
  {
    title: 'Pre-Listed Words',
    dataIndex: 'word',
    key: 'word',
    render: (value, item, index) => <div style={{ width: '80px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.word ? item?.word : 'N/A'}</div>,
    sorter: true,
  },
  {
    title: 'Action',
    dataIndex: 'Posted',
    key: 'Posted',
    render: (text, record) => {
      return (<div>
        <button
          type="submit"
          onClick={() => {
            setEditWordValue(record?.word)
            showEditModal(record)
          }}
        >
          {appconstant.edit}
        </button>
        <button type="submit" onClick={() => deleteModel(record)}> {appconstant.delete} </button> </div>
      )
    }
  },
  ];
  return (
    <div>
      <Layout>
        <Sidebar />
        <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />

        <Layout className="new_layout">
          <HeaderAdmin handlewClick={handlewClick} {...props} />
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="gamemangement">{appconstant.gamemanagement}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>{appconstant.prelistedwords}</Breadcrumb.Item>
          </Breadcrumb>
          <Content style={{ margin: '24px 16px 0' }}>
            <div className="top_button_item">
              <button type="submit" className="button-list top_new" onClick={showModaladd}>{appconstant.add} </button>
            </div>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <div className="content-e">
                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 class="text-white text-capitalize ps-3">{appconstant.prelistedwords}</h6>
                  <input
                    type="text"
                    className="search-bar"
                    placeholder="Search"
                    value={preListedSearchValue}
                    autoComplete="off"
                    name="search"
                    onChange={(e) => {
                      handleSearch(e)
                    }}
                  />
                </div>
                <Table
                  dataSource={preListedList}
                  columns={columns}
                  showSorterTooltip={false}
                  pagination={false}
                  onChange={handlTableChange}
                />
                <Pagination
                  pageSize="20"
                  total={preListedListPagination?.totalCount}
                  onChange={(page) => {
                    console.log(page, "page*****")
                    if (!navigator.onLine) {
                      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                    } else {
                      let data = (page - 1) * 20;
                      const dta = {
                        search: preListedSearchValue,
                        sort: preListedSortBy,
                        order: preListedSortOrder,
                        offset: data,
                        limit: 20,
                      }
                      dispatch(updatePreListedAction(data, 'preListedOffsetValue'))
                      dispatch(getPreListedListInitiate(dta, history))
                    }
                  }}
                  current={preListedListPagination?.currentPage}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
      {editPreListedWordModal()}
      {addPreListedWordModal()}
    </div>
  )
}
export default PreListedWords;
