import * as types from '../ActionTypes'

export const getNotificationsInitiate = (data, history) => {
    const action = {
        type: types.GET_NOTIFICATIONS_LIST_INITIATE,
        payload: data,
        history: history
    }
    return action
}

export const getNotificationsSuccess = (data) => {
    const action = {
        type: types.GET_NOTIFICATIONS_LIST_SUCCESS,
        payload: data,
    }
    return action
}

export const getNotificationsFailure = (error) => {
    const action = {
        type: types.GET_NOTIFICATIONS_LIST_FAILURE,
        payload: error,
    }
    return action
}

export const updateNotificationsAction = (data, key) => ({
    type: types.UPDATE_NOTIFICATION_STATES,
    payload: data,
    key
})

export const clearNotificationsData = () => ({
    type: types.CLEAR_NOTIFICATION_DATA
})