import React, { useState, useEffect } from "react"
import { Layout, Menu } from 'antd';

import {
  BrowserRouter as Router,
  Link,
  useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers, faGamepad } from "@fortawesome/free-solid-svg-icons";
import MobileSidebar from "../../layout/mobilesidebar";
import { Breadcrumb } from 'antd';
import images from '../../themes/appImage'
import { useDispatch, useSelector } from "react-redux";
import { dashboardAction } from '../../redux/Action/dashboardaction'


const { Header, Content, Footer, Sider } = Layout;


const Dashboard = (props) => {

  const dashboardData = useSelector(state => state.DashboardReducer.dashboardData)
  const { activeUsers, activeGames, totalGames, totalUsers } = dashboardData;


  console.log(dashboardData, 'dashboardData......')
  const history = useHistory();
  const dispatch = useDispatch();

  const [isVisible, setVisible] = useState(false)
  const handlewClick = () => {
    setVisible(!isVisible)
  }
  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    if (!localStorage.getItem('token')) {
      history.push('/login')
    }

  }, [])

  useEffect(() => {
    dispatch(dashboardAction(history))
  }, [])

  const items = [
    {
      users: 'Total Games Created',
      total: totalGames ? totalGames : 0,
      icon: '',

      husers: 'Total Games Created',
    },
    {
      users: 'Total Active Games',
      total: activeGames ? activeGames : 0,
      icon: '',
      husers: 'Total Active Games'
    },
    {
      users: 'Total Users',
      total: totalUsers ? totalUsers : 0,
      icon: faUser,
      husers: 'Total Users',
    },
    {
      users: 'Total Active Users',
      total: activeUsers ? activeUsers : 0,
      icon: faUser,
      husers: 'Total Active Users',
    },
  ]

  return (
    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} {...props} />
        <Content style={{ margin: '24px 16px 0' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard" style={{
              color: "rgba(0, 0, 0, 0.45)",

            }} >Dashboard</Link></Breadcrumb.Item>
          </Breadcrumb>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <div className="content-new">
              {
                items.map((value, index) => {
                  return (
                    <div className="col-xl-3 col-sm-4 mb-xl-0 mb-3">
                      <div className="card">
                        <div className="card-header p-3 pt-2">
                          <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                            {value?.icon ? <FontAwesomeIcon icon={value.icon} /> : <img className="dash_icon" alt="" src={images.dice} />}
                          </div>
                          <div className="text-end pt-1">
                            <p className="text-sm mb-0">{value.users}</p>
                            <h4 className="mb-0 weight">{value.total}</h4>
                          </div>
                        </div>
                        <hr className="dark horizontal my-0" />
                        <div className="card-footer p-3">
                          <p className="mb-0"><span class="text-success text-sm font-weight-bolder new"></span>{ }</p>
                        </div>
                      </div>
                    </div>
                  )
                })

              }
            </div>
          </div>
        </Content>
      </Layout>
    </Layout >


  )

}
export default Dashboard