import React from 'react';
import { Modal } from 'antd';

const CustomAlertBox = (props) => {
    const {
        isModalVisible,
        message,
        onOk,
        onClick,
        onCancle,
        onCancelBtn
    } = props


    const _customeAlertBox = () => {
        return (
            <Modal
                title="Delete"
                centered
                className="sharemodal delete"
                width={570}
                visible={isModalVisible}
                footer={null}
                onOk={onOk}
                onCancel={onCancelBtn}
            // onCancel={() => setIsModalLogoOut(false)}
            >
                <p>{'Are you sure, you want to delete this message?'}</p>
                <div className={"text-center listings center-button"}>

                    {
                        <button
                            style={{ cursor: 'pointer' }}
                            type="submit"
                            onClick={onCancle}
                            className={'button_new_one popupbuttonone borderone'}>
                            {'No'}
                        </button>
                    }

                    <button
                        style={{ cursor: 'pointer' }}
                        type="submit"
                        onClick={onClick}
                        className={'button_new_one popupbuttonone'}>
                        {'Yes'}
                    </button>
                </div>
            </Modal>

        )
    }


    return (

        _customeAlertBox()
    )

}

export default CustomAlertBox;