import React, { useState, useEffect } from "react"
import { Layout, Menu } from 'antd';
import images from '../../themes/appImage'

import {
    BrowserRouter as Router,
    Link,
    useHistory

} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import InputField from '../../customComponent/input'
import { LoginAction } from "../../redux/Action/Authentication"
import { isInternetConnected } from '../../common/checkInternet'


import { Formik } from 'formik';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ValidationConstants from '../../themes/ValidationConstants'


const { Header, Content, Footer, Sider } = Layout;

const Login = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        document.title = 'Disparity Trap';
    }, [])

    const initialValues = {
        email: '',
        password: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(ValidationConstants.valEmail).required(ValidationConstants.valEmail),
        password: Yup.string().required(ValidationConstants.reqPassword)
    });
     
    const onSubmit = (values, { setSubmitting }) => {
        console.log('on Submit hit ------', values);
        isInternetConnected() && dispatch(LoginAction(values, history))
    }

    const validate = values => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Please enter email address.';
        } else if (!/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/.test(values.email)) {
            errors.email = ValidationConstants.valEmail;
        }
        return errors;
    };



    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        validate
    });


    return (
        <div className="image-bg">
            <div className="page-header">
                <div className="line_true">
                    <div className="content-e login_1">
                        <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                            <div className="text-center cost">
                                {/* <Link to="/dashboard"> */}
                                    <img src={images.logo} />
                                    <h6 class="text-white text-capitalize ps-3 login_2">Login</h6>
                                {/* </Link> */}
                            </div>

                        </div>
                        <form noValidate onSubmit={formik.handleSubmit}>
                        <div className="wrapper_line">

                            <div className="form-group">
                                <InputField
                                    onChangeText={formik.handleChange('email')}
                                    value={formik.values.email.trim()}
                                    touched={formik.touched.email}
                                    error={formik.errors.email}
                                    placeholder="Email Address"
                                    // feildType='email'
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="form-group" style={{ marginBottom: '0px' }}>

                                <InputField
                                    onChangeText={formik.handleChange('password')}
                                    value={formik.values.password.trim()}
                                    touched={formik.touched.password}
                                    error={formik.errors.password}
                                    placeholder="Password"
                                    // feildType='email'
                                    onBlur={formik.handleBlur}
                                    type='password'
                                />

                            </div>
                            <div className="forrgot"><Link to="forgotpassword">Forgot Password</Link></div>
                          <div className="text-center button">
                           <button type="submit" className="button-list">Login</button>
                           </div>
                            <div>
                            </div>
                        </div>
                        </form>
                    </div>


                </div>
            </div>
        </div>

    )
}
export default Login;
