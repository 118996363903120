import { put, call } from 'redux-saga/effects';
import * as types from '../ActionTypes';
import { loginApi, forgotPasswordApi, checkResetPasswordLink, resetPassword, changePassword } from '../Api'
import { toast } from 'react-toastify';
import { isInternetConnected } from '../../common/checkInternet'
import { changePasswordSuccess, changePasswordFailure } from "../Action/Authentication"
import { message } from 'antd';

function* loginAgain(action) {
    yield put({
      type: types.API_AUTHENTICATION_FAILED,
    });
    localStorage.clear();
    action.history.push("/login");
  }
  

export function* LoginSaga(action) {
    console.log('new-item.........', action)
    // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
    // const token = localStorage.getItem('token')
    try {
        const response = yield call(loginApi, action.payload)
        let { data, message } = response.result;
        console.log('resultSaga.......,', response)
        if (response.status == 1) {
            // toast.success(message, { position: "top-right" })
            yield put({ type: types.API_LOGIN_SUCCESS, result: data })
            localStorage.setItem('token', data.token)
            action.history.push("/dashboard")
        }
        else {
            toast.error(message, { position: "top-right", toastId: 'custom_msg' })
            yield put({ type: types.API_LOGIN_ERROR })
        }
    }

    catch (err) {
        console.log("this is err = ", err)
        toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
        yield put({ type: types.API_LOGIN_ERROR, error: err })

    }
}


export function* LogoutSaga(action) {
    try {
        localStorage.removeItem('token');
        // localStorage.setItem(data.token, 'token')
        toast.success('Logout successfully!', {
            position: toast.POSITION.TOP_RIGHT,
            toastId: 'custom_msg'
        });
        yield put({ type: types.API_LOGOUT_SUCCESS });
        action.history.push('/login');
    } catch (error) {

        yield put({ type: types.API_LOGOUT_ERROR });

    }
}


export function* ForgotPasswordSaga(action) {
    console.log('new-item.........', action)
    // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
    // const token = localStorage.getItem('token')
    try {
        const response = yield call(forgotPasswordApi, action.payload)
        let { data, message } = response.result;

        console.log(data, 'resultSaga.......,', message)
        if (response.status == 1) {
            toast.success(message, { position: "top-right", toastId: 'custom_msg' })
            yield put({ type: types.FORGOT_PASSWORD_SUCCESS, result: data })
            localStorage.removeItem('token');
            action.history.push("/login")
        }
        else {
            yield put({ type: types.FORGOT_PASSWORD_ERROR });
            if (message == 'Please login again, user not found.' || message == 'Your password has been changed, Please login again.' || message == 'Your account is login from another device.') {
                toast.dismiss()
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'custom_msg'
                });
                localStorage.removeItem('token');
                action.history.push('/login');
            } else {
                if (isInternetConnected()) {

                    toast.error(message, { position: "top-right", toastId: 'custom_msg' })
                } else {
                    toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                }
            }
        }
    }

    catch (err) {
        toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
        yield put({ type: types.FORGOT_PASSWORD_ERROR, error: err })

    }
}
export function* checkResetLinksaga(action) {
    console.log('checkSaga', action)
    toast.dismiss();
    const customId = "custom-id-yes";
    try {
        let response = yield call(checkResetPasswordLink, action.data);

        let { result, status } = response;
        console.log('checkResetLink', response)
        if (status === 1) {
            yield put({
                type: types.CHECK_RESET_LIST_SUCCESS,
                payload: result.data.showLink
            });
        }
        else {
            // toast.error('', { position: "top-right" })
            yield put({ type: types.CHECK_RESET_LIST_ERROR })
        }
    } catch (error) {
        yield put({
            type: types.CHECK_RESET_LIST_ERROR,
            payload: false
        });

    }
}

export function* resetPasswordSaga(action) {
    toast.dismiss();
    const customId = "custom-id-yes";
    try {
        let response = yield call(resetPassword, action.data);
        let { result, status } = response;
        console.log('resetPasswordSaga------', response);
        if (status === 1) {
            // toast.success(result.message, {
            //     position: toast.POSITION.TOP_RIGHT,
            //     toastId: customId
            // });
            yield put({
                type: types.API_RESET_PASSWORD_SUCCESS
            });
            localStorage.removeItem('token');
            // action.history.push('/login');
        }
        else {
            console.log('resetPasswordSaga else ------', response);
            yield put({ type: types.API_RESET_PASSWORD_ERROR, result: response.result })
            if (isInternetConnected()) {
                if (response.result.message == 'Link expired.') {

                } else {

                    toast.error(response.result.message, { position: "top-right" })
                }
            } else {
                toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
            }
        }
    } catch (error) {
        console.log('resetPasswordSaga else ------', error);
        toast.error(error?.message, { position: "top-right", toastId: 'custom_msg' })
        yield put({ type: types.API_RESET_PASSWORD_ERROR, result: error });

    }
}

export function* changePasswordSaga(action) {
    console.log('new-item.........', action)
    try {
      const response = yield call(changePassword, action.payload)
      let { data, message } = response.result;
      console.log('resultSaga.......,', response)
      if (response.status == 1) {
        
        yield put(changePasswordSuccess(data))
        localStorage.clear()
        action.history.push("/");
        toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      }
      else {
        console.log('resultSaga.......,')
        toast.error(message, { position: "top-right", toastId: 'custom_msg' })
        yield put(changePasswordFailure(message))
      }
    }
  
    catch (err) {
      if (err.status === 4) {
        yield call(loginAgain, action);
        toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
        yield put(changePasswordFailure(err))
      } else {
        console.log('resultSaga.......,', err)
        toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
        yield put(changePasswordFailure(err))
      }
  
    }
  }
