import APIKit from './apiKit';
import { convertArrayToCSV } from "convert-array-to-csv";
// import { isInternetConnected } from '../../common/checkInternet/index';
// import { cancelTokenSource } from './apiKit'

const StatusCodes = {
    "Success": 1,
    "Failure": 0,
    "ServerDown": 2,
    "Unauthenticate": 3
}

export const API_Method = {
    async POST(url, body) {
        console.log(url, "manu sarna", body)
        return await new Promise((resolve, reject) => {

            APIKit.post(url, body, {
                headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                    'accept': 'application/json'
                },
            })
                .then(async (data) => {
                    if (data) {
                        if (data.status === 200) {
                            console.log("kit = ", data)
                            return resolve({
                                status: StatusCodes.Success,
                                result: data.data,
                            })
                        }
                        else {
                            return reject({
                                result: { message: data.data.message },
                                status: StatusCodes.Failure,
                            })
                        }
                    } else {
                        return reject({
                            result: { message: "Something went wrong." },
                            status: StatusCodes.Failure
                        })
                    }
                })
                .catch(async (error) => {
                    console.log('errorMessage', error.response)
                    if (!error.response) {
                        return reject({
                            result: { message: "Timeout : server issue" },
                            status: StatusCodes.Failure,
                        })
                    } else if (
                        error.response.status == 403
                    ) {
                        return reject({
                            status: 4,
                            error: error?.response?.data?.message,
                        });
                    } else {
                        return reject({
                            message: error.response.data.Message ? error.response.data.Message : error.response.data.message,// error.response.data.message
                            status: StatusCodes.Failure,
                        })
                    }
                });
        })
    },
    async PUT(url, body) {
        return await new Promise((resolve, reject) => {

            APIKit.put(url, body, {
                headers: {
                    'access-control-allow-origin': '*',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(async (data) => {
                    console.log('resultData', data)
                    if (data) {
                        if (data.status === 200) {
                            return resolve({
                                status: StatusCodes.Success,
                                result: data.data,
                            })
                        }
                        else {
                            return reject({
                                result: { message: data.data.message },
                                status: StatusCodes.Failure,
                            })
                        }
                    } else {
                        return reject({
                            result: { message: "Something went wrong." },
                            status: StatusCodes.Failure
                        })
                    }
                })
                .catch(async (error) => {
                    console.log('resultData', error.response)
                    if (!error.response) {
                        return reject({
                            result: { message: "Timeout : server issue" },
                            status: StatusCodes.Failure,
                        })
                    } else if (
                        error.response.status == 403
                    ) {
                        return reject({
                            status: 4,
                            error: error?.response?.data?.message,
                        });
                    } else {
                        return reject({
                            message: error.response.data.Message ? error.response.data.Message : error.response.data.message,
                            status: StatusCodes.Failure,
                        })
                    }
                });
        })
    },
    async DELETE(url, body) {
        return APIKit.delete(url, body, {
            headers: {
                'access-control-allow-origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(async (data) => {
                if (data) {
                    console.log('data ---delete-', data);
                    if (data.status === 200) {
                        return {
                            status: StatusCodes.Success,
                            result: data.data,
                        }
                    }
                    else {
                        return {
                            result: { message: data.data.message },
                            status: StatusCodes.Failure,
                        }
                    }
                } else {
                    return {
                        result: { message: "Something went wrong." },
                        status: StatusCodes.Failure
                    }
                }
            })
            .catch(async (error) => {
                if (!error.response) {
                    return {
                        result: { message: "Timeout : server issue" },
                        status: StatusCodes.Failure,
                    }
                } else if (error.response.data && error.response.data.error && error.response.data.error) {
                    return {
                        result: { message: error.response.data.error },// error.response.data.message
                        status: StatusCodes.Failure,
                    }
                } else {
                    return {
                        result: { message: error.response.data.Message ? error.response.data.Message : error.response.data.message },
                        status: StatusCodes.Failure,
                    }
                }
            });
    },
    async GET(url, body) {
        return await new Promise((resolve, reject) => {

            APIKit.get(url, {
                headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                }
            })
                .then(async (data) => {
                    console.log('error --catch--', data);
                    if (data) {
                        if (data.status === 200) {
                            return resolve({
                                status: StatusCodes.Success,
                                result: data.data,
                            })
                        }
                        else {
                            return reject({
                                result: { message: data.data.message },
                                status: StatusCodes.Failure,
                            })
                        }
                    } else {
                        return reject({
                            result: { message: "Something went wrong." },
                            status: StatusCodes.Failure
                        })
                    }
                })
                .catch(async (error) => {
                    if (!error.response) {
                        return reject({
                            result: { message: "Timeout : server issue" },
                            status: StatusCodes.Failure,
                        })
                    }
                    else if (
                        error.response.status == 403
                    ) {
                        return reject({
                            status: 4,
                            error: error?.response?.data?.message,
                        });
                    }
                    else {
                        console.log('error --catch--', error.response);
                        return reject({
                            result: { message: error.response.data.Message ? error.response.data.Message : error.response.data.message },
                            status: StatusCodes.Failure,
                        })
                    }
                });
        })
    },
    GETDATA(url, fileName) {
        return new Promise((resolve, reject) => {
            APIKit
                .get(url, {
                    // responseType: 'arraybuffer',
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/csv",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        let Headers
                        if (fileName === "users_data") {
                            Headers = [
                                "User ID",
                                "User Name",
                                "Email Address",
                                "Games Played",
                                "Currently Hosting"
                            ]
                        } else if (fileName === "games_data") {
                            Headers = [
                                "Room ID",
                                "Created Date/Time(PST TIME)",
                                "Winner Name",
                                "Room Creater",
                                "Currently Hosting",
                                "Total Players",
                                "Game Length"
                            ];
                        }
                        else if (fileName === "voucher_data") {
                            Headers = [
                                "Email Address",
                                "Created Date/Time(PST TIME)",
                                "Voucher Code",
                                "Mail Status",
                            ];
                        }

                        let header = Headers;
                        console.log("api method = ", result);
                        const csvFromArrayOfObjects = convertArrayToCSV(result.data, {
                            header,
                        });
                        // CsvDataService.exportToCsv(`${fileName}.csv`, result.data)
                        const url = window.URL.createObjectURL(
                            new Blob([csvFromArrayOfObjects])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName + ".csv"); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        // const url = window.URL.createObjectURL(new Blob([result.data]));
                        //   const link = document.createElement('a');
                        //   link.href = url;
                        //   link.setAttribute('download', fileName + '.csv'); //or any other extension
                        //   document.body.appendChild(link);
                        //   link.click();
                        return resolve({
                            status: 1,
                            result: result,
                        });
                    } else {
                        if (result) {
                            return reject({
                                status: 3,
                                error: result.data.message,
                            });
                        } else {
                            return reject({
                                status: 4,
                                error: "Something went wrong.",
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log("error inside getdata", err);
                    if (err.response) {
                        if (
                            err.response.status !== null &&
                            err.response.status !== undefined
                        ) {
                            if (err.response.status == 500) {
                                return reject({
                                    status: 2,
                                    error: err,
                                });
                            } else if (err.response.status == 403) {
                                return reject({
                                    status: 4,
                                    error: err?.response?.data?.message,
                                });
                            } else if (
                                err.response.status == 400 ||
                                err.response.status == 401 ||
                                err.response.status == 404
                            ) {
                                return reject({
                                    status: 4,
                                    error: err?.response?.data?.message,
                                });
                            } else {
                                return reject({
                                    status: 8,
                                    error: err,
                                });
                            }
                        }
                    } else {
                        return reject({
                            status: 6,
                            error: 'Something went Wrong',
                        });
                    }
                });
        });
    },

}