import { put, call } from 'redux-saga/effects';
import { message } from 'antd';
import {
  vouchersList,
  addVoucher,
  uploadCSV,
  exportVoucherList,
  deleteVoucher
} from '../Api'
import { toast } from 'react-toastify';
import * as types from '../ActionTypes';
import {
  getVouchersListSuccess,
  getVouchersListFailure,
  addVoucherSuccess,
  addVoucherFailure,
  deleteVoucherListSuccess,
  deleteVoucherListFailure
} from "../Action/vouchers"

function* loginAgain(action) {
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  localStorage.clear();
  action.history.push("/login");
}

export function* getVouchersListSaga(action) {
  console.log('new-item.........', action)
  // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
  // const token = localStorage.getItem('token')
  try {
    const response = yield call(vouchersList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      yield put(getVouchersListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getVouchersListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getVouchersListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getVouchersListFailure(err))
    }

  }
}

export function* addVoucherSaga(action) {
  console.log('new-item.........', action)
  // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
  // const token = localStorage.getItem('token')
  try {
    const response = yield call(addVoucher, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addVoucherSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addVoucherFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(addVoucherFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addVoucherFailure(err))
    }

  }
}


export function* uploadCSVSaga(action) {
  try {
    const response = yield call(uploadCSV, action.payload)
    let { data, message } = response.result;
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addVoucherSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addVoucherFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(addVoucherFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addVoucherFailure(err))
    }

  }
}

export function* exportVouchersListSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(exportVoucherList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      // yield put(exportUserListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      // yield put(exportUserListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      // yield put(exportUserListFailure(err))
    } else {
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      // yield put(exportUserListFailure(err))
    }

  }
}


export function* voucherDeleteSaga(action) {
  try {
    const response = yield call(deleteVoucher, action.payload)
    let { data, message } = response.result;
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteVoucherListSuccess(action.payload))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteVoucherListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteVoucherListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteVoucherListFailure(err))
    }

  }
}
