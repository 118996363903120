import * as types from '../ActionTypes';
import { usersList } from '../Api';

const initialState = {
  faqListLoader: false,
  faqListSuccess: false,
  faqList: [],
  offsetValue: 0,
  searchValue: '',
  sortBy: null,
  sortOrder: null,
  faqListPagination: {},
  faqDetailLoader: false,
  faqDetailSuccess: false,
  faqDetail: {},
  addFaqLoader: false,
  addFaqSuccess: false,
  editFaqLoader: false,
  editFaqSuccess: false,
  editFaqDetail: {},
  deleteFaqLoader: false,
  deleteFaqSuccess: false,
  contactDetailLoader: false,
  contactDetailSuccess: false,
  contactDetail: {},
  editContactLoader: false,
  editContactSuccess: false,
  editContactDetail: {},
  aboutDetailLoader: false,
  aboutDetailSuccess: false,
  aboutDetail: {},
  editAboutLoader: false,
  editAboutSuccess: false,
  editAboutDetail: {},
  privacyDetailLoader: false,
  privacyDetailSuccess: false,
  privacyDetail: {},
  editPrivacyLoader: false,
  editPrivacySuccess: false,
  editPrivacyDetail: {},
  editTermsLoader: false,
  editTermsSuccess: false,
  editTermsDetail: {},
  termsLoader: false,
  termsSuccess: false,
  terms: {},
  faq: '1'
}

export const gamesSettings = (state = initialState, { payload, key, type }) => {
  switch (type) {
    case types.GET_FAQ_LIST_INITIATE:
      return {
        ...state,
        faqListLoader: true,
        faqListSuccess: false
      }

    case types.GET_FAQ_LIST_SUCCESS:
      return {
        ...state,
        faqListLoader: false,
        faqListSuccess: true,
        faqList: payload.list,
        faqListPagination: payload.pagination
      }

    case types.GET_FAQ_LIST_FAILURE:
      return {
        ...state,
        faqListLoader: false,
      }

    case types.GET_FAQ_DETAILS_INITIATE:
      return {
        ...state,
        faqDetailLoader: true,
        faqListSuccess: false
      }

    case types.GET_FAQ_DETAILS_SUCCESS:
      return {
        ...state,
        faqListSuccess: true,
        faqDetailLoader: false,
        faqDetail: payload
      }

    case types.GET_FAQ_DETAILS_FAILURE:
      return {
        ...state,
        faqDetailLoader: false,
        faq: '1'
      }

    case types.ADD_FAQ_INITIATE:
      return {
        ...state,
        addFaqLoader: true,
        addFaqSuccess: false
      }

    case types.ADD_FAQ_SUCCESS:
      return {
        ...state,
        addFaqLoader: false,
        addFaqSuccess: true
      }

    case types.ADD_FAQ_FAILURE:
      return {
        ...state,
        addFaqLoader: false,
      }

    case types.DELETE_FAQ_DETAIL_INITIATE:
      return {
        ...state,
        deleteFaqLoader: true,
        deleteFaqSuccess: false
      }

    case types.DELETE_FAQ_DETAIL_SUCCESS:
      return {
        ...state,
        deleteFaqLoader: false,
        deleteFaqSuccess: true,
      }

    case types.DELETE_FAQ_DETAIL_FAILURE:
      return {
        ...state,
        deleteFaqLoader: false,
        faq: '1',
        deleteFaqSuccess: true
      }

    case types.EDIT_FAQ_DETAIL_INITIATE:
      return {
        ...state,
        editFaqLoader: true,
        editFaqSuccess: false
      }

    case types.EDIT_FAQ_DETAIL_SUCCESS:
      return {
        ...state,
        editFaqLoader: false,
        editFaqSuccess: true,
        editFaqDetail: payload
      }

    case types.EDIT_FAQ_DETAIL_FAILURE:
      return {
        ...state,
        editFaqLoader: false,
      }

    case types.GET_CONTACT_DETAILS_INITIATE:
      return {
        ...state,
        contactDetailLoader: true,
        contactDetailSuccess: false
      }

    case types.GET_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        contactDetailSuccess: true,
        contactDetailLoader: false,
        contactDetail: payload
      }

    case types.GET_CONTACT_DETAILS_FAILURE:
      return {
        ...state,
        contactDetailLoader: false
      }

    case types.EDIT_CONTACT_DETAIL_INITIATE:
      return {
        ...state,
        editContactLoader: true,
        editContactSuccess: false
      }

    case types.EDIT_CONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        editContactLoader: false,
        editContactSuccess: true,
        editContactDetail: payload
      }

    case types.EDIT_CONTACT_DETAIL_FAILURE:
      return {
        ...state,
        editContactLoader: false,
      }

    case types.GET_ABOUT_DETAILS_INITIATE:
      return {
        ...state,
        aboutDetailLoader: true,
        aboutDetailSuccess: false
      }

    case types.GET_ABOUT_DETAILS_SUCCESS:
      return {
        ...state,
        aboutDetailSuccess: true,
        aboutDetailLoader: false,
        aboutDetail: payload
      }

    case types.GET_ABOUT_DETAILS_FAILURE:
      return {
        ...state,
        aboutDetailLoader: false
      }

    case types.EDIT_ABOUT_DETAIL_INITIATE:
      return {
        ...state,
        editAboutLoader: true,
        editAboutSuccess: false
      }

    case types.EDIT_ABOUT_DETAIL_SUCCESS:
      return {
        ...state,
        editAboutLoader: false,
        editAboutSuccess: true,
        editAboutDetail: payload
      }

    case types.EDIT_ABOUT_DETAIL_FAILURE:
      return {
        ...state,
        editAboutLoader: false,
      }

    case types.GET_PRIVACY_DETAILS_INITIATE:
      return {
        ...state,
        privacyDetailLoader: true,
        privacyDetailSuccess: false
      }

    case types.GET_PRIVACY_DETAILS_SUCCESS:
      return {
        ...state,
        privacyDetailSuccess: true,
        privacyDetailLoader: false,
        privacyDetail: payload
      }

    case types.GET_PRIVACY_DETAILS_FAILURE:
      return {
        ...state,
        privacyDetailLoader: false
      }

    case types.GET_TERMS_INITIATE:
      return {
        ...state,
        termsLoader: true,
        termsSuccess: false
      }

    case types.GET_TERMS_SUCCESS:
      return {
        ...state,
        termsSuccess: true,
        termsLoader: false,
        terms: payload
      }

    case types.GET_TERMS_FAILURE:
      return {
        ...state,
        termsLoader: false
      }

    case types.EDIT_PRIVACY_DETAIL_INITIATE:
      return {
        ...state,
        editPrivacyLoader: true,
        editPrivacySuccess: false
      }

    case types.EDIT_PRIVACY_DETAIL_SUCCESS:
      return {
        ...state,
        editPrivacyLoader: false,
        editPrivacySuccess: true,
        editPrivacyDetail: payload
      }

    case types.EDIT_PRIVACY_DETAIL_FAILURE:
      return {
        ...state,
        editPrivacyLoader: false,
      }

    case types.EDIT_TERMS_INITIATE:
      return {
        ...state,
        editTermsLoader: true,
        editTermsSuccess: false
      }

    case types.EDIT_TERMS_SUCCESS:
      return {
        ...state,
        editTermsLoader: false,
        editTermsSuccess: true,
        editTermsDetail: payload
      }

    case types.EDIT_TERMS_FAILURE:
      return {
        ...state,
        editTermsLoader: false,
      }

    case types.UPDATE_GAME_SETTINGS_STATES:
      state[key] = payload
      return { ...state, };

    case types.CLEAR_GAME_SETTINGS_DATA:
      return {
        ...state,
        faqListSuccess: false,
        addFaqSuccess: false,
        deleteFaqSuccess: false,
        editFaqSuccess: false,
        contactDetailSuccess: false,
        editContactSuccess: false,
        aboutDetailSuccess: false,
        editAboutSuccess: false,
        privacyDetailSuccess: false,
        termsSuccess: false,
        editPrivacySuccess: false,
        editTermsSuccess: false,
      }

    default:
      return state
  }
}

