import { combineReducers } from 'redux';
// import { commonReducer } from "./commonReducer";
import * as types from '../ActionTypes'
import { LoginReducer } from './Authenticationreduce';
import { DashboardReducer } from './Dashboardreducer';
import { users } from './users';
import { vouchers } from "./vouchers"
import { games } from "./games"
import { contactUs } from "./contactUs"
import { gamesSettings } from "./gameSettings"
import { notifications } from "./notifications"



export const rootReducer = combineReducers({
    LoginReducer,
    DashboardReducer,
    users,
    vouchers,
    games,
    contactUs,
    gamesSettings,
    notifications
});
