
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify';
import moment from 'moment'
import {
  Layout,
  Pagination,
  DatePicker,
  Space,
  Table,
  Breadcrumb,
  Modal,
  Tooltip
} from 'antd';
import images from '../../themes/appImage'

import {
  BrowserRouter as Router,
  Link,
  useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { getMemoizedGamesData } from "../../redux/selectors/games"
import { exportGamesListInitiate, getGamesListInitiate, updateGamesAction, deleteGamesListInitiate } from "../../redux/Action/games"
import { arrayMap } from "../../common/utils";

const { RangePicker } = DatePicker;

const { Header, Content, Footer, Sider } = Layout;

const GameManagement = (props) => {

  const dispatch = useDispatch()
  const history = useHistory()

  const gamesData = useSelector(getMemoizedGamesData)
  const {
    gamesList,
    offsetValue,
    searchValue,
    sortBy,
    sortOrder,
    startDate,
    endDate,
    gamesListPagination,
    gamesListLoader
  } = gamesData

  const [search, setSearch] = useState('')
  const [dates, setDates] = useState([]);
  const [isVisible, setVisible] = useState(false)

  useLayoutEffect(() => {

    const data = {
      search: searchValue,
      sort: sortBy,
      order: sortOrder,
      offset: searchValue ? 0 : offsetValue,
      limit: 20,
      startDate: startDate,
      endDate: endDate
    }
    dispatch(getGamesListInitiate(data, history))
  }, [])

  const columns = [{
    title: 'Sr. No.',
    dataIndex: 'srno',
    key: 'srno',
    render: (value, item, index) => <div style={{ width: '60px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{(index + 1 + offsetValue)}</div>,
  },
  {
    title: 'Room ID',
    dataIndex: 'roomId',
    key: 'roomId',
    render: (value, item, index) => <div style={{ width: '80px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.roomId || 'N/A'}</div>,
    sorter: true,
  },
  // {
  //   title: 'Room Name',
  //   dataIndex: 'roomname',
  //   key: 'roomname',
  //   render: (value, item, index) => <div style={{ width: '100px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.roomName || 'N/A'}</div>,
  //   sorter: true,
  // },
  {
    title: 'Created Date/Time',
    dataIndex: 'createdOn',
    key: 'createdOn',
    render: (value, item, index) => <div style={{ width: '150px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.createdOn ? moment(item?.createdOn).format('DD/MM/YYYY hh:mm A') : 'N/A'}</div>,
    sorter: true,
  },
  {
    title: 'Winner Name',
    dataIndex: 'winner',
    key: 'winner',
    render: (value, item, index) => <div style={{ width: '160px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><Tooltip title={(item?.winner && item?.winner.length > 0) ? arrayMap(item?.winner) : 'N/A'}>{(item?.winner && item?.winner.length > 0) ? arrayMap(item?.winner) : 'N/A'}</Tooltip></div>,
    sorter: false,
  },
  {
    title: 'Room Creater',
    dataIndex: 'host',
    key: 'host',
    render: (value, item, index) => <div style={{ width: '110px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.host?.name ? item?.host?.name : 'N/A'}</div>,
    sorter: true,
  },
  {
    title: 'Currently Running',
    dataIndex: 'isStart',
    key: 'isStart',
    render: (value, item, index) => <div style={{ width: '135px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{(item?.isStart && !item?.isEnd) ? 'Yes' : 'No'}</div>,
    sorter: true,
  },

  {
    title: 'Total Players',
    dataIndex: 'players',
    key: 'players',
    render: (value, item, index) => <div style={{ width: '135px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.players}</div>,
    sorter: true,
  },
  {
    title: 'Game Length',
    dataIndex: 'gameLength',
    key: 'gameLength',
    sorter: true,
    render: (value, item, index) => <div style={{ width: '135px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{getGameLength(item?.gameLength)}</div>,
  },
  ];

  const getGameLength = (ms) => {
    if (ms && ms != 'In Progress') {
      let seconds = ms / 1000;
      const hours = parseInt(seconds / 3600);
      seconds = seconds % 3600;
      const minutes = parseInt(seconds / 60);
      seconds = seconds % 60;
      console.log(hours + 'hr' + " " + minutes + 'min');

      if (hours < 1) {
        return minutes > 1 ? `${minutes} mins` : `${minutes} min`
      }
      else {
        return hours > 1 ? `${hours}hrs ${minutes} mins` : `${hours}hr ${minutes} mins`
      }

    }
    else {
      return 'In Progress'
    }
  }

  const deleteModel = (record) => {
    console.log(record, "record****")
    Modal.confirm({
      title: "Delete Room",
      content: `Are you sure, you want to delete this room?`,
      okText: 'Yes',
      centered: true,
      cancelText: 'No',
      onOk() {
        dispatch(deleteGamesListInitiate(record._id))
      },
      onCancel() {

      },
      className: "new-button"
    });
  };

  const handlewClick = () => {
    setVisible(!isVisible)
  }
  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)

  }, [])

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlTableChange = (pagination, filter, sorter) => {
    console.log("this is event = ", sorter)
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      if (sorter.order == "ascend") {
        const data = {
          search: searchValue,
          sort: sorter.field,
          order: 1,
          offset: searchValue ? 0 : offsetValue,
          limit: 20,
          startDate: startDate,
          endDate: endDate
        }
        dispatch(updateGamesAction(1, 'sortOrder'))
        dispatch(updateGamesAction(sorter.field, 'sortBy'))
        dispatch(getGamesListInitiate(data, history))

      } //To fetch listing in descending order
      else if (sorter.order === "descend") {
        const data = {
          search: searchValue,
          sort: sorter.field,
          order: -1,
          offset: searchValue ? 0 : offsetValue,
          limit: 20,
          startDate: startDate,
          endDate: endDate
        }
        dispatch(updateGamesAction(-1, 'sortOrder'))
        dispatch(updateGamesAction(sorter.field, 'sortBy'))
        dispatch(getGamesListInitiate(data, history))
      } //To fetch listing in normal order
      else {
        const data = {
          search: searchValue,
          sort: null,
          order: null,
          offset: searchValue ? 0 : offsetValue,
          limit: 20,
          startDate: startDate,
          endDate: endDate
        }
        dispatch(updateGamesAction(null, 'sortOrder'))
        dispatch(updateGamesAction(null, 'sortBy'))
        dispatch(getGamesListInitiate(data, history))
      }
    }
  }

  const handleSearch = (e) => {
    let searchWord = e.target.value
    let isOnlyBlankSpace = false
    if (searchWord.charAt(0) === " ") {
      if (searchWord.length == 1 && searchWord == " ") {
        isOnlyBlankSpace = true
      }
      searchWord = searchWord.replace(/^ +/gm, '')
      setSearch(searchWord.replace(/^ +/gm))
      dispatch(updateGamesAction(searchWord, 'searchValue'))
    } else {
      dispatch(updateGamesAction(searchWord, 'searchValue'))
      setSearch(searchWord)
    }
    if (isOnlyBlankSpace === false) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: null,
        order: null,
        offset: searchWord.trim().length === 0 ? offsetValue : 0,
        limit: 20,
        startDate: startDate,
        endDate: endDate
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {

        dispatch(getGamesListInitiate(data, history))
      }
    } else if (searchWord !== search) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: "",
        order: null,
        offset: searchWord.trim().length == 0 ? offsetValue : 0,
        limit: 20,
        startDate: startDate,
        endDate: endDate
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {

        dispatch(getGamesListInitiate(data, history))
      }
    }
  }

  const daysCount = (dates) => {
    var given = moment(dates).add(1, 'months').startOf('day');
    var current = moment(dates);

    let res = moment.duration(given.diff(current)).days();
    return res
  }

  const disabledDate = current => {

    if (!dates || dates.length === 0) {
      return current && current < moment().subtract(2, 'years').endOf('day');
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > daysCount(dates[0]);
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > daysCount(dates[1]);
    return tooEarly || tooLate;
  };

  return (
    <div>
      <Layout>
        <Sidebar />
        <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
        <Layout className="new_layout">
          <HeaderAdmin handlewClick={handlewClick} {...props} />
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>{appconstant.gamemanagement} </Breadcrumb.Item>
          </Breadcrumb>
          <Content style={{ margin: '24px 16px 0' }}>
            <div className="top_button_item d-flex justify-content-between">
              <div className="button_left">
                <button
                  type="submit"
                  className="button-list top_new"
                  onClick={() => {
                    if (!navigator.onLine) {
                      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                    } else {
                      dispatch(exportGamesListInitiate('games_data', history))
                    }
                  }}
                >
                  {appconstant.export}
                </button>
                <Link to="/prelistedwords"><button type="submit" className="button-list top_new">{appconstant.prelistedwords}</button>
                </Link>
              </div>
              <Space direction="vertical" size={12}>
                <RangePicker
                  disabledDate={disabledDate}
                  onCalendarChange={val => {
                    setDates(val)
                  }}
                  defaultValue={startDate && [moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                  onChange={(e, v) => {
                    console.log("this is picker = ", e, v)
                    let startDate = ''
                    let endDate = ''
                    if (e && e.length > 0) {
                      startDate = `${moment(e[0]).format('YYYY-MM-DD')}T00:00:00`
                      endDate = `${moment(e[1]).format('YYYY-MM-DD')}T23:59:59`

                      dispatch(updateGamesAction(startDate, 'startDate'))
                      dispatch(updateGamesAction(endDate, 'endDate'))
                      const data = {
                        search: searchValue,
                        sort: sortBy,
                        order: sortOrder,
                        offset: searchValue ? 0 : offsetValue,
                        limit: 20,
                        startDate: startDate,
                        endDate: endDate
                      }
                      if (!navigator.onLine) {
                        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                      } else {
                        dispatch(getGamesListInitiate(data, history))
                      }
                    } else {
                      dispatch(updateGamesAction(null, 'startDate'))
                      dispatch(updateGamesAction(null, 'endDate'))
                      const data = {
                        search: searchValue,
                        sort: sortBy,
                        order: sortOrder,
                        offset: searchValue ? 0 : offsetValue,
                        limit: 20,
                        startDate: null,
                        endDate: null
                      }
                      if (!navigator.onLine) {
                        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                      } else {
                        dispatch(getGamesListInitiate(data, history))
                      }
                    }
                  }}
                />
              </Space>
            </div>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <div className="content-e">
                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 class="text-white text-capitalize ps-3">{appconstant.gamemanagement}</h6>
                  <input
                    type="text"
                    className="search-bar"
                    placeholder="Search"
                    value={searchValue}
                    autoComplete="off"
                    name="search"
                    onChange={(e) => {
                      handleSearch(e)
                    }}
                  />
                </div>
                <Table
                  dataSource={gamesList}
                  columns={columns}
                  showSorterTooltip={false}
                  loading={gamesListLoader}
                  pagination={false}
                  onChange={handlTableChange}
                />
                <Pagination
                  pageSize="20"
                  total={gamesListPagination?.totalCount}
                  onChange={(page) => {
                    if (!navigator.onLine) {
                      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                    } else {
                      let data = (page - 1) * 20;
                      const dta = {
                        search: searchValue,
                        sort: sortBy,
                        order: sortOrder,
                        offset: data,
                        limit: 20,
                        startDate: startDate,
                        endDate: endDate
                      }
                      dispatch(updateGamesAction(data, 'offsetValue'))
                      dispatch(getGamesListInitiate(dta, history))
                    }
                  }}
                  current={gamesListPagination?.currentPage}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
      {/* <Modal title="Delete"
         visible={isModalVisible}
         onOk={handleOk}
         onCancel={handleCancel} 
        footer={null}>


        <div className="text-center  model1">
         <p>Are you sure want to delete?</p>
         </div>
         <div className="text-center  model1">
         <button type="submit" onClick = {() =>handleCancel(false)}>Ok</button>
         <button type="submit" onClick = {() =>handleCancel(false)}>Cancel</button>
         </div>
      </Modal> */}
    </div>
  )
}
export default GameManagement