import { put, call } from 'redux-saga/effects';
import { message } from 'antd';
import {
  usersList,
  usersListDetails,
  userEditApi,
  exportUsersList,
  userAddApi,
  userGamesOrgList,
  userGamesPlayedList,
  userBlockUnblockApi
} from '../Api'
import { toast } from 'react-toastify';
import * as types from '../ActionTypes';
import { isInternetConnected } from '../../common/checkInternet'
import {
  getUsersListSuccess,
  getUsersListFailure,
  exportUserListSuccess,
  exportUserListFailure,
  getUserDetailSuccess,
  getUserDetailFailure,
  userEditSuccess,
  userEditFailure,
  userAddSuccess,
  userAddFailure,
  getUserGameOrganizeListSuccess,
  getUserGameOrganizeListFailure,
  getUserGamePlayedListSuccess,
  getUserGamePlayedListFailure,
  blockUnblockUserSuccess,
  blockUnblockUserFailure
} from "../Action/users"

function* loginAgain(action) {
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  localStorage.clear();
  action.history.push("/login");
}

export function* getUsersListSaga(action) {
  console.log('new-item.........', action)
  // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
  // const token = localStorage.getItem('token')
  try {
    const response = yield call(usersList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      // toast.success(message, { position: "top-right" })
      yield put(getUsersListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUsersListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUsersListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUsersListFailure(err))
    }

  }
}

export function* getUsersDetailsSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(usersListDetails, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      yield put(getUserDetailSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUserDetailFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUserDetailFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUserDetailFailure(err))
    }

  }
}

export function* userEditSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(userEditApi, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(userEditSuccess(data))
      action.history.push('/usermanagement')
    }
    else {
      console.log('resultSaga.......,')
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(userEditFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(userEditFailure(err))
    } else {
      console.log('resultSaga.......,', err)
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(userEditFailure(err))
    }

  }
}

export function* exportUsersListSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(exportUsersList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      yield put(exportUserListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(exportUserListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(exportUserListFailure(err))
    } else {
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(exportUserListFailure(err))
    }

  }
}

export function* userAddSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(userAddApi, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(userAddSuccess(data))
      action.history.push('/usermanagement')
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(userAddFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(userAddFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(userAddFailure(err))
    }


  }
}

export function* getUserGameOrgListSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(userGamesOrgList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      yield put(getUserGameOrganizeListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUserGameOrganizeListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUserGameOrganizeListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUserGameOrganizeListFailure(err))
    }

  }
}

export function* getUserGamePlayedListSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(userGamesPlayedList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      yield put(getUserGamePlayedListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUserGamePlayedListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUserGamePlayedListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getUserGamePlayedListFailure(err))
    }

  }
}

export function* userBlockUnblockSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(userBlockUnblockApi, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right" })
      yield put(blockUnblockUserSuccess(action.payload))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(blockUnblockUserFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(blockUnblockUserFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(blockUnblockUserFailure(err))
    }

  }
}

