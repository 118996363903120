import * as types from '../ActionTypes';
import { usersList } from '../Api';

const initialState = {
  userListLoader: false,
  usersListSuccess: false,
  usersList: [],
  offsetValue: 0,
  searchValue: '',
  sortBy: null,
  sortOrder: null,
  startDate: null,
  endDate: null,
  usersListPagination: {},
  userDetailLoader: false,
  userDetailSuccess: false,
  userDetail: {},
  userEditLoader: false,
  userEditSuccess: false,
  userEditDetail: {},
  userAddLoader: false,
  userAddSuccess: false,
  userAddDetail: {},
  blockUnblockUserLoader: false,
  blockUnblockUserSuccess: false,
  exportUserListLoader: false,
  exportUserListSuccess: false,
  exportUserList: [],
  userGameOrgListLoader: false,
  userGameOrgListSuccess: false,
  userGameOrgList: [],
  userGameOrgListPagination: {},
  gameOrgOffsetValue: 0,
  gameOrgSearchValue: '',
  gameOrgSortBy: null,
  gameOrgSortOrder: null,
  userGamePlayListLoader: false,
  userGamePlayListSuccess: false,
  userGamePlayList: [],
  userGamePlayListPagination: {},
  gamePlayOffsetValue: 0,
  gamePlaySearchValue: '',
  gamePlaySortBy: null,
  gamePlaySortOrder: null,
}

export const users = (state = initialState, { payload, key, type }) => {
  switch (type) {
    case types.GET_USERS_LIST_INITIATE:
      return {
        ...state,
        userListLoader: true,
        usersListSuccess: false
      }

    case types.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        userListLoader: false,
        usersListSuccess: true,
        usersList: payload.list,
        usersListPagination: payload.pagination
      }

    case types.GET_USERS_LIST_FAILURE:
      return {
        ...state,
        userListLoader: false,
      }

    case types.GET_USER_DETAIL_INITIATE:
      return {
        ...state,
        userDetailLoader: true,
        userDetailSuccess: false
      }

    case types.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetailLoader: false,
        userDetailSuccess: true,
        userDetail: payload
      }

    case types.GET_USER_DETAIL_FAILURE:
      return {
        ...state,
        userDetailLoader: false,
      }

    case types.EDIT_USER_DETAIL_INITIATE:
      return {
        ...state,
        userEditLoader: true,
        userEditSuccess: false
      }

    case types.EDIT_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userEditLoader: false,
        userEditSuccess: true,
        userEditDetail: payload
      }

    case types.EDIT_USER_DETAIL_FAILURE:
      return {
        ...state,
        userEditLoader: false,
      }

    case types.ADD_USER_DETAIL_INITIATE:
      return {
        ...state,
        userAddLoader: true,
        userAddSuccess: false
      }

    case types.ADD_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userAddLoader: false,
        userAddSuccess: true,
        userEditDetail: payload
      }

    case types.ADD_USER_DETAIL_FAILURE:
      return {
        ...state,
        userAddLoader: false,
      }

    case types.BLOCK_UNBLOCK_USER_INITIATE:
      return {
        ...state,
        blockUnblockUserLoader: true,
        blockUnblockUserSuccess: false
      }

    case types.BLOCK_UNBLOCK_USER_SUCCESS:
      let tempList = [...state.usersList]

      tempList.forEach((itm, index) => {
        console.log("user = ", itm)
        if (payload.status && itm._id === payload.userId) {
          return itm.block = true
        } else if (!payload.status && itm._id === payload.userId) {
          return itm.block = false
        }
        return itm
      })

      return {
        ...state,
        blockUnblockUserLoader: false,
        blockUnblockUserSuccess: true,
        usersList: tempList
      }

    case types.BLOCK_UNBLOCK_USER_FAILURE:
      return {
        ...state,
        blockUnblockUserLoader: false,
      }

    case types.EXPORT_USER_LIST_INITIATE:
      return {
        ...state,
        exportUserListLoader: true,
        exportUserListSuccess: false
      }

    case types.EXPORT_USER_LIST_SUCCESS:
      return {
        ...state,
        exportUserListLoader: false,
        exportUserListSuccess: true,
        exportUserList: payload
      }

    case types.EXPORT_USER_LIST_FAILURE:
      return {
        ...state,
        exportUserListLoader: false,
      }

    case types.GET_USER_GAME_ORGANIZE_LIST_INITIATE:
      return {
        ...state,
        userGameOrgListLoader: true,
        userGameOrgListSuccess: false
      }

    case types.GET_USER_GAME_ORGANIZE_LIST_SUCCESS:
      return {
        ...state,
        userGameOrgListLoader: false,
        userGameOrgListSuccess: true,
        userGameOrgList: payload.list,
        userGameOrgListPagination: payload.pagination
      }

    case types.GET_USER_GAME_ORGANIZE_LIST_FAILURE:
      return {
        ...state,
        userGameOrgListLoader: false,
      }

    case types.GET_USER_GAME_PLAYED_LIST_INITIATE:
      return {
        ...state,
        userGamePlayListLoader: true,
        userGamePlayListSuccess: false
      }

    case types.GET_USER_GAME_PLAYED_LIST_SUCCESS:
      return {
        ...state,
        userGamePlayListLoader: false,
        userGamePlayListSuccess: true,
        userGamePlayList: payload.list,
        userGamePlayListPagination: payload.pagination
      }

    case types.GET_USER_GAME_PLAYED_LIST_FAILURE:
      return {
        ...state,
        userGamePlayListLoader: false,
      }

    case types.UPDATE_USERS_STATES:
      state[key] = payload
      return { ...state, };

    case types.CLEAR_USERS_DATA:
      return {
        ...state,
        userDetailSuccess: false,
        userEditSuccess: false,
        blockUnblockUserSuccess: false,
        exportUserListSuccess: false,
        userGameOrgListSuccess: false,
        userGamePlayListSuccess: false,
        userAddSuccess: false
      }

    default:
      return state
  }
}

