
import { createStore, applyMiddleware } from "redux";
import { rootReducer } from '../Reducer/index'
import createSagaMiddleware from "redux-saga";
import { createLogger } from 'redux-logger'
import rootSaga from "../Saga";
const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
  duration: true,
  timestamp: true,
})

export const configureStore = () => {

  const store = createStore(rootReducer,
    applyMiddleware(sagaMiddleware, logger),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}