import React, { useState, useEffect, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify';
import moment from 'moment'
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import {
  Link,
  useHistory
} from "react-router-dom";
import { Layout, Pagination, DatePicker, Space, Table, Breadcrumb, Modal } from 'antd';

import images from '../../themes/appImage'
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import appconstant from "../../themes/appconstant";
import { getMemoizedUsersData } from "../../redux/selectors/users"
import { getUsersListInitiate, updateUsersAction, exportUserListInitiate, blockUnblockUserInitiate } from "../../redux/Action/users"

const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;



const UserManagement = (props) => {


  const dispatch = useDispatch()
  const history = useHistory()

  const usersData = useSelector(getMemoizedUsersData)

  console.log("this is data = ", usersData)
  const {
    usersList,
    offsetValue,
    searchValue,
    sortBy,
    sortOrder,
    startDate,
    endDate,
    usersListPagination,
    userListLoader,
    usersListSuccess,
    blockUnblockUserLoader,
    blockUnblockUserSuccess,
  } = usersData

  const [search, setSearch] = useState('')
  const [dates, setDates] = useState([]);

  useLayoutEffect(() => {

    const data = {
      search: searchValue,
      sort: sortBy,
      order: sortOrder,
      offset: searchValue ? 0 : offsetValue,
      limit: 20,
      startDate: startDate,
      endDate: endDate
    }
    dispatch(getUsersListInitiate(data, history))
  }, [])


  const columns = [{
    title: 'Sr. No.',
    dataIndex: 'srno',
    key: 'srno',
    render: (value, item, index) => <div style={{ width: '60px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{(index + 1 + offsetValue)}</div>,
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    sorter: true,
    render: (value, item, index) => <div style={{ width: '80px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.userId || 'N/A'}</div>,
  },
  {
    title: 'User Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, item, index) => <div style={{ width: '160px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.name ? item?.name : 'N/A'}</div>,
    sorter: true,
  },
  {
    title: 'Email Address',
    dataIndex: 'email',
    key: 'email',
    render: (value, item, index) => <div style={{ width: '250px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.email ? item?.email : 'N/A'}</div>,
    elipses: true,
    sorter: true,
  },
  {
    title: 'Games Played',
    dataIndex: 'gamesPlayed',
    key: 'gamesPlayed',
    render: (value, item, index) => <div style={{ width: '120px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.gamesPlayed ? item?.gamesPlayed : '0'}</div>,
    sorter: true,
  },
  {
    title: 'Currently Hosting',
    dataIndex: 'hosting',
    key: 'hosting',
    sorter: true,
    render: (value, item, index) => <div style={{ width: '135px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.hosting ? 'Yes' : 'No'}</div>,
  },
  {
    title: 'Action',
    dataIndex: 'Posted',
    key: 'Posted',
    render: (text, record) => {
      return (

        <div>

          <button
            type="submit"
            onClick={() => {
              if (!navigator.onLine) {
                toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
              } else {
                props.history.push("/userview", record)
              }
            }}
          >{appconstant.view}</button>

          <button
            type="submit"
            onClick={() => {
              if (!navigator.onLine) {
                toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
              } else {
                props.history.push("/useredit", record)
              }
            }}
          >{appconstant.edit}</button>

          {record.block ? (
            <button type="submit" onClick={() => {
              // showModalBlock({ userId: record._id, status: false })
              // setIsModalBlockData({ userId: record._id, status: false })
              const data = {
                userId: record._id,
                status: false
              }
              BlockModel(data)
            }} >{appconstant.unBlock}</button>
          ) : (
            <button type="submit" onClick={() => {
              // setIsModalBlockData({ userId: record._id, status: true })
              const data = {
                userId: record._id,
                status: true
              }
              BlockModel(data)
            }} >{appconstant.block}</button>
          )}
        </div>
      )
    }
  },
  ];


  const BlockModel = (data) => {
    return (
      Modal.confirm({
        title: data?.status ? 'Block' : "Unblock",
        content: data?.status ? `Are you sure, you want to block this user?` : 'Are you sure, you want to unblock this user?',
        okText: 'Yes',
        centered: true,
        cancelText: 'No',
        onOk() {
          if (!navigator.onLine) {
            toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
          } else {
            dispatch(blockUnblockUserInitiate(data, history))
          }
        },
        onCancel() {
        },
        className: "new-button"
      })
    )
  };

  const deleteModel = (record) => {

    Modal.confirm({
      title: "Delete",
      content: `Are you sure, you want to delete this user?`,
      okText: 'Yes',
      centered: true,
      cancelText: 'No',
      onOk() {

      },
      onCancel() {

      },
      className: "new-button"
    });
  };


  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const [isVisible, setVisible] = useState(false)
  const handlewClick = () => {
    setVisible(!isVisible)
  }
  useEffect(() => {
    document.title = 'Disparity Trap';
  }, [])


  const handlTableChange = (pagination, filter, sorter) => {
    console.log("this is event = ", sorter)
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      if (sorter.order == "ascend") {
        const data = {
          search: searchValue,
          sort: sorter.field,
          order: 1,
          offset: searchValue ? 0 : offsetValue,
          limit: 20,
          startDate: startDate,
          endDate: endDate
        }
        dispatch(updateUsersAction(1, 'sortOrder'))
        dispatch(updateUsersAction(sorter.field, 'sortBy'))
        dispatch(getUsersListInitiate(data, history))

      } //To fetch listing in descending order
      else if (sorter.order === "descend") {
        const data = {
          search: searchValue,
          sort: sorter.field,
          order: -1,
          offset: searchValue ? 0 : offsetValue,
          limit: 20,
          startDate: startDate,
          endDate: endDate
        }
        dispatch(updateUsersAction(-1, 'sortOrder'))
        dispatch(updateUsersAction(sorter.field, 'sortBy'))
        dispatch(getUsersListInitiate(data, history))
      } //To fetch listing in normal order
      else {
        const data = {
          search: searchValue,
          sort: null,
          order: null,
          offset: searchValue ? 0 : offsetValue,
          limit: 20,
          startDate: startDate,
          endDate: endDate
        }
        dispatch(updateUsersAction(null, 'sortOrder'))
        dispatch(updateUsersAction(null, 'sortBy'))
        dispatch(getUsersListInitiate(data, history))
      }
    }
  }

  const handleSearch = (e) => {
    let searchWord = e.target.value
    let isOnlyBlankSpace = false
    if (searchWord.charAt(0) === " ") {
      if (searchWord.length == 1 && searchWord == " ") {
        isOnlyBlankSpace = true
      }
      searchWord = searchWord.replace(/^ +/gm, '')
      setSearch(searchWord.replace(/^ +/gm))
      dispatch(updateUsersAction(searchWord, 'searchValue'))
    } else {
      dispatch(updateUsersAction(searchWord, 'searchValue'))
      setSearch(searchWord)
    }
    if (isOnlyBlankSpace === false) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: null,
        order: null,
        offset: searchWord.trim().length === 0 ? offsetValue : 0,
        limit: 20,
        startDate: startDate,
        endDate: endDate
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {

        dispatch(getUsersListInitiate(data, history))
      }
    } else if (searchWord !== search) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: "",
        order: null,
        offset: searchWord.trim().length == 0 ? offsetValue : 0,
        limit: 20,
        startDate: startDate,
        endDate: endDate
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {

        dispatch(getUsersListInitiate(data, history))
      }
    }
  }

  const daysCount = (dates) => {
    var given = moment(dates).add(1, 'months').startOf('day');
    var current = moment(dates);

    let res = moment.duration(given.diff(current)).days();
    return res
  }

  const disabledDate = current => {

    if (!dates || dates.length === 0) {
      return current && current < moment().subtract(2, 'years').endOf('day');
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > daysCount(dates[0]);
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > daysCount(dates[1]);
    return tooEarly || tooLate;
  };



  return (
    <div>
      <Layout>
        <Sidebar />
        <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
        <Layout className="new_layout">
          <HeaderAdmin handlewClick={handlewClick} {...props} />
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>
              {appconstant.UserManagement}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Content style={{ margin: '24px 16px 0' }}>
            <div className="top_button_item d-flex justify-content-between">
              <div className="button_left">
                <button
                  type="submit"
                  className="button-list top_new"
                  onClick={() => {
                    if (!navigator.onLine) {
                      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                    } else {
                      dispatch(exportUserListInitiate('users_data', history))
                    }
                  }}
                >
                  {appconstant.export}
                </button>
                <Link to="/adduser"><button type="submit" className="button-list top_new">{appconstant.AddNewUser}</button>
                </Link>
              </div>
              <Space direction="vertical" size={12}>
                <RangePicker
                  disabledDate={disabledDate}
                  onCalendarChange={val => {
                    setDates(val)
                  }}
                  defaultValue={startDate && [moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                  onChange={(e, v) => {
                    console.log("this is picker = ", e, v)
                    let startDate = ''
                    let endDate = ''
                    if (e && e.length > 0) {
                      startDate = `${moment(e[0]).format('YYYY-MM-DD')}T00:00:00`
                      endDate = `${moment(e[1]).format('YYYY-MM-DD')}T23:59:59`

                      dispatch(updateUsersAction(startDate, 'startDate'))
                      dispatch(updateUsersAction(endDate, 'endDate'))
                      const data = {
                        search: searchValue,
                        sort: sortBy,
                        order: sortOrder,
                        offset: searchValue ? 0 : offsetValue,
                        limit: 20,
                        startDate: startDate,
                        endDate: endDate
                      }
                      if (!navigator.onLine) {
                        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                      } else {
                        dispatch(getUsersListInitiate(data, history))
                      }
                    } else {
                      dispatch(updateUsersAction(null, 'startDate'))
                      dispatch(updateUsersAction(null, 'endDate'))
                      const data = {
                        search: searchValue,
                        sort: sortBy,
                        order: sortOrder,
                        offset: searchValue ? 0 : offsetValue,
                        limit: 20,
                        startDate: null,
                        endDate: null
                      }
                      if (!navigator.onLine) {
                        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                      } else {
                        dispatch(getUsersListInitiate(data, history))
                      }
                    }
                  }}
                />
              </Space>
            </div>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <div className="content-e">
                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 class="text-white text-capitalize ps-3">{appconstant.UserManagement}</h6>
                  <input
                    type="text"
                    className="search-bar"
                    placeholder="Search"
                    value={searchValue}
                    autoComplete="off"
                    name="search"
                    onChange={(e) => {
                      handleSearch(e)
                    }}
                  />
                </div>
                <Table
                  dataSource={usersList}
                  columns={columns}
                  showSorterTooltip={false}
                  loading={userListLoader}
                  pagination={false}
                  onChange={handlTableChange}
                />
                <Pagination
                  pageSize="20"
                  total={usersListPagination?.totalCount}
                  onChange={(page) => {
                    if (!navigator.onLine) {
                      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                    } else {
                      let data = (page - 1) * 20;
                      const dta = {
                        search: searchValue,
                        sort: sortBy,
                        order: sortOrder,
                        offset: data,
                        limit: 20,
                        startDate: startDate,
                        endDate: endDate
                      }
                      dispatch(updateUsersAction(data, 'offsetValue'))
                      dispatch(getUsersListInitiate(dta, history))
                    }
                  }}
                  current={usersListPagination?.currentPage}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}
export default UserManagement
