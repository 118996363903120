import * as types from '../ActionTypes';
import { usersList } from '../Api';

const initialState = {
  contactUsListLoader: false,
  contactUsListSuccess: false,
  contactUsList: [],
  startDate: null,
  endDate: null,
  contactUsListPagination: {},
  contactUsDetailLoader: false,
  contactUsDetailSuccess: false,
  contactUsDetail: {},
  deleteContactUsLoader: false,
  deleteContactUsSuccess: false,

  ////
  contactLoad: false,
  contactListArr: [],
  contactPagination: null,
  offsetValue: 0,
  searchValue: '',
  sortBy: null,
  sortOrder: null,
}

export const contactUs = (state = initialState, action) => {
  switch (action.type) {
    // case types.GET_CONTACT_US_LIST_INITIATE:
    //   return {
    //     ...state,
    //     contactUsListLoader: true,
    //     contactUsListSuccess: false
    //   }

    // case types.GET_CONTACT_US_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     contactUsListLoader: false,
    //     contactUsListSuccess: true,
    //     contactUsList: payload.list,
    //     contactUsListPagination: payload.pagination
    //   }

    // case types.GET_CONTACT_US_LIST_FAILURE:
    //   return {
    //     ...state,
    //     contactUsListLoader: false,
    //   }

    // case types.GET_CONTACT_US_DETAILS_INITIATE:
    //   return {
    //     ...state,
    //     contactUsDetailLoader: true,
    //     contactUsDetailSuccess: false
    //   }

    // case types.GET_CONTACT_US_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     contactUsDetailLoader: false,
    //     contactUsDetailSuccess: true,
    //     exportGamesList: payload
    //   }

    // case types.GET_CONTACT_US_DETAILS_FAILURE:
    //   return {
    //     ...state,
    //     contactUsDetailLoader: false,
    //   }

    // case types.DELETE_CONTACT_US_DETAIL_INITIATE:
    //   return {
    //     ...state,
    //     deleteContactUsLoader: true,
    //     deleteContactUsSuccess: false
    //   }

    // case types.DELETE_CONTACT_US_DETAIL_SUCCESS:
    //   return {
    //     ...state,
    //     deleteContactUsLoader: false,
    //     deleteContactUsSuccess: true
    //   }

    // case types.DELETE_CONTACT_US_DETAIL_FAILURE:
    //   return {
    //     ...state,
    //     deleteContactUsLoader: false,
    //   }



    // case types.CLEAR_CONTACT_US_DATA:
    //   return {
    //     ...state,
    //     contactUsListSuccess: false,
    //     contactUsDetailSuccess: false,
    //     deleteContactUsSuccess: false
    //   }

    //// Add Contact
    case types.ADD_CONTACT_LOAD:
      return {
        ...state,
        contactLoad: true
      }

    case types.ADD_CONTACT_SUCCESS:
      return {
        ...state,
        contactLoad: false
      }

    case types.ADD_CONTACT_FAIL:
      return {
        ...state,
        contactLoad: false
      }


    //List Contact
    case types.GET_CONTACT_LIST_LOAD:
      return {
        ...state,
        contactLoad: true
      }

    case types.GET_CONTACT_LIST_SUCCESS:
      console.log('GET_CONTACT_LIST_SUCCESS', action)
      return {
        ...state,
        contactLoad: false,
        contactListArr: action.result.list,
        contactPagination: action.result.pagination
      }

    case types.GET_CONTACT_LIST_FAIL:
      return {
        ...state,
        contactLoad: false
      }

    //// Delete Contact
    case types.DELETE_CONTACT_LOAD:
      return {
        ...state,
        contactLoad: true
      }

    case types.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contactLoad: false
      }

    case types.DELETE_CONTACT_FAIL:
      return {
        ...state,
        contactLoad: false
      }

    // Update ContactUs Data
    case types.UPDATE_CONTACT_US_STATES:
      state[action.key] = action.data
      return { ...state, };

    default:
      return state
  }
}

