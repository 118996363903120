export const API_LOGIN_START = 'API_LOGIN_START'
export const API_LOGIN_LOAD = 'API_LOGIN_LOAD'
export const API_LOGIN_SUCCESS = 'API_LOGIN_SUCCESS'
export const API_LOGIN_ERROR = 'API_LOGIN_ERROR'

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START'
export const FORGOT_PASSWORD_LOAD = 'FORGOT_PASSWORD_LOAD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'

export const API_RESET_PASSWORD_START = 'FORGOT_PASSWORD_START'
export const API_RESET_PASSWORD_LOAD = 'API_RESET_PASSWORD_LOAD'
export const API_RESET_PASSWORD_SUCCESS = 'API_RESET_PASSWORD_SUCCESS'
export const API_RESET_PASSWORD_ERROR = 'API_RESET_PASSWORD_ERROR'

export const DASHBOARD_DATA_START = 'DASHBOARD_DATA_START'
export const DASHBOARD_DATA_LOAD = 'DASHBOARD_DATA_LOAD'
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS'
export const DASHBOARD_DATA_ERROR = 'DASHBOARD_DATA_ERROR'

export const CHANGE_PASSWORD_INITIATE = 'CHANGE_PASSWORD_INITIATE'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const API_AUTHENTICATION_FAILED = 'API_AUTHENTICATION_FAILED'

//Check Reset Password
export const CHECK_RESET_LIST_LOAD = 'CHECK_RESET_LIST_LOAD';
export const CHECK_RESET_LIST_SUCCESS = 'CHECK_RESET_LIST_SUCCESS';
export const CHECK_RESET_LIST_ERROR = 'CHECK_RESET_LIST_ERROR';


export const API_LOGOUT_LOAD = 'API_LOGOUT_LOAD'
export const API_LOGOUT_SUCCESS = 'API_LOGOUT_SUCCESS'
export const API_LOGOUT_ERROR = 'API_LOGOUT_ERROR'

//Dashboard
export const API_DASHBOARD_LOAD = 'API_DASHBOARD_LOAD'
export const API_DASHBOARD_SUCCESS = 'API_DASHBOARD_SUCCESS'
export const API_DASHBOARD_ERROR = 'API_DASHBOARD_ERROR'

// User Management
export const GET_USERS_LIST_INITIATE = 'GET_USERS_LIST_INITIATE';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';

export const GET_USER_DETAIL_INITIATE = 'GET_USER_DETAIL_INITIATE';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAILURE = 'GET_USER_DETAIL_FAILURE';

export const EDIT_USER_DETAIL_INITIATE = 'EDIT_USER_DETAIL_INITIATE';
export const EDIT_USER_DETAIL_SUCCESS = 'EDIT_USER_DETAIL_SUCCESS';
export const EDIT_USER_DETAIL_FAILURE = 'EDIT_USER_DETAIL_FAILURE';

export const ADD_USER_DETAIL_INITIATE = 'ADD_USER_DETAIL_INITIATE';
export const ADD_USER_DETAIL_SUCCESS = 'ADD_USER_DETAIL_SUCCESS';
export const ADD_USER_DETAIL_FAILURE = 'ADD_USER_DETAIL_FAILURE';

export const BLOCK_UNBLOCK_USER_INITIATE = 'BLOCK_UNBLOCK_USER_INITIATE';
export const BLOCK_UNBLOCK_USER_SUCCESS = 'BLOCK_UNBLOCK_USER_SUCCESS';
export const BLOCK_UNBLOCK_USER_FAILURE = 'BLOCK_UNBLOCK_USER_FAILURE';

export const EXPORT_VOUCHER_LIST_INITIATE = 'EXPORT_VOUCHER_LIST_INITIATE';
export const EXPORT_VOUCHER_LIST_SUCCESS = 'EXPORT_VOUCHER_LIST_SUCCESS';
export const EXPORT_VOUCHER_LIST_FAILURE = 'EXPORT_VOUCHER_LIST_FAILURE';

export const GET_USER_GAME_ORGANIZE_LIST_INITIATE = 'GET_USER_GAME_ORGANIZE_LIST_INITIATE';
export const GET_USER_GAME_ORGANIZE_LIST_SUCCESS = 'GET_USER_GAME_ORGANIZE_LIST_SUCCESS';
export const GET_USER_GAME_ORGANIZE_LIST_FAILURE = 'GET_USER_GAME_ORGANIZE_LIST_FAILURE';

export const GET_USER_GAME_PLAYED_LIST_INITIATE = 'GET_USER_GAME_PLAYED_LIST_INITIATE';
export const GET_USER_GAME_PLAYED_LIST_SUCCESS = 'GET_USER_GAME_PLAYED_LIST_SUCCESS';
export const GET_USER_GAME_PLAYED_LIST_FAILURE = 'GET_USER_GAME_PLAYED_LIST_FAILURE';

export const UPDATE_USERS_STATES = 'UPDATE_USERS_STATES';

export const CLEAR_USERS_DATA = "CLEAR_USERS_DATA";

// Voucher Management
export const GET_VOUCHERS_LIST_INITIATE = 'GET_VOUCHERS_LIST_INITIATE';
export const GET_VOUCHERS_LIST_SUCCESS = 'GET_VOUCHERS_LIST_SUCCESS';
export const GET_VOUCHERS_LIST_FAILURE = 'GET_VOUCHERS_LIST_FAILURE';

export const ADD_VOUCHER_INITIATE = 'ADD_VOUCHER_INITIATE';
export const ADD_VOUCHER_SUCCESS = 'ADD_VOUCHER_SUCCESS';
export const ADD_VOUCHER_FAILURE = 'ADD_VOUCHER_FAILURE';

export const UPLOAD_CSV_INITIATE = 'UPLOAD_CSV_INITIATE';
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';
export const UPLOAD_CSV_FAILURE = 'UPLOAD_CSV_FAILURE';

export const UPDATE_VOUCHERS_STATES = 'UPDATE_VOUCHERS_STATES';

export const CLEAR_VOUCHERS_DATA = "CLEAR_VOUCHERS_DATA";

export const EXPORT_USER_LIST_INITIATE = 'EXPORT_USER_LIST_INITIATE';
export const EXPORT_USER_LIST_SUCCESS = 'EXPORT_USER_LIST_SUCCESS';
export const EXPORT_USER_LIST_FAILURE = 'EXPORT_USER_LIST_FAILURE';


// Game Management
export const GET_GAMES_LIST_INITIATE = 'GET_GAMES_LIST_INITIATE';
export const GET_GAMES_LIST_SUCCESS = 'GET_GAMES_LIST_SUCCESS';
export const GET_GAMES_LIST_FAILURE = 'GET_GAMES_LIST_FAILURE';

export const EXPORT_GAMES_LIST_INITIATE = 'EXPORT_GAMES_LIST_INITIATE';
export const EXPORT_GAMES_LIST_SUCCESS = 'EXPORT_GAMES_LIST_SUCCESS';
export const EXPORT_GAMES_LIST_FAILURE = 'EXPORT_GAMES_LIST_FAILURE';

export const GET_PRE_LISTED_LIST_INITIATE = 'GET_PRE_LISTED_LIST_INITIATE';
export const GET_PRE_LISTED_LIST_SUCCESS = 'GET_PRE_LISTED_LIST_SUCCESS';
export const GET_PRE_LISTED_LIST_FAILURE = 'GET_PRE_LISTED_LIST_FAILURE';

export const ADD_PRE_LISTED_INITIATE = 'ADD_PRE_LISTED_INITIATE';
export const ADD_PRE_LISTED_SUCCESS = 'ADD_PRE_LISTED_SUCCESS';
export const ADD_PRE_LISTED_FAILURE = 'ADD_PRE_LISTED_FAILURE';

export const EDIT_PRE_LISTED_INITIATE = 'EDIT_PRE_LISTED_INITIATE';
export const EDIT_PRE_LISTED_SUCCESS = 'EDIT_PRE_LISTED_SUCCESS';
export const EDIT_PRE_LISTED_FAILURE = 'EDIT_PRE_LISTED_FAILURE';

export const DELETE_PRE_LISTED_INITIATE = 'DELETE_PRE_LISTED_INITIATE';
export const DELETE_PRE_LISTED_SUCCESS = 'DELETE_PRE_LISTED_SUCCESS';
export const DELETE_PRE_LISTED_FAILURE = 'DELETE_PRE_LISTED_FAILURE';

export const DELETE_GAMES_INITIATE = 'DELETE_GAMES_INITIATE';
export const DELETE_GAMES_SUCCESS = 'DELETE_GAMES_SUCCESS';
export const DELETE_GAMES_FAILURE = 'DELETE_GAMES_FAILURE';

export const DELETE_VOUCHER_INITIATE = 'DELETE_VOUCHER_INITIATE';
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHER_SUCCESS';
export const DELETE_VOUCHER_FAILURE = 'DELETE_VOUCHER_FAILURE';

export const UPDATE_GAMES_STATES = 'UPDATE_GAMES_STATES';
export const UPDATE_PRELISTED_STATES = 'UPDATE_PRELISTED_STATES';

export const CLEAR_GAMES_DATA = "CLEAR_GAMES_DATA";

// Contact Us
export const GET_CONTACT_US_LIST_INITIATE = 'GET_CONTACT_US_LIST_INITIATE';
export const GET_CONTACT_US_LIST_SUCCESS = 'GET_CONTACT_US_LIST_SUCCESS';
export const GET_CONTACT_US_LIST_FAILURE = 'GET_CONTACT_US_LIST_FAILURE';

export const GET_CONTACT_US_DETAILS_INITIATE = 'GET_CONTACT_US_DETAILS_INITIATE';
export const GET_CONTACT_US_DETAILS_SUCCESS = 'GET_CONTACT_US_DETAILS_SUCCESS';
export const GET_CONTACT_US_DETAILS_FAILURE = 'GET_CONTACT_US_DETAILS_FAILURE';

export const DELETE_CONTACT_US_DETAIL_INITIATE = 'DELETE_CONTACT_US_DETAIL_INITIATE';
export const DELETE_CONTACT_US_DETAIL_SUCCESS = 'DELETE_CONTACT_US_DETAIL_SUCCESS';
export const DELETE_CONTACT_US_DETAIL_FAILURE = 'DELETE_CONTACT_US_DETAIL_FAILURE';

export const UPDATE_CONTACT_US_STATES = 'UPDATE_CONTACT_US_STATES';

export const CLEAR_CONTACT_US_DATA = "CLEAR_CONTACT_US_DATA";

// Game Settings
export const GET_FAQ_LIST_INITIATE = 'GET_FAQ_LIST_INITIATE';
export const GET_FAQ_LIST_SUCCESS = 'GET_FAQ_LIST_SUCCESS';
export const GET_FAQ_LIST_FAILURE = 'GET_FAQ_LIST_FAILURE';

export const GET_FAQ_DETAILS_INITIATE = 'GET_FAQ_DETAILS_INITIATE';
export const GET_FAQ_DETAILS_SUCCESS = 'GET_FAQ_DETAILS_SUCCESS';
export const GET_FAQ_DETAILS_FAILURE = 'GET_FAQ_DETAILS_FAILURE';

export const ADD_FAQ_INITIATE = 'ADD_FAQ_INITIATE';
export const ADD_FAQ_SUCCESS = 'ADD_FAQ_SUCCESS';
export const ADD_FAQ_FAILURE = 'ADD_FAQ_FAILURE';

export const DELETE_FAQ_DETAIL_INITIATE = 'DELETE_FAQ_DETAIL_INITIATE';
export const DELETE_FAQ_DETAIL_SUCCESS = 'DELETE_FAQ_DETAIL_SUCCESS';
export const DELETE_FAQ_DETAIL_FAILURE = 'DELETE_FAQ_DETAIL_FAILURE';

export const EDIT_FAQ_DETAIL_INITIATE = 'EDIT_FAQ_DETAIL_INITIATE';
export const EDIT_FAQ_DETAIL_SUCCESS = 'EDIT_FAQ_DETAIL_SUCCESS';
export const EDIT_FAQ_DETAIL_FAILURE = 'EDIT_FAQ_DETAIL_FAILURE';

export const GET_CONTACT_DETAILS_INITIATE = 'GET_CONTACT_DETAILS_INITIATE';
export const GET_CONTACT_DETAILS_SUCCESS = 'GET_CONTACT_DETAILS_SUCCESS';
export const GET_CONTACT_DETAILS_FAILURE = 'GET_CONTACT_DETAILS_FAILURE';

export const EDIT_CONTACT_DETAIL_INITIATE = 'EDIT_CONTACT_DETAIL_INITIATE';
export const EDIT_CONTACT_DETAIL_SUCCESS = 'EDIT_CONTACT_DETAIL_SUCCESS';
export const EDIT_CONTACT_DETAIL_FAILURE = 'EDIT_CONTACT_DETAIL_FAILURE';

export const GET_ABOUT_DETAILS_INITIATE = 'GET_ABOUT_DETAILS_INITIATE';
export const GET_ABOUT_DETAILS_SUCCESS = 'GET_ABOUT_DETAILS_SUCCESS';
export const GET_ABOUT_DETAILS_FAILURE = 'GET_ABOUT_DETAILS_FAILURE';

export const EDIT_ABOUT_DETAIL_INITIATE = 'EDIT_ABOUT_DETAIL_INITIATE';
export const EDIT_ABOUT_DETAIL_SUCCESS = 'EDIT_ABOUT_DETAIL_SUCCESS';
export const EDIT_ABOUT_DETAIL_FAILURE = 'EDIT_ABOUT_DETAIL_FAILURE';

export const GET_PRIVACY_DETAILS_INITIATE = 'GET_PRIVACY_DETAILS_INITIATE';
export const GET_PRIVACY_DETAILS_SUCCESS = 'GET_PRIVACY_DETAILS_SUCCESS';
export const GET_PRIVACY_DETAILS_FAILURE = 'GET_PRIVACY_DETAILS_FAILURE';

export const GET_TERMS_INITIATE = 'GET_TERMS_INITIATE';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAILURE = 'GET_TERMS_FAILURE';

export const EDIT_PRIVACY_DETAIL_INITIATE = 'EDIT_PRIVACY_DETAIL_INITIATE';
export const EDIT_PRIVACY_DETAIL_SUCCESS = 'EDIT_PRIVACY_DETAIL_SUCCESS';
export const EDIT_PRIVACY_DETAIL_FAILURE = 'EDIT_PRIVACY_DETAIL_FAILURE';

export const EDIT_TERMS_INITIATE = 'EDIT_TERMS_INITIATE';
export const EDIT_TERMS_SUCCESS = 'EDIT_TERMS_SUCCESS';
export const EDIT_TERMS_FAILURE = 'EDIT_TERMS_FAILURE';

export const UPDATE_GAME_SETTINGS_STATES = 'UPDATE_GAME_SETTINGS_STATES';

export const CLEAR_GAME_SETTINGS_DATA = "CLEAR_GAME_SETTINGS_DATA";


export const ADD_CONTACT_LOAD = 'ADD_CONTACT_LOAD';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAIL = 'ADD_CONTACT_FAIL';

export const GET_CONTACT_LIST_LOAD = 'GET_CONTACT_LIST_LOAD';
export const GET_CONTACT_LIST_SUCCESS = 'GET_CONTACT_LIST_SUCCESS';
export const GET_CONTACT_LIST_FAIL = 'GET_CONTACT_LIST_FAIL';

export const DELETE_CONTACT_LOAD = 'DELETE_CONTACT_LOAD';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';

// Notification
export const GET_NOTIFICATIONS_LIST_INITIATE = 'GET_NOTIFICATIONS_LIST_INITIATE';
export const GET_NOTIFICATIONS_LIST_SUCCESS = 'GET_NOTIFICATIONS_LIST_SUCCESS';
export const GET_NOTIFICATIONS_LIST_FAILURE = 'GET_NOTIFICATIONS_LIST_FAILURE';

export const UPDATE_NOTIFICATION_STATES = 'UPDATE_NOTIFICATION_STATES';

export const CLEAR_NOTIFICATION_DATA = "CLEAR_NOTIFICATION_DATA";
