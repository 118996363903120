import React, { useState, useEffect, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Layout, Pagination } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  Link,
  useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { Table, Breadcrumb, Modal } from 'antd';
import appconstant from "../../themes/appconstant";
import { getVouchersListInitiate, updateVouchersAction, addVoucherInitiate, uploadCSVAction, deleteVoucherListInitiate } from "../../redux/Action/vouchers"
import { getMemoizedVouchersData } from "../../redux/selectors/vouchers"
import { removeEmojis } from "../../common/utils"
import Csvfile from '../../assets/sample.csv'
import { exportVoucherList } from "../../redux/Action/users";

const { Content } = Layout;

const VoucherCodeManagement = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()

  const vouchersData = useSelector(getMemoizedVouchersData)
  const {
    VouchersList,
    vouchersListPagination,
    offsetValue,
    searchValue,
    sortBy,
    sortOrder,
    addVoucherLoader,
    addVoucherSuccess
  } = vouchersData

  const [isVisible, setVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCSVModalVisible, setIsCSVModalVisible] = useState(false);
  const [search, setSearch] = useState('')
  const [addEmail, setAddEmail] = useState('')
  const [csv, setCSV] = useState(null)
  const [csvName, setCSVName] = useState('')
  const [errorAddEmail, setErrorAddEmail] = useState('')
  const [errorCSV, setErrorCSV] = useState('')
  const [page, setPaginationPage] = useState(0)
  const file = React.useRef()
  console.log(csv);
  useLayoutEffect(() => {

    const data = {
      search: searchValue,
      sort: sortBy,
      order: sortOrder,
      limit: 20,
      offset: searchValue ? 0 : offsetValue
    }
    dispatch(getVouchersListInitiate(data, history))
  }, [])

  useEffect(() => {
    document.title = 'Disparity Trap';
    if (addVoucherSuccess) {
      handleCancel()
      const data = {
        search: searchValue,
        sort: sortBy,
        order: sortOrder,
        limit: 20,
        offset: searchValue ? 0 : offsetValue
      }
      dispatch(getVouchersListInitiate(data, history))
    }
  }, [addVoucherSuccess])

  const columns = [{
    title: 'Sr. No.',
    dataIndex: 'srno',
    key: 'srno',
    render: (value, item, index) => (searchValue ? index + 1 + (vouchersListPagination?.currentPage - 1) * 20 : index + 1 + offsetValue)
  },
  {
    title: 'Email Address',
    dataIndex: 'email',
    key: 'email',
    render: (value, item, index) => <div style={{ width: '150px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.email ? item?.email : 'N/A'}</div>,
    sorter: true,
  },
  {
    title: 'Created Date/Time',
    dataIndex: 'createdOn',
    key: 'createdOn',
    render: (value, item, index) => item?.createdOn ? moment(item?.createdOn).format('DD/MM/YYYY hh:mm A') : 'N/A',
    sorter: true,
  },
  {
    title: 'Voucher Code',
    dataIndex: 'code',
    key: 'code',
    sorter: true,
  },
  {
    title: 'Mail Status',
    dataIndex: 'isMailSent',
    key: 'isMailSent',
    sorter: true,
    render: (value, item, index) => item?.isMailSent,
  },
  {
    title: 'Action',
    // dataIndex: 'Posted',
    // key: 'Posted',
    render: (text, record) => {
      return (
        <div>
          <button type="submit" onClick={() => deleteModel(record)} >{appconstant.delete}</button>
        </div>
      )
    }
  },
  ];

  const deleteModel = (record) => {
    console.log(record, "record****")
    Modal.confirm({
      title: "Delete voucher code",
      content: `Are you sure, you want to delete this voucher code?`,
      okText: 'Yes',
      centered: true,
      cancelText: 'No',
      onOk() {
        dispatch(deleteVoucherListInitiate(record._id, history))
      },
      onCancel() {

      },
      className: "new-button"
    });
  };
  const handlewClick = () => {
    setVisible(!isVisible)
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (!addEmail && !errorAddEmail) {
      setErrorAddEmail('Please enter email address.')
      console.log("yes")
    } else if (addEmail && !errorAddEmail) {
      dispatch(addVoucherInitiate({ email: addEmail }, history))
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAddEmail('')
    setErrorAddEmail('')
  };
  const handleCSVCancel = () => {
    setIsCSVModalVisible(false);
    setCSV(null)
    setCSVName('')
    setErrorCSV('')
  };

  const handleUpload = () => {
    if (!csv) {
      setErrorCSV('Please select a CSV file.')
    } else if (csv.type.match('text/csv')) {
      if (navigator.onLine) {
        let body = new FormData()
        body.append('file', csv)
        dispatch(uploadCSVAction(body, history))
        handleCSVCancel()
      }
      else {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      }
    }
    else {
      setErrorCSV('Please select a CSV file.')
    }
  };

  const handleAddVoucherInputChange = (data) => {
    let value = data.target.value
    let reg = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/
    if (value) {
      if (value.match(reg)) {
        setErrorAddEmail('')
        setAddEmail(removeEmojis(value))
      } else {
        setAddEmail(removeEmojis(value))
        setErrorAddEmail('Please enter valid email address.')
      }
    } else {
      setAddEmail(value)
      setErrorAddEmail('Please enter email address.')
    }

  }

  const vouchercode = () => {
    return (
      <Modal title={appconstant.addnewvoucher}
        visible={isModalVisible}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Add'}
        confirmLoading={addVoucherLoader}
      >
        <div className="form-group" style={{ marginBottom: '0px' }}>
          <label>{appconstant.EmailAddress}</label>
          <input
            type="text"
            placeholder="Email Address"
            autoComplete="off"
            name="forgotValue"
            value={addEmail}
            className="form-control"
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              handleAddVoucherInputChange(e)
            }}
          />
          {errorAddEmail && <div style={{ color: 'red' }}>
            {errorAddEmail}
          </div>}
        </div>
      </Modal>
    )
  }

  const onFileSelect = (e) => {
    setCSV(e?.target?.files[0])
    setCSVName(e?.target?.files[0]?.name)
    setErrorCSV('')
  }
  const csvUpload = () => {
    return (
      <Modal title={appconstant.UploadCSV}
        visible={isCSVModalVisible}
        centered
        onOk={handleUpload}
        onCancel={handleCSVCancel}
        okText={'Upload'}
        confirmLoading={addVoucherLoader}
      >
        <div className="form-group" style={{ marginBottom: '0px' }}>
          <label>{"Select CSV"}</label>
          <input
            type="file"
            name='selectCSV'
            style={{ display: 'none' }}
            title=" "
            onChange={(e) => {
              onFileSelect(e)
              e.target.value = null
            }}
            accept=".csv"
            ref={file}
          />
          <input
            type="text"
            placeholder="Select CSV"
            autoComplete="off"
            name="forgotValue"
            value={csvName}
            className="form-control"
            onClick={() => file.current.click()}
            style={{ cursor: 'pointer', caretColor: 'transparent' }}
          />
          {errorCSV && <div style={{ color: 'red' }}>
            {errorCSV}
          </div>}
        </div>
      </Modal>
    )
  }

  const handlTableChange = (pagination, filter, sorter) => {
    console.log(pagination, "this is event = ", sorter)
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      if (sorter.order == "ascend") {
        const data = {
          search: searchValue,
          sort: sorter.field,
          order: 1,
          offset: searchValue ? 0 : offsetValue,
          limit: 20
        }
        dispatch(updateVouchersAction(1, 'sortOrder'))
        dispatch(updateVouchersAction(sorter.field, 'sortBy'))
        dispatch(getVouchersListInitiate(data, history))

      } //To fetch listing in descending order
      else if (sorter.order === "descend") {
        const data = {
          search: searchValue,
          sort: sorter.field,
          order: -1,
          offset: searchValue ? 0 : offsetValue,
          limit: 20
        }
        dispatch(updateVouchersAction(-1, 'sortOrder'))
        dispatch(updateVouchersAction(sorter.field, 'sortBy'))
        dispatch(getVouchersListInitiate(data, history))
      } //To fetch listing in normal order
      else {
        const data = {
          search: searchValue,
          sort: null,
          order: null,
          offset: searchValue ? 0 : offsetValue,
          limit: 20
        }
        dispatch(updateVouchersAction(null, 'sortOrder'))
        dispatch(updateVouchersAction(null, 'sortBy'))
        dispatch(getVouchersListInitiate(data, history))
      }
    }
  }

  const handleSearch = (e) => {
    let searchWord = e.target.value
    let isOnlyBlankSpace = false
    if (searchWord.charAt(0) === " ") {
      if (searchWord.length == 1 && searchWord == " ") {
        isOnlyBlankSpace = true
      }
      searchWord = searchWord.replace(/^ +/gm, '')
      setSearch(searchWord.replace(/^ +/gm))
      dispatch(updateVouchersAction(searchWord, 'searchValue'))
    } else {
      dispatch(updateVouchersAction(searchWord, 'searchValue'))
      setSearch(searchWord)
    }
    if (isOnlyBlankSpace === false) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: null,
        order: null,
        offset: searchWord.trim().length === 0 ? offsetValue : 0,
        limit: 20
      }
      if (!navigator.onLine) {
        // dispatch(showSuccessSnackbar({ type: "error", msg: 'Please check your internet connection.' }))
      } else {

        dispatch(getVouchersListInitiate(data, history))
      }
    } else if (searchWord !== search) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: "",
        order: null,
        offset: searchWord.trim().length == 0 ? offsetValue : 0,
        limit: 10
      }
      if (!navigator.onLine) {
        // dispatch(showSuccessSnackbar({ type: "error", msg: 'Please check your internet connection.' }))
      } else {

        dispatch(getVouchersListInitiate(data, history))
      }
    }
  }
  console.log("slajdlasd", offsetValue);
  return (

    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} {...props} />
        <Breadcrumb>
          <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
          <Breadcrumb.Item>
            {appconstant.voucheruserdetails}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="button_left top_button_item" style={{ position: 'relative', width: '100%' }}>
            <button type="submit" className="button-list top_new" onClick={showModal}>{appconstant.add} </button>

            <button
              type="submit"
              className="button-list top_new"
              onClick={() => {
                if (!navigator.onLine) {
                  toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                } else {
                  dispatch(exportVoucherList('voucher_data', history))
                }
              }}
            >
              {appconstant.export}
            </button>
            <button type="submit" className="button-list top_new" onClick={() => setIsCSVModalVisible(true)}>{appconstant.UploadCSV} </button>
            <div className="downloadSampleWrapper">
              <a href={Csvfile} download="sample.csv">
                <button type="submit" className="button-list top_new" >{appconstant.DownloadCSV} </button>
              </a>
            </div>
          </div>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

            <div className="content-e">
              <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                <h6 class="text-white text-capitalize ps-3"> {appconstant.voucheruserdetails}</h6>
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search"
                  value={searchValue}
                  autoComplete="off"
                  name="search"
                  onChange={(e) => {
                    if (!navigator.onLine) {
                      // dispatch(showSuccessSnackbar({ type: "error", msg: 'Please check your internet connection.' }))
                    } else {

                      handleSearch(e)
                    }
                  }}
                />
              </div>
              <Table
                dataSource={VouchersList}
                columns={columns}
                showSorterTooltip={false}
                className="vouchers_d"
                pagination={false}
                onChange={handlTableChange}
              />
              <Pagination
                pageSize="20"
                total={vouchersListPagination?.totalCount}
                onChange={(page) => {
                  setPaginationPage(page);
                  let data = (page - 1) * 20;
                  const dta = {
                    search: searchValue,
                    sort: sortBy,
                    order: sortOrder,
                    offset: data,
                    limit: 20,
                  }
                  dispatch(updateVouchersAction(data, 'offsetValue'))
                  dispatch(getVouchersListInitiate(dta, history))
                }}
                current={vouchersListPagination?.currentPage}
                showSizeChanger={false}
              />
            </div>
            {vouchercode()}
            {csvUpload()}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
export default VoucherCodeManagement
