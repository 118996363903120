import * as types from '../ActionTypes'

export const getFAQListInitiate = (data, history) => {
  const action = {
    type: types.GET_FAQ_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getFAQListSuccess = (data) => {
  const action = {
    type: types.GET_FAQ_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const getFAQListFailure = (error) => {
  const action = {
    type: types.GET_FAQ_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const getFAQDetailInitiate = (data, history) => {
  const action = {
    type: types.GET_FAQ_DETAILS_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getFAQDetailSuccess = (data) => {
  const action = {
    type: types.GET_FAQ_DETAILS_SUCCESS,
    payload: data,
  }
  return action
}

export const getFAQDetailFailure = (error) => {
  const action = {
    type: types.GET_FAQ_DETAILS_FAILURE,
    payload: error,
  }
  return action
}

export const addFAQInitiate = (data, history) => {
  const action = {
    type: types.ADD_FAQ_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const addFAQSuccess = (data) => {
  const action = {
    type: types.ADD_FAQ_SUCCESS,
    payload: data,
  }
  return action
}

export const addFAQFailure = (error) => {
  const action = {
    type: types.ADD_FAQ_FAILURE,
    payload: error,
  }
  return action
}

export const deleteFAQInitiate = (data, history) => {
  const action = {
    type: types.DELETE_FAQ_DETAIL_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const deleteFAQSuccess = (data) => {
  const action = {
    type: types.DELETE_FAQ_DETAIL_SUCCESS,
    payload: data,
  }
  return action
}

export const deleteFAQFailure = (error) => {
  const action = {
    type: types.DELETE_FAQ_DETAIL_FAILURE,
    payload: error,
  }
  return action
}

export const editFAQInitiate = (data, history) => {
  const action = {
    type: types.EDIT_FAQ_DETAIL_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const editFAQSuccess = (data) => {
  const action = {
    type: types.EDIT_FAQ_DETAIL_SUCCESS,
    payload: data,
  }
  return action
}

export const editFAQFailure = (error) => {
  const action = {
    type: types.EDIT_FAQ_DETAIL_FAILURE,
    payload: error,
  }
  return action
}

export const getContactDetailInitiate = (data, history) => {
  const action = {
    type: types.GET_CONTACT_DETAILS_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getContactDetailSuccess = (data) => {
  const action = {
    type: types.GET_CONTACT_DETAILS_SUCCESS,
    payload: data,
  }
  return action
}

export const getContactDetailFailure = (error) => {
  const action = {
    type: types.GET_CONTACT_DETAILS_FAILURE,
    payload: error,
  }
  return action
}

export const editContactInitiate = (data, history) => {
  const action = {
    type: types.EDIT_CONTACT_DETAIL_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const editContactSuccess = (data) => {
  const action = {
    type: types.EDIT_CONTACT_DETAIL_SUCCESS,
    payload: data,
  }
  return action
}

export const editContactFailure = (error) => {
  const action = {
    type: types.EDIT_CONTACT_DETAIL_FAILURE,
    payload: error,
  }
  return action
}

export const getAboutDetailInitiate = (data, history) => {
  const action = {
    type: types.GET_ABOUT_DETAILS_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getAboutDetailSuccess = (data) => {
  const action = {
    type: types.GET_ABOUT_DETAILS_SUCCESS,
    payload: data,
  }
  return action
}

export const getAboutDetailFailure = (error) => {
  const action = {
    type: types.GET_ABOUT_DETAILS_FAILURE,
    payload: error,
  }
  return action
}

export const editAboutInitiate = (data, history) => {
  const action = {
    type: types.EDIT_ABOUT_DETAIL_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const editAboutSuccess = (data) => {
  const action = {
    type: types.EDIT_ABOUT_DETAIL_SUCCESS,
    payload: data,
  }
  return action
}

export const editAboutFailure = (error) => {
  const action = {
    type: types.EDIT_ABOUT_DETAIL_FAILURE,
    payload: error,
  }
  return action
}

export const getPrivacyDetailInitiate = (data, history) => {
  const action = {
    type: types.GET_PRIVACY_DETAILS_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getPrivacyDetailSuccess = (data) => {
  const action = {
    type: types.GET_PRIVACY_DETAILS_SUCCESS,
    payload: data,
  }
  return action
}

export const getPrivacyDetailFailure = (error) => {
  const action = {
    type: types.GET_PRIVACY_DETAILS_FAILURE,
    payload: error,
  }
  return action
}
export const getTermsInitiate = (data, history) => {
  const action = {
    type: types.GET_TERMS_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getTermsSuccess = (data) => {
  const action = {
    type: types.GET_TERMS_SUCCESS,
    payload: data,
  }
  return action
}

export const getTermsFailure = (error) => {
  const action = {
    type: types.GET_TERMS_FAILURE,
    payload: error,
  }
  return action
}

export const editPrivacyInitiate = (data, history) => {
  const action = {
    type: types.EDIT_PRIVACY_DETAIL_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const editPrivacySuccess = (data) => {
  const action = {
    type: types.EDIT_PRIVACY_DETAIL_SUCCESS,
    payload: data,
  }
  return action
}

export const editPrivacyFailure = (error) => {
  const action = {
    type: types.EDIT_PRIVACY_DETAIL_FAILURE,
    payload: error,
  }
  return action
}

export const editTermsInitiate = (data, history) => {
  const action = {
    type: types.EDIT_TERMS_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const editTermsSuccess = (data) => {
  const action = {
    type: types.EDIT_TERMS_SUCCESS,
    payload: data,
  }
  return action
}

export const editTermsFailure = (error) => {
  const action = {
    type: types.EDIT_TERMS_FAILURE,
    payload: error,
  }
  return action
}

export const updateAction = (data, key) => ({
  type: types.UPDATE_GAME_SETTINGS_STATES,
  payload: data,
  key
})

export const clearGamesSettingsData = () => ({
  type: types.CLEAR_GAME_SETTINGS_DATA
})