import * as types from '../ActionTypes';
const INITIAL_STATE = {
    dashboardData: {},
    dashboardDataLoad: false,
};

export const DashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //Dashboard
        case types.API_DASHBOARD_LOAD:
            return {
                ...state,
                dashboardDataLoad: true,
            }
        case types.API_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboardDataLoad: false,
                dashboardData: action.result

            }
        case types.API_DASHBOARD_ERROR:
            return {
                ...state,
                dashboardDataLoad: false
            }

            
        default: return { ...state }
    }
};