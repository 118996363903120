import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Breadcrumb, Layout, Menu } from 'antd';
import moment from 'moment'
import { UploadOutlined, UserOutlined, VideoCameraOutlined, NotificationOutlined } from '@ant-design/icons';
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers, faBars } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { getMemoizedNotificationsData } from "../redux/selectors/notifications"
import { getNotificationsInitiate } from "../redux/Action/notifications"

const { Header, Content, Footer, Sider } = Layout;



const HeaderAdmin = (props) => {

  const dispatch = useDispatch()
  const notificationData = useSelector(getMemoizedNotificationsData)

  const {
    notificationList,
    notificationListLoader,
    offsetValue,
    searchValue,
    sortBy,
    sortOrder,
    totalCount
  } = notificationData

  useLayoutEffect(() => {
    const data = {
      search: searchValue,
      sort: sortBy,
      order: sortOrder,
      offset: searchValue ? 0 : offsetValue,
      limit: 20
    }
    dispatch(getNotificationsInitiate(data, props.history))


  }, [])


  const menu = (
    <Menu className="notification_menu">
      <div className="new_container">
        {!notificationListLoader && notificationList.length > 0 ?
          notificationList.map((item, index) => {
            return (
              <div className="notification_t">
                <h5>{item.descripition}</h5>
                <div className="d-flexnew_n">
                  <span>Pre-Listed Word - {item?.word?.word?.substring(0, 7) + (item?.word?.word.length > 7 ? '...' : '')}</span>
                  <span className="time_new">{moment(item?.createdOn).format('DD/MM/YYYY hh:mm A')}</span>

                </div>
              </div>
            )
          }) : (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
              There is no data available.
            </div>
          )
        }
      </div>
      <Menu.Item key="1"><a className="anchor_view_more" onClick={(e) => props.history.push('/notifications')}>View More</a></Menu.Item>
    </Menu>
  );
  return (
    <div>
      <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
        <div className="notification button">
          <Dropdown overlay={menu} trigger={['click']}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <FontAwesomeIcon icon={faBell} /> {`${totalCount} Notifications`}
            </a>
          </Dropdown>
        </div>
        <div className="iocns">
          <FontAwesomeIcon icon={faBars} className="lite-text" onClick={props.handlewClick} />
        </div>


      </Header>
    </div>

  )
}
export default HeaderAdmin;