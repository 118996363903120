import * as types from '../ActionTypes'

export const getContactUsListInitiate = (data, history) => {
  const action = {
    type: types.GET_CONTACT_US_LIST_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getContactUsListSuccess = (data) => {
  const action = {
    type: types.GET_CONTACT_US_LIST_SUCCESS,
    payload: data,
  }
  return action
}

export const getContactUsListFailure = (error) => {
  const action = {
    type: types.GET_CONTACT_US_LIST_FAILURE,
    payload: error,
  }
  return action
}

export const getContactUsDetailInitiate = (data, history) => {
  const action = {
    type: types.GET_CONTACT_US_DETAILS_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const getContactUsDetailSuccess = (data) => {
  const action = {
    type: types.GET_CONTACT_US_DETAILS_SUCCESS,
    payload: data,
  }
  return action
}

export const getContactUsDetailFailure = (error) => {
  const action = {
    type: types.GET_CONTACT_US_DETAILS_FAILURE,
    payload: error,
  }
  return action
}

export const deleteContactUsDetailInitiate = (data, history) => {
  const action = {
    type: types.DELETE_CONTACT_US_DETAIL_INITIATE,
    payload: data,
    history: history
  }
  return action
}

export const deleteContactUsDetailSuccess = (data) => {
  const action = {
    type: types.DELETE_CONTACT_US_DETAIL_SUCCESS,
    payload: data,
  }
  return action
}

export const deleteContactUsDetailFailure = (error) => {
  const action = {
    type: types.DELETE_CONTACT_US_DETAIL_FAILURE,
    payload: error,
  }
  return action
}



export const clearContactUsData = () => ({
  type: types.CLEAR_CONTACT_US_DATA
})



export const contactAddAction = (data, messageObj) => ({
  type: types.ADD_CONTACT_LOAD,
  data,
  messageObj
})


export const getContactList = (data, history) => {
  const action = {
    type: types.GET_CONTACT_LIST_LOAD,
    payload: data,
    history
  }
  return action
}

export const deleteContact = (contactId, data, userId) => {
  const action = {
    type: types.DELETE_CONTACT_LOAD,
    contactId,
    data,
    userId
  }
  return action
}

export const updateContactUsAction = (data, key) => {
  const action = {
    type: types.UPDATE_CONTACT_US_STATES,
    data,
    key
  }
  return action
}