import logo from './logo.svg';
import './App.css';
import '../src/css/style.css'
import '../src/css/responsive.css'
import React, { useState, useEffect } from 'react'
import {
  // MemoryRouter,
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import Routes from './Route/Routes';
import PrivateRoute from './Route/privateRoute'
import history from './Route/history';
import 'antd/dist/antd.css'
import { configureStore } from './redux/store';
import { Provider } from 'react-redux'
import { ToastContainer, Slide } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './components/dashboard';
import Login from './components/login';
import ForgotPassword from './components/forgotpassword';
import ResetPassword from './components/resetpassword';
// import firebaseApp, { fetchToken, onMessageListener } from './helper/firebase';



const store = configureStore();


function App() {

  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  // fetchToken(setTokenFound);


  // onMessageListener().then(payload => {
  //   console.log('payloadNotification', payload)
  //   setShow(true);
  //   setNotification({ title: payload.notification.title, body: payload.notification.body })
  //   console.log(payload);
  //   toast.success(`You have a notification \n${payload.notification.title}`, { position: "top-right" })
  // }).catch(err => console.log('failed: ', err));


  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  if (localStorage.token) {
                    return <Dashboard />
                  }
                  else {
                    return <Login />
                  }
                }
                }
              />
              <Route path="/login"
                render={() => (localStorage.token ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <Login />
                ))}
              />
              <Route path="/forgotpassword"
                render={() => (localStorage.token ? (
                  <>
                    <Redirect to="/dashboard" />
                  </>
                ) : (
                  <ForgotPassword />
                ))}
              />
              <Route path="/resetpassword"
                render={() => <ResetPassword />}
              />
              <PrivateRoute path="/" component={Routes} />
            </Switch>
          </Router>
        </Switch>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        transition={Slide}
      />
    </Provider>
  );
}

export default App;
