import { put, call } from 'redux-saga/effects';
import { message } from 'antd';
import {
    notificationList
} from '../Api'
import { toast } from 'react-toastify';
import * as types from '../ActionTypes';
import { isInternetConnected } from '../../common/checkInternet'
import {
    getNotificationsSuccess,
    getNotificationsFailure
} from "../Action/notifications"

function* loginAgain(action) {
    yield put({
        type: types.API_AUTHENTICATION_FAILED,
    });
    localStorage.clear();
    action.history.push("/login");
}

export function* getNotificationsListSaga(action) {
    console.log('new-item.........', action)
    // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
    // const token = localStorage.getItem('token')
    try {
        const response = yield call(notificationList, action.payload)
        let { data, message } = response.result;
        console.log('resultSaga.......,', response)
        if (response.status === 1) {
            // toast.success(message, { position: "top-right" })
            yield put(getNotificationsSuccess(data))
        }
        else {
            toast.error(message, { position: "top-right", toastId: 'custom_msg' })
            yield put(getNotificationsFailure(message))
        }
    }

    catch (err) {
        if (err.status === 4) {
            yield call(loginAgain, action);
            toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
            yield put(getNotificationsFailure(err))
        } else {
            toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
            yield put(getNotificationsFailure(err))
        }

    }
}