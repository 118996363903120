import { put, call } from 'redux-saga/effects';

import {
  gamesList,
  exportGamesList,
  preListedWordList,
  addPreListedWord,
  editPreListedWord,
  deletePreListedWord,
  deleteGame
} from '../Api'
import { toast } from 'react-toastify';
import * as types from '../ActionTypes';
import {
  getGamesListSuccess,
  getGamesListFailure,
  exportGamesListSuccess,
  exportGamesListFailure,
  getPreListedListSuccess,
  getPreListedListFailure,
  addPreListedListSuccess,
  addPreListedListFailure,
  editPreListedListSuccess,
  editPreListedListFailure,
  deletePreListedListSuccess,
  deletePreListedListFailure,
  deleteGamesListFailure,
  deleteGamesListSuccess
} from "../Action/games"

function* loginAgain(action) {
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  localStorage.clear();
  action.history.push("/login");
}

export function* getGamesListSaga(action) {
  console.log('new-item.........', action)
  // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
  // const token = localStorage.getItem('token')
  try {
    const response = yield call(gamesList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      // toast.success(message, { position: "top-right" })
      yield put(getGamesListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getGamesListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getGamesListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getGamesListFailure(err))
    }

  }
}

export function* exportGamesListSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(exportGamesList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      yield put(exportGamesListSuccess(data))
    }
    else {
      console.log("this is else")
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(exportGamesListFailure(message))
    }
  }

  catch (err) {
    console.log("this is catch")
    if (err.status === 4) {
      console.log("this is catch if")
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(exportGamesListFailure(err))
    } else {
      console.log("this is catch else = ", err)
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(exportGamesListFailure(err))
    }

  }
}

export function* getpreListedListSaga(action) {
  console.log('new-item.........', action)
  // yield put({ type: types.API_LIST_BOT_USERS_LOAD });
  // const token = localStorage.getItem('token')
  try {
    const response = yield call(preListedWordList, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status === 1) {
      // toast.success(message, { position: "top-right" })
      yield put(getPreListedListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getPreListedListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(getPreListedListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(getPreListedListFailure(err))
    }

  }
}

export function* preListedWordAddSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(addPreListedWord, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addPreListedListSuccess(data))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addPreListedListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(addPreListedListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(addPreListedListFailure(err))
    }

  }
}

export function* preListedWordEditSaga(action) {
  console.log('new-item.........', action)
  try {
    const response = yield call(editPreListedWord, action.payload)
    let { data, message } = response.result;
    console.log('resultSaga.......,', response)
    if (response.status == 1) {
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editPreListedListSuccess(data))
    }
    else {
      console.log('resultSaga.......,')
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editPreListedListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(editPreListedListFailure(err))
    } else {
      console.log('resultSaga.......,', err)
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(editPreListedListFailure(err))
    }

  }
}

export function* gameDeleteSaga(action) {
  console.log('delete in reducer****', action)
  try {
    const response = yield call(deleteGame, action.payload)
    let { data, message } = response.result;
    console.log('delete in reducer****', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteGamesListSuccess(action.payload))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deleteGamesListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(deletePreListedListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deletePreListedListFailure(err))
    }

  }
}

export function* preListedWordDeleteSaga(action) {
  console.log('delete in reducer****', action)
  try {
    const response = yield call(deletePreListedWord, action.payload)
    let { data, message } = response.result;
    console.log('delete in reducer****', response)
    if (response.status == 1) {
      // toast.success(message, { position: "top-right" })
      toast.success(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deletePreListedListSuccess(action.payload))
    }
    else {
      toast.error(message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deletePreListedListFailure(message))
    }
  }

  catch (err) {
    if (err.status === 4) {
      yield call(loginAgain, action);
      toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
      yield put(deletePreListedListFailure(err))
    } else {
      toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
      yield put(deletePreListedListFailure(err))
    }

  }
}