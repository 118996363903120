import React, { useState, useEffect } from "react"
import { Layout, Menu, Spin } from 'antd';
import { toast } from 'react-toastify';
import images from '../../themes/appImage'

import {
  BrowserRouter as Router,
  Link,
  useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";

import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from 'antd';
import appconstant from "../../themes/appconstant";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// import Loader from '../../helper/reactLoader'
import InputField from '../../customComponent/input'
import { userAddInitiate } from "../../redux/Action/users"

import { useDispatch, useSelector } from "react-redux";
import { getMemoizedUsersData } from "../../redux/selectors/users"


const { Header, Content, Footer, Sider } = Layout;

const AddUser = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const userAddData = useSelector(getMemoizedUsersData);
  const {
    userAddLoader,
    userAddSuccess,
    userAddDetail,
  } = userAddData

  const [isVisible, setVisible] = useState(false)
  const [profileImg, setProfileImg] = useState(null);
  const [title, setTitle] = useState("Click to upload image.");
  const [tooltip, setTooltip] = useState("Click to change image.");
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [imageUpload, setImageUpload] = useState("");
  const [imageError, setImageError] = useState(null)
  const handlewClick = () => {
    setVisible(!isVisible)
  }
  useEffect(() => {
    document.title = 'Disparity Trap';
  }, [])
  const imageChange = (e, setValue) => {
    if (e.target.files) {
      console.log('CheckinFile', e.target.files)
      let file = e.target.files[0]
      if (!file.name.match(/\.(jpg|png|jpeg|gif)$/)) {
        setImageError('Only .jpeg, .jpg, .png format images are allowed.')
        return false;
      }
      const extentType = ['jpeg', 'jpg', 'png'];
      const extent = file?.name?.split('.');
      const extentT = extent && extent[extent.length - 1];
      console.log('imageChange', extentType, extentType.indexOf(extentT) >= 0, extentType.indexOf(extentT))
      if (extentType.indexOf(extentT) >= 0) {
        if (file.size > 20971520) {
          setImageError('File size must be equal or less than 20 MB.')
        } else {
          setFile(URL.createObjectURL(file));
          setFileUrl(file)
          setValue('profile', file)
          setImageUpload("")
          setImageError(null)
        }
      }
      else {
        if (e.target.files.length == 0) {

        } else {

          setImageError('Only .jpeg, .jpg, .png format images are allowed.')
        }
      }
    }
  }
  const validationSchema = Yup.object().shape({
    profile: Yup.string(),
    email: Yup.string()
      // .email("Please enter valid email address.")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter valid email address."
      )
      .required("Please enter email address."),
    password: Yup.string().min(8, "Password should be at least 8 characters long.").required("Please enter password."),
    confirmPassword: Yup.string().required("Please enter confirm password.").oneOf([Yup.ref('password'), null], "password and confirm password should be same."),
    fname: Yup.string()
      .test('trim', 'Please enter username.', value => value?.trim()?.length > 0)
      .min(3, 'Username should be at least 3 characters long.')
      // .min(20, 'Username should be at least 3 characters long.', value => value?.trim()?.length > 0)
      .required("Please enter username."),
  });




  const handleFormSubmit = (values, { setSubmitting }) => {
    // setBtnDisabled(true)
    console.log("this is")
    let body = new FormData()
    if (file) {
      body.append('profileImage', fileUrl)
    }
    // body.append('userId', userDetail._id)
    body.append('name', values.fname)
    body.append('email', values.email)
    body.append('password', values.password)
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      dispatch(userAddInitiate(body, history))
    }

  }
  return (

    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} {...props} />
        <Breadcrumb>
          <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/usermanagement">{appconstant.UserManagement}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{appconstant.AddNewUser}</Breadcrumb.Item>
        </Breadcrumb>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="site-layout-background label_space" style={{ padding: 24, minHeight: 360 }}>
            <Spin className="page_loading" tip="Loading..." spinning={userAddLoader} size={"large"}>
              <div className="content-e">
                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                  <h6 class="text-white text-capitalize ps-3">{appconstant.AddNewUser}</h6>
                </div>
                <div className="wrapper_line">
                  <Formik
                    enableReinitialize
                    initialValues={{ profile: '', fname: '', password: '', email: '', confirmPassword: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      values,
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="upload-image">
                          <div className="upload-image">

                            <div
                              class="upload_img common-flex"

                            >
                              <input
                                type="file"
                                id="pic"
                                name="profile"
                                accept=".png,.jpg,.jpeg"
                                style={{ display: "none" }}
                                onChange={(e) => imageChange(e, setFieldValue)}
                              />


                              {<div className='image_wrapper_style' style={{ cursor: 'default' }}>
                                <label for="pic" title={file ? tooltip : title}>
                                  <img
                                    className="table-img"
                                    src={file ? file : images.dummy}
                                    type="file"
                                    onChange={(e) => imageChange(e, setFieldValue)}
                                    name="profile"
                                    height="100"
                                    width="100"
                                    style={{
                                      // padding: file ? '0px' : '0px .5px 1px 1px',
                                      cursor: 'pointer',
                                      borderRadius: '50%'
                                    }}
                                  />

                                  <img
                                    style={{ cursor: "pointer" }}
                                    className="plus_icon"
                                    src={images.plus}
                                    onClick={imageChange}
                                  ></img>
                                </label>
                              </div>}



                            </div>
                            <div
                              className="image-upload"
                              style={{
                                color: "red",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {imageUpload}
                              {/* {fileUrl} */}
                            </div>
                          </div>

                          {/* {(imageError || errors.profile) ? (
                              <div class='color-error' style={{ width: '100%', textAlign: "center" }}>{imageError ? imageError : errors.profile}</div>
                            ) : null} */}
                          <div
                            className="image-upload"
                            style={{
                              color: "red",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* {imageUpload} */}
                          </div>
                        </div>
                        {(imageError || errors.profile) ? (
                          <div class='color-error' style={{ width: '100%', textAlign: "center" }}>{imageError ? imageError : errors.profile}</div>
                        ) : null}

                        <div className="form-group">
                          <label>{appconstant.UserName}</label>
                          <InputField
                            onChangeText={handleChange('fname')}
                            onBlur={handleBlur('fname')}
                            value={values.fname}
                            touched={touched.fname}
                            error={errors.fname}
                            placeholder="User Name"
                            // type='noSpace'
                            fieldType='userName'
                            maxLength={40}
                          />
                        </div>
                        <div className="form-group">
                          <label>{appconstant.EmailAddress}</label>
                          <InputField
                            onChangeText={handleChange('email')}
                            onBlur={handleBlur('email')}
                            value={values.email}
                            touched={touched.email}
                            error={errors.email}
                            placeholder="Email Address"
                            fieldType="spaceRestrict"
                          />
                        </div>
                        <div className="form-group">
                          <label>{appconstant.Password}</label>
                          <InputField
                            onChangeText={
                              handleChange("password")
                            }
                            onBlur={handleBlur("password")}
                            touched={touched.password}
                            error={errors.password}
                            placeholder="Password"
                            type="password"
                            value={values.password}
                          />
                        </div>
                        <div className="form-group">
                          <label>{appconstant.ConfrmPassword}</label>
                          <InputField
                            onChangeText={
                              handleChange("confirmPassword")
                            }
                            onBlur={handleBlur("confirmPassword")}
                            touched={touched.confirmPassword}
                            error={errors.confirmPassword}
                            placeholder="Confirm password"
                            type="password"
                            value={values.confirmPassword}
                          />
                        </div>
                        <div className="text-center button">
                          <button type="submit" className="button-list">{appconstant.add}</button>
                        </div>
                        <div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </Spin>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
export default AddUser;
