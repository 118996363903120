import { put, call } from 'redux-saga/effects';
import * as types from '../ActionTypes';
import { dashboardApi } from '../Api'
import { toast } from 'react-toastify';
import { isInternetConnected } from '../../common/checkInternet'

function* loginAgain(action) {
    yield put({
        type: types.API_AUTHENTICATION_FAILED,
    });
    localStorage.clear();
    action.history.push("/login");
}

export function* DashboardSaga(action) {
    console.log('new-item.........', action)
    try {
        const response = yield call(dashboardApi, action.payload)
        let { data, message } = response.result;
        console.log('resultSaga.......,', response)
        if (response.status == 1) {
            // toast.success(message,{position: "top-right"})
            yield put({ type: types.API_DASHBOARD_SUCCESS, result: data })
        }
        else {
            yield put({ type: types.API_DASHBOARD_ERROR });

            if (message == 'Authorization token is required.') {
                toast.error('Your password has been changed, Please login again.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                localStorage.removeItem('token');
                action.history.push('/login');
            }

            if (message == 'Please login again, user not found.' || message == 'Your password has been changed, Please login again.' || message == 'Your account is login from another device.') {
                toast.dismiss()
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'custom_msg'
                });
                localStorage.removeItem('token');
                action.history.push('/login');
            } else {
                if (message !== 'Authorization token is required.') {
                    if (isInternetConnected()) {

                        toast.error(message, { position: "top-right" })
                    } else {
                        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
                    }
                }

            }
        }
    }

    catch (err) {
        if (err.status === 4) {
            yield call(loginAgain, action);
            toast.error(err.error, { position: "top-right", toastId: 'custom_msg' })
            yield put({ type: types.API_DASHBOARD_ERROR, error: err })
        } else {
            toast.error(err.message, { position: "top-right", toastId: 'custom_msg' })
            yield put({ type: types.API_DASHBOARD_ERROR, error: err })
        }

    }
}