const ValidationConstants = {
    valEmail: "Please enter valid email address.",
    reqEmail: "Please enter email.",
    reqName: "Please enter name.",
    reqPhone: "Please enter phone number",
    reqPlayerPosition: "Please enter player position",
    noSpace: "Space is not allowed.",
    space: "Space is not allowed.",
    reqPassword: "Please enter password.",
    resPassword: "Please enter new password.",
    oldPass: "Please enter old password.",
    newPass: "Please enter new password.",
    password: " Password should be at least 6 characters long.",
    confirmSpace: "Space is not allowed in confirm password.",
    match: "New password and confirm password should be same.",
    confirmPassword: "Please enter confirm password.",
    name2: "Name should be at least 2 characters long.",
    name30: "Name should be 30 characters long.",


    noBlankSpace: "Blank spaces not allowed in amount.",
    fileSize: "File size should not be more than 20 MB.",
    formats: "Only .jpg, .jpeg and .png format images are allowed.",

    valid_Number: "Please enter valid number.",
    upload_Image: "Please upload image.",
    change_Image: "Click to change image."
}

export default ValidationConstants;