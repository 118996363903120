import React, { useState, useEffect } from "react"
import { Layout, Menu } from 'antd';
import images from '../../themes/appImage'

import {
  BrowserRouter as Router,
  Link,
  useHistory

} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import InputField from '../../customComponent/input'
import { ForgotPasswords, checkResetPasswordAction, resetPasswordAction } from "../../redux/Action/Authentication";
import { isInternetConnected } from '../../common/checkInternet'


import { Formik } from 'formik';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ValidationConstants from '../../themes/ValidationConstants'
import appconstant from "../../themes/appconstant";


const { Header, Content, Footer, Sider } = Layout;

const ResetPassword = () => {
  const [isButtonDisabled, setButtonStatus] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [token, setToken] = useState('')

  const { checkResetLink, isLinkExpire, passWordChangedSuccessfully, verificationCheckLoader, checkResetLinkSuccess } = useSelector(state => state.LoginReducer)

  console.log('checkResetLink', checkResetLink, isLinkExpire)
  useEffect(() => {
    document.title = 'Disparity Trap';
    let tempToken = history?.location?.pathname.split('=')
    setToken(tempToken[1])
    // tempToken = token[1]
    console.log('historyCheck', token)
    let body = {
      token: tempToken[1]
    }
    isInternetConnected() && dispatch(checkResetPasswordAction(body))

  }, [])




  const validationSchema = Yup.object().shape({
    password: Yup.string().min(8, " New password should be at least 8 characters long.").required("Please enter new password."),
    confirmPassword: Yup.string().required("Please enter confirm password.").oneOf([Yup.ref('password'), null], "New password and confirm password should be same."),
  });





  return (
    <div className="image-bg">
      {console.log("this is true = ", checkResetLinkSuccess)}
      {!verificationCheckLoader && checkResetLinkSuccess ?
        (<div className="page-header">
          {console.log("tjos os", isLinkExpire)}
          {(!verificationCheckLoader && isLinkExpire && !passWordChangedSuccessfully) && <div className="line_true">
            <div className="content-e login_1">
              <div className={"wrapper_line"}>
                <div className="text-center cost">
                  <img src={images.logo} style={{ marginTop: '10px' }} />
                  <h6 class="text-white text-capitalize ps-3 login_2">{appconstant.resetpassword}</h6>
                </div>

                <Formik
                  enableReinitialize
                  initialValues={{ confirmPassword: '', password: "" }}
                  validationSchema={validationSchema}
                  onSubmit={values => {
                    setButtonStatus(true);
                    console.log('values', values)
                    let data = {
                      password: values.password,
                      token: token
                    }
                    isInternetConnected(history) && dispatch(resetPasswordAction(data, history));

                    setTimeout(() => {
                      setButtonStatus(false);
                    }, 1900)
                  }}
                >

                  {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                  }) => (
                    <div className="wrapper_line">
                      {console.log("this is = ", passWordChangedSuccessfully)}
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <InputField
                            onChangeText={
                              handleChange("password")
                            }
                            onBlur={handleBlur("password")}
                            touched={touched.password}
                            error={errors.password}
                            placeholder="New Password"
                            type="password"
                            value={values.password}
                          />
                        </div>
                        <div className="form-group">
                          <InputField
                            onChangeText={
                              handleChange("confirmPassword")
                            }
                            onBlur={handleBlur("confirmPassword")}
                            touched={touched.confirmPassword}
                            error={errors.confirmPassword}
                            placeholder="Confirm new password"
                            type="password"
                            value={values.confirmPassword}
                          />
                          {/* <input
                          className="form-control"
                          placeholder="Confirm new password"
                          autoComplete="none"
                          id={"confirmPassword"} type="password" name="confirmPassword"
                          onChange={handleChange('confirmPassword')}
                          onBlur={handleBlur}
                          value={values.confirmPassword.trim()} /> */}
                          {/* {touched.confirmPassword && errors.confirmPassword ? (
                          <div class='color-error'>{errors.confirmPassword}</div>
                        ) : null} */}
                        </div>
                        <div className="text-center button">
                          <button type="submit" className="button-list">{appconstant.send}</button>
                        </div>
                      </form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>}
          {(!passWordChangedSuccessfully && !isLinkExpire && !verificationCheckLoader) &&
            <div className="line_true">
              <div className="content-e login_1 ">
                <div className={"wrapper_line"}>
                  <div className="text-center cost">
                    <img src={images.logo} style={{ marginTop: '10px' }} />
                    <h6 class="text-white text-capitalize ps-3 login_2">{appconstant.resetpassword}</h6>
                  </div>


                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <span style={{ textAlign: "center", fontSize: "22px", marginTop: "20px", color: "black", marginTop: '50px' }}>{"Link has been expired."}</span>
                    <div style={{ marginTop: '60px' }}>
                      <span style={{ color: "black", fontSize: "smaller", display: "flex", justifyContent: "center", fontWeight: '600' }}>{'Copyright 2023 © Disparity Trap. All rights reserved.'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {(passWordChangedSuccessfully && !verificationCheckLoader) &&
            <div className="line_true">
              <div className="content-e login_1 ">
                <div className={"wrapper_line"}>
                  <div className="text-center cost">
                    <img src={images.logo} style={{ marginTop: '10px' }} />
                    <h6 class="text-white text-capitalize ps-3 login_2">{appconstant.resetpassword}</h6>
                  </div>


                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <span style={{ textAlign: "center", fontSize: "22px", marginTop: "20px", color: "black", marginTop: '40px' }}>{"Your password has been reset successfully."}</span>
                    <div style={{ marginTop: '60px' }}>
                      <span style={{ color: "black", fontSize: "smaller", display: "flex", justifyContent: "center", fontWeight: '600' }}>{'Copyright 2023 © Disparity Trap. All rights reserved.'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>) : null}
    </div>

  )
}
export default ResetPassword;


