const appconstant = {
  changepassword: 'Change Password',
  Oldpassword: 'Old Password',
  NewPassword: 'New Password',
  ConfirmNewPassword: 'Confirm New Password',
  contactus: 'Contact Us',
  home: 'Home',
  notifications: 'Notifications',
  viewmessage: 'View Messages',
  delete: 'Delete',
  EmailAddress: 'Email Address',
  GroupMessages: 'Group Messages ',
  IndividualMessages: 'Individual Messages',
  UserManagement: 'User Management',
  view: 'View',
  edit: 'Edit',
  block: 'Block',
  unBlock: 'Unblock',
  update: 'Update',
  export: 'Export',
  add: 'Add',
  send: 'Send',
  Password: 'Password',
  ConfrmPassword: 'Confirm Password',
  UploadCSV: 'Upload CSV',
  DownloadCSV: 'Download CSV format',

  UserDetails: 'User Details',
  Userid: 'User ID',
  UserName: 'User Name',
  EmailAddress: 'Email Address  ',
  gamesplayed: 'games played',
  DateofJoining: 'Date  Joined',
  GameOrganized: 'Games Created',
  gamesplayed: 'Games Played',
  edituserdetails: 'Edit User Details',
  voucheruserdetails: 'Voucher Code Management',
  addnewvoucher: "Add New Voucher",
  AddNewUser: "Add New User",
  CurrentlyHosting: "Currently Hosting",


  // 
  prelistedwords: 'Pre-Listed Words',
  export: 'Export',
  gamemanagement: 'Game Management',

  //
  EditAboutUs: 'Edit About Us',
  EditPrivacyPolicy: 'Edit Privacy Policy',
  AboutUs: 'About Us',
  GameSettings: 'Game Settings',
  PrivacyPolicy: 'Privacy Policy',
  ContactInfo: 'Contact Info',
  FAQs: 'FAQs',
  EditContactInfo: 'Edit Contact Info',
  AddNewFAQ: 'Add New FAQ',
  phonenumber: 'Phone Number',
  AddNewUser: 'Add New User',
  submit: 'Submit',
  cancel: 'Cancel',
  question: 'Question',
  answer: 'Answer',
  //
  buttonupate: 'Update',
  resetpassword: 'Reset Password',
  confirmnew: 'Confirm new password'


}
export default appconstant