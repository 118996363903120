const images = {

    logo: require('../images/logo.png').default,
    plus: require('../images/plus_five.png').default,
    usermessage: require('../images/user_message.png').default,
    dummy: require('../images/dummy.png').default,
    dice: require('../images/dice.svg').default,
    admin: require('../images/admin.png').default,


}

export default images;