import { createSelector } from 'reselect'

export const getMemoizedVouchersData = createSelector(
  (state) => state.vouchers,
  (vouchersState) => {
    const {
      vouchersListLoader,
      vouchersListSuccess,
      VouchersList,
      vouchersListPagination,
      offsetValue,
      searchValue,
      sortBy,
      sortOrder,
      addVoucherLoader,
      addVoucherSuccess,
      addVoucherDetail
    } = vouchersState

    return {
      vouchersListLoader,
      vouchersListSuccess,
      VouchersList,
      vouchersListPagination,
      offsetValue,
      searchValue,
      sortBy,
      sortOrder,
      addVoucherLoader,
      addVoucherSuccess,
      addVoucherDetail
    }
  }
)