import React, { useState } from "react"
import { Layout, Menu } from 'antd';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";
import images from "../themes/appImage";
import { Drawer } from 'antd';
import { LogoutAction } from '../redux/Action/Authentication';
import { isInternetConnected } from '../common/checkInternet'
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUnlock, faSignOutAlt, faGamepad, faHandshake, faCog } from "@fortawesome/free-solid-svg-icons";

const { Header, Content, Footer, Sider } = Layout;


const MobileSidebar = (props) => {

  console.log("res = = ", props.isVisible)
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };


  let history = useHistory();
  let location = useLocation();


  const onMenuClick = (e) => {
    history.push(e.key);
    if (e.key == '/login') {
      isInternetConnected() && dispatch(LogoutAction(history))
    }
  };


  let activeKey = ""
  switch (history.location.pathname) {
    case "/dashboard":
      activeKey = "/dashboard"
      break;
    case "/usermanagement":
      activeKey = "/usermanagement"
      break;
    case "/userview":
      activeKey = "/usermanagement"
      break;
    case "/useredit":
      activeKey = "/usermanagement"
      break;
    case "/adduser":
      activeKey = "/usermanagement"
      break;
    case "/newmanagement":
      activeKey = "/newmanagement"
      break;

    case "/voucher":
      activeKey = "/voucher"
      break;
    case "/gamemangement":
      activeKey = "/gamemangement"
      break;
    case "/prelistedwords":
      activeKey = "/gamemangement"
      break;

    case "/contactus":
      activeKey = "/contactus"
      break;

    case "/gamesettings":
      activeKey = "/gamesettings"
      break;
    case "/contactinfo":
      activeKey = "/gamesettings"
      break;
    case "/editcontactinfo":
      activeKey = "/gamesettings"
      break;
    case "/aboutus":
      activeKey = "/gamesettings"
      break;
    case "/editaboutus":
      activeKey = "/gamesettings"
      break;
    case "/viewprivacypolicy":
      activeKey = "/gamesettings"
      break;
    case "/editprivacypolicy":
      activeKey = "/gamesettings"
      break;
    case "/notifications":
      activeKey = "/notifications"
      break;
    case "/changepassword":
      activeKey = "/changepassword"
      break;
  }



  return (

    <Drawer placement="right"
      onClose={props.handlewClick}
      visible={props.isVisible}
      onClick={() => console.log("yes = = ")}>
      <Sider
        // breakpoint="lg"
        className="sider_left"
        collapsedWidth="0"
        width="250"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo">
          <Link to="/dashboard">
            <img src={images.logo} />
          </Link>
        </div>



        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={[activeKey]}
          selectedKeys={[activeKey]}
          onClick={(e) => onMenuClick(e)}>


          <Menu.Item key="/dashboard">
            <Link to="">
              <FontAwesomeIcon icon={faHome} />
              <span className="line_dash">Dashboard</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="/usermanagement">
            <FontAwesomeIcon icon={faUser} />
            <span className="line_dash"> User Management</span>
          </Menu.Item>

          <Menu.Item key="/voucher">
            <FontAwesomeIcon icon={faNewspaper} />
            <span className="line_dash">Voucher Code Management</span>
          </Menu.Item>


          <Menu.Item key="/gamemangement">

            <FontAwesomeIcon icon={faGamepad} />
            <span className="line_dash">Game Management</span>
          </Menu.Item>

          <Menu.Item key="/contactus">

            <FontAwesomeIcon icon={faHandshake} />
            <span className="line_dash">Contact Us</span>
          </Menu.Item>
          <Menu.Item key="/gamesettings">

            <FontAwesomeIcon icon={faCog} />
            <span className="line_dash">Game Settings</span>
          </Menu.Item>
          <Menu.Item key="/notifications">

            <FontAwesomeIcon icon={faBell} />
            <span className="line_dash">Notifications</span>
          </Menu.Item>


          <Menu.Item key="/changepassword">
            <FontAwesomeIcon icon={faUnlock} />
            <span className="line_dash">Change Password</span>
          </Menu.Item>


          <Menu.Item key="/login">
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span className="line_dash"> Logout</span>
          </Menu.Item>

        </Menu>
      </Sider>
    </Drawer>

  )
}
export default MobileSidebar;