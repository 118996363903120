import * as types from '../ActionTypes'

export const dashboardAction = (history) =>{
    const action = {
        type: types.API_DASHBOARD_LOAD,
        history
    }
    return action

}
