import { toast } from 'react-toastify';

export const isInternetConnected = (history) => {

    const customId = "custom-id-yes";

    if (!navigator.onLine) {
        toast.dismiss();
        toast.error('Please check your internet connection.', {
            position: toast.POSITION.TOP_RIGHT,
            toastId: customId
        });
        // localStorage.removeItem('token');
        // history.push('/login');
    }
    return navigator.onLine;
}