import React, { useState, useEffect } from "react"
import { Layout, Menu } from 'antd';
import images from '../../themes/appImage'

import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from 'antd';


const { Header, Content, Footer, Sider } = Layout;

const Chat = (props) => {
    const [isVisible, setVisible] = useState(false)
    const handlewClick = () => {
        setVisible(!isVisible)
    }
    useEffect(() => {
        document.title = 'Disparity Trap';
        window.scrollTo(0, 0)

    }, [])

    return (

        <Layout>
            <Sidebar />
            <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
            <Layout className="new_layout">
                <HeaderAdmin handlewClick={handlewClick} {...props} />
                <Breadcrumb>
                    <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to="/gamemangement"> Game Management</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>View messages</Breadcrumb.Item>
                </Breadcrumb>


                <Content className="chat_new_one" style={{ margin: '24px 16px 0' }}>
                    <button type="submit" class="button-list data_R">Group Messages </button>
                    <button type="submit" class="button-list data_R">Individual Messages </button>

                    <div className="chat_new">
                        <div className="right_chat">
                            <ul>
                                <li>
                                    <div className="imge_wrappe active">
                                        <div className="item_t">
                                            <img src={images.usermessage} />
                                            <span className="user-status"></span>
                                        </div>
                                        <span>Ankuj</span>
                                    </div> </li>
                                <li>
                                    <div className="imge_wrappe">
                                        <div className="item_t">
                                            <img src={images.usermessage} />
                                            <span className="user-status"></span>
                                        </div>
                                        <span>Kite</span>
                                    </div> </li>
                                <li>
                                    <div className="imge_wrappe">
                                        <div className="item_t">
                                            <img src={images.usermessage} />
                                            <span className="user-status"></span>
                                        </div>
                                        <span>John</span>
                                    </div> </li>
                                <li>
                                    <div className="imge_wrappe">
                                        <div className="item_t">
                                            <img src={images.usermessage} />
                                            <span className="user-status"></span>
                                        </div>
                                        <span>Jack</span>
                                    </div> </li>
                                <li>
                                    <div className="imge_wrappe">
                                        <div className="item_t">
                                            <img src={images.usermessage} />
                                            <span className="user-status"></span>
                                        </div>
                                        <span>Tom</span>
                                    </div> </li>
                                <li>
                                    <div className="imge_wrappe">
                                        <div className="item_t">
                                            <img src={images.usermessage} />
                                            <span className="user-status"></span>
                                        </div>
                                        <span>Brad</span>
                                    </div> </li>

                            </ul>
                        </div>
                        <div className="right_column">
                            <div className="text-details header">
                                <div className="item_t">
                                    <img src={images.usermessage} />
                                    <span className="user-status"></span>
                                </div>
                                <div className="lite_ew">
                                    <h4>ankuj</h4>
                                    <p>Writing...</p>
                                </div>
                            </div>
                            <div className="home-chat">
                                <div className="wrapper_cli">
                                    <div class="message-content">
                                        <h6 class="text-dark">Brittany Williams</h6>
                                        <span>Wow!  Today is October 30th! Halloween is tomorrow already!  Have you decided what you will be dressing up for Halloween yet, Sara?</span>
                                    </div>
                                    <div class="message-options">
                                        <div class="avatar avatar-sm"><img src={images.usermessage} /></div>
                                        <span class="message-date">9:12am</span>
                                    </div>
                                </div>
                                <div className="active_user wrapper_cli ">
                                    <div class="message-content ">
                                        <h6 class="text-dark">Brittany Williams</h6>
                                        <span>Wow!  Today is October 30th! Halloween is tomorrow already!  Have you decided what you will be dressing up for Halloween yet, Sara?</span>
                                    </div>
                                    <div class="message-options">
                                        <div class="avatar avatar-sm"><img src={images.usermessage} /></div>
                                        <span class="message-date">9:12am</span>
                                    </div>
                                </div>
                                <div className="wrapper_cli">
                                    <div class="message-content">
                                        <h6 class="text-dark">Brittany Williams</h6>
                                        <span>Wow!  Today is October 30th! Halloween is tomorrow already!  Have you decided what you will be dressing up for Halloween yet, Sara?</span>
                                    </div>
                                    <div class="message-options">
                                        <div class="avatar avatar-sm"><img src={images.usermessage} /></div>
                                        <span class="message-date">9:12am</span>
                                    </div>
                                </div>
                                <div className="active_user wrapper_cli ">
                                    <div class="message-content ">
                                        <h6 class="text-dark">Brittany Williams</h6>
                                        <span>Wow!  Today is October 30th! Halloween is tomorrow already!  Have you decided what you will be dressing up for Halloween yet, Sara?</span>
                                    </div>
                                    <div class="message-options">
                                        <div class="avatar avatar-sm"><img src={images.usermessage} /></div>
                                        <span class="message-date">9:12am</span>
                                    </div>
                                </div>
                            </div>
                            <div className="footer">
                                <div className="chat_point">
                                    <textarea className="form-control"></textarea>

                                </div>
                                <button type="submit" class="button-list top_new">Send </button>
                            </div>
                        </div>
                    </div>

                </Content>
            </Layout>
        </Layout>
    )
}
export default Chat
