import React, { useState, useEffect } from "react"
import { Layout, Menu } from 'antd';
import images from '../../themes/appImage'
import { isInternetConnected } from '../../common/checkInternet'


import {
  BrowserRouter as Router,
  Link,
  useHistory
} from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from 'antd';
import appconstant from "../../themes/appconstant";

import { getUserDetailInitiate } from "../../redux/Action/users"
import { useDispatch, useSelector } from "react-redux";
import { getMemoizedUsersData } from "../../redux/selectors/users"
import moment from 'moment'




const { Header, Content, Footer, Sider } = Layout;

const UserView = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isVisible, setVisible] = useState(false)
  const handlewClick = () => {
    setVisible(!isVisible)
  }
  const usersData = useSelector(getMemoizedUsersData)

  console.log("this is data = ", usersData)
  console.log(' props.history.location.state-------', props.history.location.state);
  const {
    userDetailLoader,
    userDetailSuccess,
    userDetail,
    userEditLoader,
  } = usersData

  console.log('userdetails-------', userDetail)

  useEffect(() => {
    document.title = 'Disparity Trap';
    window.scrollTo(0, 0)
    console.log('checkingprops', props)
    if (props.history.location.state == undefined) {
      history.push('/usermanagement')

    } else {
      if (localStorage.getItem('token')) {
        console.log("this is good")
        const data = {
          userId: props.history.location.state._id
        }
        isInternetConnected() && dispatch(getUserDetailInitiate(data, history))
      }
    }


    if (!localStorage.getItem('token')) {
      history.push('/login')
    }

  }, [])

  return (

    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} {...props} />
        <Breadcrumb>
          <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/usermanagement">{appconstant.UserManagement}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{appconstant.UserDetails}</Breadcrumb.Item>
        </Breadcrumb>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <div className="content-e">
              <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 class="text-white text-capitalize ps-3">{appconstant.UserDetails}</h6>
              </div>
              <div className="wrapper_line">
                <div className="">
                  <label>{appconstant.Userid}</label>
                  <p>{userDetail.userId ? userDetail.userId : 'N/A'}</p>
                </div>
                <div>
                  <label>{appconstant.UserName}</label>
                  <p>{userDetail.name ? userDetail.name : 'N/A'}</p>
                </div>

                <div>
                  <label>{appconstant.EmailAddress}</label>
                  <p>{userDetail.email ? userDetail.email : 'N/A'}</p>
                </div>
                <div>
                  <label>{appconstant.gamesplayed}</label>
                  <p>{userDetail.gamesPlayed ? userDetail.gamesPlayed : '0'}</p>
                </div>
                <div>
                  <label>{appconstant.CurrentlyHosting}</label>
                  <p>{userDetail.hosting ? 'Yes' : 'No'}</p>
                </div>
                <div>
                  <label>{appconstant.DateofJoining}</label>
                  <p>{userDetail.createdOn ? moment(userDetail.createdOn).format("DD/MM/YYYY") : 'N/A'}</p>
                </div>
                <div className="text-center button">
                  <button onClick={() => props.history.push("/gameorganised", props.history.location.state)} type="submit" className="button-list">{appconstant.GameOrganized}</button>
                  <button onClick={() => props.history.push("/gameplayed", props.history.location.state)} type="submit" className="button-list">{appconstant.gamesplayed}</button>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
export default UserView
