import React, { useState, useEffect, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Layout, Menu, Tabs, Table, Breadcrumb, Modal, Pagination, Spin } from 'antd';
import {
  Link,
  useHistory
} from "react-router-dom";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'

import images from '../../themes/appImage'
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import InputField from '../../customComponent/input'
import { getMemoizedGameSettingsData } from "../../redux/selectors/gameSettings"
import {
  getFAQListInitiate,
  addFAQInitiate,
  updateAction,
  getFAQDetailInitiate,
  editFAQInitiate,
  deleteFAQInitiate,
  getContactDetailInitiate,
  editContactInitiate,
  getAboutDetailInitiate,
  editAboutInitiate,
  getPrivacyDetailInitiate,
  editPrivacyInitiate,
  getTermsInitiate,
  editTermsInitiate
} from "../../redux/Action/gameSettings"
import { removeEmojis } from "../../common/utils"
import Linkify from 'react-linkify';

const { TabPane } = Tabs;

const { Content } = Layout;

const addFaqInitialValues = {
  addFaqQuestion: '',
  addFaqValue: ''
}

console.log("this is welcome home");

const contactInitialValues = {
  editContactPhone: '',
  editContactEmail: ''
}

const GameSettings = (props) => {

  // console.log("this is welcome home", addFaqInitialValues);

  const dispatch = useDispatch()
  const history = useHistory()

  const gameSettingsData = useSelector(getMemoizedGameSettingsData)
  const {
    faqListLoader,
    faqList,
    offsetValue,
    searchValue,
    sortBy,
    sortOrder,
    faqListPagination,
    faqDetail,
    addFaqLoader,
    addFaqSuccess,
    editFaqLoader,
    editFaqSuccess,
    deleteFaqSuccess,
    contactDetail,
    contactDetailLoader,
    contactDetailSuccess,
    editContactSuccess,
    editContactLoader,
    aboutDetail,
    editAboutSuccess,
    privacyDetail,
    terms,
    editPrivacySuccess,
    editTermsSuccess,
    faq,
  } = gameSettingsData


  const [search, setSearch] = useState('')
  const [isVisible, setVisible] = useState(false)
  const [contact, setEditcontact] = useState('1');
  const [aboutus, setAboutUS] = useState('1');
  const [Policy, setPolicy] = useState('1');
  const [Terms, setTerms] = useState('1');
  const [activeTabName, setActiveTabName] = useState("FAQs")
  console.log('faqCheck', faq, '=====', gameSettingsData)

  useLayoutEffect(() => {
    document.title = 'Disparity Trap';
    console.log('###########', activeTabName, Terms);
    if (activeTabName === 'FAQs' && faq === '1') {
      const data = {
        search: searchValue,
        sort: sortBy,
        order: sortOrder,
        offset: searchValue ? 0 : offsetValue,
        limit: 20,
      }
      dispatch(getFAQListInitiate(data, history))
    } else if (activeTabName === 'FAQs' && (faq === '3' || faq === '4')) {
      const data = props?.history?.location?.state?._id
      dispatch(getFAQDetailInitiate(data, history))
    } else if (activeTabName === 'Contact Info') {
      dispatch(getContactDetailInitiate(null, history))
    } else if (activeTabName === 'About Us' && aboutus === "1") {
      dispatch(getAboutDetailInitiate(null, history))
    } else if (activeTabName === 'Privacy Policy' && Policy === '1') {
      dispatch(getPrivacyDetailInitiate(null, history))
    } else if (activeTabName === 'Terms & Conditions' && Terms === '1') {

      dispatch(getTermsInitiate(null, history))
    }

  }, [faq, activeTabName])

  useLayoutEffect(() => {
    // if (addFaqSuccess) {
    //   setFaq('1')
    //   dispatch(updateAction(false, 'addFaqSuccess'))
    // } else
    if (editFaqSuccess) {
      // setFaq('1')
      dispatch(updateAction('1', 'faq'))
      dispatch(updateAction(false, 'editFaqSuccess'))
    } else if (deleteFaqSuccess) {
      const data = {
        search: searchValue,
        sort: sortBy,
        order: sortOrder,
        offset: searchValue ? 0 : offsetValue,
        limit: 20,
      }
      dispatch(getFAQListInitiate(data, history))
      dispatch(updateAction(false, 'deleteFaqSuccess'))
    } else if (editContactSuccess) {
      setEditcontact('1')
      dispatch(getContactDetailInitiate(null, history))
      dispatch(updateAction(false, 'editContactSuccess'))
    } else if (editAboutSuccess) {
      setAboutUS('1')
      dispatch(getAboutDetailInitiate(null, history))
      dispatch(updateAction(false, 'editAboutSuccess'))
    } else if (editPrivacySuccess) {
      setPolicy('1')
      dispatch(getPrivacyDetailInitiate(null, history))
      dispatch(updateAction(false, 'editPrivacySuccess'))
    } else if (editTermsSuccess) {
      setTerms('1')
      dispatch(getTermsInitiate(null, history))
      dispatch(updateAction(false, 'editTermsSuccess'))
    }
  }, [addFaqSuccess, editFaqSuccess, deleteFaqSuccess, editContactSuccess, editAboutSuccess, editPrivacySuccess, editTermsSuccess])



  useLayoutEffect(() => {
    if (contactDetail) {
      contactInitialValues.editContactPhone = contactDetail.phone
      contactInitialValues.editContactEmail = contactDetail.email
    }
  }, [contactDetail])


  const handlTableChange = (pagination, filter, sorter) => {
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      if (sorter.order == "ascend") {
        const data = {
          search: searchValue,
          sort: sorter.field,
          order: 1,
          offset: searchValue ? 0 : offsetValue,
          limit: 20
        }
        dispatch(updateAction(1, 'sortOrder'))
        dispatch(updateAction(sorter.field, 'sortBy'))
        dispatch(getFAQListInitiate(data, history))

      } //To fetch listing in descending order
      else if (sorter.order === "descend") {
        const data = {
          search: searchValue,
          sort: sorter.field,
          order: -1,
          offset: searchValue ? 0 : offsetValue,
          limit: 20
        }
        dispatch(updateAction(-1, 'sortOrder'))
        dispatch(updateAction(sorter.field, 'sortBy'))
        dispatch(getFAQListInitiate(data, history))
      } //To fetch listing in normal order
      else {
        const data = {
          search: searchValue,
          sort: null,
          order: null,
          offset: searchValue ? 0 : offsetValue,
          limit: 20
        }
        dispatch(updateAction(null, 'sortOrder'))
        dispatch(updateAction(null, 'sortBy'))
        dispatch(getFAQListInitiate(data, history))
      }
    }
  }

  const handleSearch = (e) => {
    let searchWord = e.target.value
    let isOnlyBlankSpace = false
    if (searchWord.charAt(0) === " ") {
      if (searchWord.length == 1 && searchWord == " ") {
        isOnlyBlankSpace = true
      }
      searchWord = searchWord.replace(/^ +/gm, '')
      setSearch(searchWord.replace(/^ +/gm))
      dispatch(updateAction(searchWord, 'searchValue'))
    } else {
      dispatch(updateAction(searchWord, 'searchValue'))
      setSearch(searchWord)
    }
    if (isOnlyBlankSpace === false) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: null,
        order: null,
        offset: searchWord.trim().length === 0 ? offsetValue : 0,
        limit: 20
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {

        dispatch(getFAQListInitiate(data, history))
      }
    } else if (searchWord !== search) {
      isOnlyBlankSpace = false
      const data = {
        search: searchWord,
        sort: "",
        order: null,
        offset: searchWord.trim().length == 0 ? offsetValue : 0,
        limit: 20
      }
      if (!navigator.onLine) {
        toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
      } else {

        dispatch(getFAQListInitiate(data, history))
      }
    }
  }

  const deleteModel = (record) => {

    Modal.confirm({
      title: "Delete FAQ",
      content: `Are you sure, you want to delete this FAQ?`,
      okText: 'Yes',
      centered: true,
      cancelText: 'No',
      onOk() {
        if (!navigator.onLine) {
          toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
        } else {
          dispatch(deleteFAQInitiate(record?._id))
        }
      },
      onCancel() {

      },
      className: "new-button"
    });
  };


  const handlewClick = () => {
    setVisible(!isVisible)
  }

  const columns = [{
    title: 'Sr. No.',
    dataIndex: 'srno',
    key: 'srno',
    sorter: false,
    render: (value, item, index) => <div style={{ width: '60px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{(index + 1 + offsetValue)}</div>,
  },
  {
    title: 'Questions',
    dataIndex: 'question',
    key: 'question',
    sorter: true,
  },
  {
    title: 'Action',
    dataIndex: 'Posted',
    key: 'Posted',
    render: (text, record) => {
      return (<div>
        <button
          type="submit"
          onClick={(e) => {
            if (!navigator.onLine) {
              toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
            } else {
              props.history.push("/gamesettings", record)
              // setFaq('3')
              dispatch(updateAction('3', 'faq'))
            }

            //setViewData(true)
          }}
        > View </button>
        <button
          type="submit"
          onClick={(e) => {
            if (!navigator.onLine) {
              toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
            } else {
              props.history.push("/gamesettings", record)
              // setFaq('4')
              dispatch(updateAction('4', 'faq'))
            }

          }}
        >
          Edit
        </button>
        <button
          type="submit"
          onClick={() => {
            deleteModel(record)
          }}
        >
          Delete
        </button>
      </div>
      )
    }
  },
  ];

  const addFaqValidationSchema = Yup.object().shape({
    addFaqQuestion: Yup.string()
      .test('trim', 'Please enter question.', value => value?.trim()?.length > 0)
      .min(3, 'Question should be at least 3 characters long.')
      // .min(20, 'Username should be at least 3 characters long.', value => value?.trim()?.length > 0)
      .required("Please enter question."),
    addFaqValue: Yup.string()
      .required("Please enter answer.")
      .min(3, 'Answer should be at least 3 characters long.'),
  });



  const editFaqValidationSchema = Yup.object().shape({
    editFaqQuestion: Yup.string()
      .test('trim', 'Please enter question.', value => value?.trim()?.length > 0)
      .min(3, 'Question should be at least 3 characters long.')
      // .min(20, 'Username should be at least 3 characters long.', value => value?.trim()?.length > 0)
      .required("Please enter question."),
    editFaqValue: Yup.string()
      .required("Please enter answer.")
      .min(3, 'Answer should be at least 3 characters long.')
  });

  const editContactInfoValidationSchema = Yup.object().shape({
    editContactPhone: Yup.string()
      .required("Please enter phone number.")
      .matches(/[1-9][0-9]{7,14}/, 'Phone number should be between 8 to 15 digits.'),
    editContactEmail: Yup.string()
      .required("Please enter email address.")
      .email("Please enter valid email address.")
      .matches(
        /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/,
        "Please enter valid email address."
      ),
  });

  const editAboutInfoValidationSchema = Yup.object().shape({
    aboutUsValue: Yup.string()
      .required("Please enter about us.")
      .min(3, 'About us should be at least 3 characters long.')
  })

  const editPrivacyPolicyValidationSchema = Yup.object().shape({
    privacyPolicyValue: Yup.string()
      .required("Please enter privacy policy.")
      .min(3, 'Privacy policy should be at least 3 characters long.')
  })
  const editTermsValidationSchema = Yup.object().shape({
    termsValue: Yup.string()
      .required("Please enter terms & conditions.")
      .min(3, 'Terms & Conditions should be at least 3 characters long.')
  })

  // const _checkSpecialChar = (fieldValue) => {
  //   console.log('_checkSpecialChar', fieldValue)
  //   let specialChar = ['`', '+', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '+', '=', '{', '}', '[', ']', '|', ';', ':', '"', ',', '<', '>', '?', '/', '~', '_']
  //   let isSpecialChar = false;
  //   for (let i in specialChar) {
  //     if (fieldValue.includes(specialChar[i])) {
  //       isSpecialChar = true
  //     }
  //   }
  //   return isSpecialChar
  // }

  const handleAddWordChange = (data, name, updateValue) => {
    let value = data.target.value
    let reg = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/
    if (value === " ") {
      updateValue(name, "")
    } else {
      updateValue(name, value.trimLeft())
    }
  }

  const handleFormSubmitAddFaq = (values, { setSubmitting, resetForm }) => {
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      const data = {
        question: values.addFaqQuestion,
        answer: values.addFaqValue,
      }
      dispatch(addFAQInitiate(data, history))
    }
  }

  const handleFormSubmitEditFaq = (values, { setSubmitting }) => {
    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      const data = {
        faqId: faqDetail._id,
        question: values.editFaqQuestion,
        answer: values.editFaqValue,
      }
      dispatch(editFAQInitiate(data, history))
    }

  }

  const handleFormSubmitEditContactInfo = (values, { setSubmitting }) => {

    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      const data = {
        email: values.editContactEmail,
        phone: values.editContactPhone
      }
      dispatch(editContactInitiate(data, history))
    }

  }

  const handleFormSubmitEditAboutUs = (values, { setSubmitting }) => {

    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      const data = {
        aboutUs: values.aboutUsValue
      }
      dispatch(editAboutInitiate(data, history))
    }
  }

  const handleFormSubmitEditPrivacyPolicy = (values, { setSubmitting }) => {

    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      const data = {
        privacyPolicy: values.privacyPolicyValue
      }
      dispatch(editPrivacyInitiate(data, history))
    }
  }

  const handleFormSubmitEditTerms = (values, { setSubmitting }) => {

    if (!navigator.onLine) {
      toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
    } else {
      const data = {
        terms: values.termsValue
      }
      dispatch(editTermsInitiate(data, history))
    }
  }

  const faqListFragment = (
    <React.Fragment>
      <div className="top_button_item result" style={{ marginTop: '12px' }}>
        <button type="submit" className="button-list top_new" onClick={(e) => {
          // setFaq('2')
          dispatch(updateAction('2', 'faq'))
        }}>Add New FAQ </button>
      </div>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line line2">
            <h6 class="text-white text-capitalize ps-3">{appconstant.FAQs}</h6>
            <input
              type="text"
              className="search-bar"
              placeholder="Search"
              value={searchValue}
              autoComplete="off"
              name="search"
              onChange={(e) => {
                handleSearch(e)
              }}
            />
          </div>
          <Table
            dataSource={faqList}
            columns={columns}
            showSorterTooltip={false}
            loading={faqListLoader}
            pagination={false}
            onChange={handlTableChange}
          />
          <Pagination
            pageSize="20"
            total={faqListPagination?.totalCount}
            onChange={(page) => {
              if (!navigator.onLine) {
                toast.error('Please check your internet connection.', { position: "top-right", toastId: 'custom_msg' })
              } else {
                let data = (page - 1) * 20;
                const dta = {
                  search: searchValue,
                  sort: sortBy,
                  order: sortOrder,
                  offset: data,
                  limit: 20,
                }
                dispatch(updateAction(data, 'offsetValue'))
                dispatch(getFAQListInitiate(dta, history))
              }
            }}
            current={faqListPagination?.currentPage}
            showSizeChanger={false}
          />
        </div>
      </div>
    </React.Fragment>
  )

  const AddFaqFragment = () => {
    console.log("again load = ",)
    return (

      <div className="site-layout-background label_space" style={{ padding: 24, minHeight: 360 }}>
        <Spin className="page_loading" tip="Loading..." spinning={addFaqLoader} size={"large"}>
          <div className="content-e">
            <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <h6 class="text-white text-capitalize ps-3">Add New FAQ</h6>
            </div>
            <div className="wrapper_line">
              <Formik
                enableReinitialize
                initialValues={{ addFaqQuestion: '', addFaqValue: '' }}
                validationSchema={addFaqValidationSchema}
                onSubmit={handleFormSubmitAddFaq}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  setFieldValue
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>{"Add Question"}</label>
                      <InputField
                        onChangeText={handleChange('addFaqQuestion')}
                        onBlur={handleBlur('addFaqQuestion')}
                        value={values.addFaqQuestion}
                        touched={touched.addFaqQuestion}
                        error={errors.addFaqQuestion}
                        placeholder="Question"
                        // type='noSpace'
                        fieldType='addFaqQuestion'
                      // maxLength={100}
                      />
                    </div>
                    <div className="form-group">
                      <label>{"Add Answer"}</label>
                      <textarea
                        className="form-control data"
                        placeholder="Answer"
                        name="addFaqValue"
                        value={values.addFaqValue}
                        onChange={(e) => {
                          handleAddWordChange(e, 'addFaqValue', setFieldValue)
                        }}
                        onBlur={handleBlur('addFaqValue')}
                      // maxLength={500}
                      // touched={touched.addFaqValue}
                      // error={errors.addFaqValue}
                      >

                      </textarea>

                      {(errors.addFaqValue) && <div style={{ color: 'red' }}>{errors.addFaqValue}</div>}
                    </div>
                    <div className="text-center button">
                      <button
                        type="submit"
                        className="button-list"
                      >
                        {appconstant.add}
                      </button>
                      <button
                        type="button"
                        className="button-list"
                        onClick={(e) => {
                          // setFaq('1')
                          dispatch(updateAction('1', 'faq'))
                        }}
                      >
                        {appconstant.cancel}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

          </div>
        </Spin>
      </div>

    )
  }

  const viewFaqDetails = (
    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
      <div className="content-e">
        <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
          <h6 class="text-white text-capitalize ps-3">{"FAQ Details"}</h6>
        </div>
        <div className="wrapper_line">
          <div className="">
            <label>{appconstant.question}</label>
            <p>{faqDetail?.question ? faqDetail.question : 'N/A'}</p>
          </div>
          <div>
            <label>{appconstant.answer}</label>
            <p>{faqDetail?.answer ? faqDetail.answer : 'N/A'}</p>
          </div>





          <div className="text-center button">
            <button type="submit" className="button-list" onClick={(e) => {
              // setFaq('1')
              dispatch(updateAction('1', 'faq'))
            }}>{appconstant.cancel}</button>
          </div>
        </div>
      </div>
    </div>
  )

  const EditFaqDetails = () => {

    let initialValues = {
      editFaqQuestion: '',
      editFaqValue: ''
    }

    useEffect(() => {
      if (faqDetail) {
        initialValues.editFaqQuestion = faqDetail.question
        initialValues.editFaqValue = faqDetail.answer

      }
    }, [faqDetail])

    return (

      <div className="site-layout-background label_space" style={{ padding: 24, minHeight: 360 }}>
        <Spin className="page_loading" tip="Loading..." spinning={editFaqLoader} size={"large"}>
          <div className="content-e">
            <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <h6 class="text-white text-capitalize ps-3">Edit FAQ</h6>
            </div>
            <div className="wrapper_line">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={editFaqValidationSchema}
                onSubmit={handleFormSubmitEditFaq}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  setFieldValue
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>{"Add Question"}</label>
                      <InputField
                        onChangeText={handleChange('editFaqQuestion')}
                        onBlur={handleBlur('editFaqQuestion')}
                        value={values.editFaqQuestion}
                        touched={touched.editFaqQuestion}
                        error={errors.editFaqQuestion}
                        placeholder="Question"
                        // type='noSpace'
                        // fieldType='userName'
                        // maxLength={100}
                        fieldType='addFaqQuestion'
                      />
                    </div>
                    <div className="form-group">
                      <label>{"Add Answer"}</label>
                      <textarea
                        className="form-control data"
                        placeholder="Answer"
                        name="editFaqValue"
                        value={values.editFaqValue}
                        onChange={(e) => {
                          handleAddWordChange(e, 'editFaqValue', setFieldValue)
                        }}
                      // maxLength={500}
                      >

                      </textarea>
                      {console.log('gameSettings', errors, ':::::::', touched)}
                      {errors.editFaqValue && <div style={{ color: 'red' }}>{errors.editFaqValue}</div>}
                    </div>
                    <div className="text-center button">
                      <button
                        type="submit"
                        className="button-list"
                        onClick={(e) => {
                          // setFaq('1')
                        }}
                      >
                        {appconstant.update}
                      </button>
                      <button
                        type="button"
                        className="button-list"
                        onClick={(e) => {
                          // setFaq('1')
                          dispatch(updateAction('1', 'faq'))
                        }}
                      >
                        {appconstant.cancel}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Spin>
      </div>

    )

  }

  const EditContactInfo = () => {

    return (
      <Formik
        enableReinitialize
        initialValues={contactInitialValues}
        validationSchema={editContactInfoValidationSchema}
        onSubmit={handleFormSubmitEditContactInfo}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

              <div className="content-e">
                {contact === '1' ? (
                  <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                    <h6 class="text-white text-capitalize ps-3">Contact Info</h6>
                  </div>) : (
                  <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                    <h6 class="text-white text-capitalize ps-3">Edit Contact Info</h6>
                  </div>
                )}
                <div className="wrapper_line">
                  <div className="form-group">
                    <label>{appconstant.phonenumber}</label>
                    {console.log('values.editContactPhone', values.editContactPhone)}
                    <InputField
                      // type="number"
                      onChangeText={handleChange('editContactPhone')}
                      onBlur={handleBlur('editContactPhone')}
                      value={values.editContactPhone}
                      touched={touched.editContactPhone}
                      error={errors.editContactPhone}
                      placeholder="Phone Number"
                      // type='noSpace'
                      fieldType='phone'
                      noMargin
                      disabled={contact == '1'}
                      maxLength={16}
                    />
                    {errors.phoneNumber && <div>{errors.phoneNumber}</div>}
                  </div>
                  <div>
                    <div className="form-group">
                      <label>{appconstant.EmailAddress}</label>
                      <InputField
                        onChangeText={handleChange('editContactEmail')}
                        value={values?.editContactEmail?.trim()}
                        touched={touched.editContactEmail}
                        error={errors.editContactEmail}
                        placeholder="Email Address"
                        fieldType="spaceRestrict"
                        onBlur={handleBlur('editContactEmail')}
                        noMargin
                        disabled={contact == '1'}
                      />
                    </div>
                  </div>
                  <div className="text-center button">
                    {
                      contact == '1' ? (
                        <button
                          type="submit"
                          className="button-list"
                          onClick={(e) => {
                            setEditcontact('2')
                          }}
                        >
                          Edit Info
                        </button>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="button-list"
                            onClick={() => {
                              // setEditcontact('1')
                            }}
                          >
                            {appconstant.update}
                          </button>
                          <button
                            type="button"
                            className="button-list"
                            onClick={() => setEditcontact('1')}
                          >
                            {appconstant.cancel}
                          </button>
                        </>
                      )
                    }



                  </div>
                </div>
              </div>

            </div>
          </form>
        )}
      </Formik>
    )
  }

  const viewAboutUs = () => {
    let string = aboutDetail?.aboutUs
    return (
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 class="text-white text-capitalize ps-3">{appconstant.AboutUs}</h6>
          </div>
          <div className="wrapper_line">
            <div className="">
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >{string}</Linkify>

              {/* <span className="value_paragraph">
                {aboutDetail?.aboutUs}</span> */}
            </div>
            <div className="text-center button">
              <button type="submit" className="button-list" onClick={() => setAboutUS('2')}>Edit Info</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const EditAboutUs = () => {

    let initialValues = {
      aboutUsValue: '',
    }

    useEffect(() => {
      if (faqDetail) {
        initialValues.aboutUsValue = aboutDetail?.aboutUs
      }
    }, [faqDetail])


    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={editAboutInfoValidationSchema}
        onSubmit={handleFormSubmitEditAboutUs}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <div className="content-e">
                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                  <h6 class="text-white text-capitalize ps-3">Edit About Us</h6>
                </div>
                <div className="wrapper_line">
                  <div className="form-group">
                    <textarea
                      className="form-control data"
                      placeholder="About Us"
                      name="aboutUsValue"
                      value={values.aboutUsValue}
                      onChange={(e) => {
                        handleAddWordChange(e, 'aboutUsValue', setFieldValue)
                      }}
                    // maxLength={500}
                    >

                    </textarea>
                    {errors.aboutUsValue && <div style={{ color: 'red' }}>{errors.aboutUsValue}</div>}
                  </div>
                  <div className="text-center button">
                    <button
                      type="submit"
                      className="button-list"
                    >
                      {appconstant.update}
                    </button>
                    <button type="submit" className="button-list" onClick={() => setAboutUS('1')}>{appconstant.cancel}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }

  const viewPrivacyPolicyDetails = () => {
    let string = privacyDetail?.privacyPolicy
    return (
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 class="text-white text-capitalize ps-3">Privacy Policy
            </h6>
          </div>
          <div className="wrapper_line breakWord">
            <div className="">
              {/* <span className="value_paragraph">{privacyDetail?.privacyPolicy}</span> */}
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >{string}</Linkify>

            </div>
            <div className="text-center button">
              <button type="submit" className="button-list" onClick={() => setPolicy('2')}>Edit Info</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const EditPrivacyPolicy = () => {

    let initialValues = {
      privacyPolicyValue: '',
    }

    useEffect(() => {
      if (faqDetail) {
        initialValues.privacyPolicyValue = privacyDetail?.privacyPolicy
      }
    }, [faqDetail])

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={editPrivacyPolicyValidationSchema}
        onSubmit={handleFormSubmitEditPrivacyPolicy}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <div className="content-e">
                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                  <h6 class="text-white text-capitalize ps-3">Edit Privacy Policy</h6>
                </div>
                <div className="wrapper_line">
                  <div className="form-group">
                    <textarea
                      className="form-control data"
                      placeholder="Privacy Policy"
                      name="privacyPolicyValue"
                      value={values.privacyPolicyValue}
                      onChange={(e) => {
                        handleAddWordChange(e, 'privacyPolicyValue', setFieldValue)
                      }}
                    // maxLength={500}
                    >

                    </textarea>
                    {errors.privacyPolicyValue && <div style={{ color: 'red' }}>{errors.privacyPolicyValue}</div>}
                  </div>
                  <div className="text-center button">
                    <button
                      type="submit"
                      className="button-list"
                    >
                      {appconstant.update}
                    </button>
                    <button type="submit" className="button-list" onClick={() => setPolicy('1')}>{appconstant.cancel}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }
  const viewTermsDetails = () => {
    console.log('##########', terms);
    let string = terms?.terms
    return (
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 class="text-white text-capitalize ps-3">Terms & Conditions
            </h6>
          </div>
          <div className="wrapper_line breakWord">
            <div className="">
              {/* <span className="value_paragraph">{privacyDetail?.privacyPolicy}</span> */}
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >{string}</Linkify>

            </div>
            <div className="text-center button">
              <button type="submit" className="button-list" onClick={() => setTerms('2')}>Edit Info</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const EditTerms = () => {

    let initialValues = {
      termsValue: '',
    }

    useEffect(() => {
      if (terms) {
        initialValues.termsValue = terms?.terms
      }
    }, [terms])

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={editTermsValidationSchema}
        onSubmit={handleFormSubmitEditTerms}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <div className="content-e">
                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                  <h6 class="text-white text-capitalize ps-3">Edit Terms & Conditions</h6>
                </div>
                <div className="wrapper_line">
                  <div className="form-group">
                    <textarea
                      className="form-control data"
                      placeholder="Terms & Conditions"
                      name="termsValue"
                      value={values.termsValue}
                      onChange={(e) => {
                        handleAddWordChange(e, 'termsValue', setFieldValue)
                      }}
                    // maxLength={500}
                    >

                    </textarea>
                    {errors.termsValue && <div style={{ color: 'red' }}>{errors.termsValue}</div>}
                  </div>
                  <div className="text-center button">
                    <button
                      type="submit"
                      className="button-list"
                    >
                      {appconstant.update}
                    </button>
                    <button type="submit" className="button-list" onClick={() => setTerms('1')}>{appconstant.cancel}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }

  const content = () => {

    return (
      <Tabs defaultActiveKey="1" onTabClick={(e) => {
        if (activeTabName === 'FAQs') {
          // setFaq('1')
          dispatch(updateAction('1', 'faq'))
        } else if (activeTabName === 'Contact Info') {
          setEditcontact('1')
        } else if (activeTabName === 'About Us') {
          setAboutUS('1')
        } else if (activeTabName === 'Privacy Policy') {
          setPolicy('1')
        } else if (activeTabName === 'Terms & Conditions') {
          setTerms('1')
        }
        setActiveTabName(e)
      }
      }>
        <TabPane tab="FAQs" key="FAQs"  >
          {faq === '1' && faqListFragment}
          {faq === '2' && <AddFaqFragment />}
          {faq === '3' && viewFaqDetails}
          {faq === '4' && <EditFaqDetails />}
        </TabPane>
        <TabPane tab="Contact Info" key="Contact Info">
          {(contact) && <EditContactInfo />}
        </TabPane>
        <TabPane tab="About Us" key="About Us">
          {aboutus === '1' ? viewAboutUs() : <EditAboutUs />}
        </TabPane>
        <TabPane tab="Privacy Policy" key="Privacy Policy">
          {Policy === '1' ? viewPrivacyPolicyDetails() : <EditPrivacyPolicy />}
        </TabPane>
        <TabPane tab="Terms & Conditions" key="Terms & Conditions">
          {Terms === '1' ? viewTermsDetails() : <EditTerms />}
        </TabPane>
      </Tabs>
    )
  }

  return (
    <div>
      <Layout>
        <Sidebar />
        <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />

        <Layout className="new_layout">
          <HeaderAdmin handlewClick={handlewClick} {...props} />
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">{appconstant.home}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>{appconstant.GameSettings}</Breadcrumb.Item>
          </Breadcrumb>
          <Content style={{ margin: '24px 16px 0' }}>
            {content()}
          </Content>
        </Layout>
      </Layout>
      {/* {vouchercode()} */}
    </div>
  )
}
export default GameSettings;
