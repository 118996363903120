import { SERVER_URL } from '../../constant';
import axios from "axios";

let APIKit = axios.create({
    baseURL: SERVER_URL,
    timeout: 300000,
});

export const cancelTokenSource = axios.CancelToken.source();
APIKit.interceptors.request.use(async (config) => {
    const tokenData = localStorage.getItem('token')
    console.log("tokenData = ", tokenData)
    if (tokenData) {
        config.headers['Authorization'] = `Bearer ${tokenData}`;
    }
    //console.log(config, 'config@@@@@')
    return config;
});
export default APIKit;