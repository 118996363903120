import { createSelector } from 'reselect'

export const getMemoizedGamesData = createSelector(
  (state) => state.games,
  (gamesState) => {
    const {
      gamesListLoader,
      gamesListSuccess,
      gamesList,
      offsetValue,
      searchValue,
      sortBy,
      sortOrder,
      startDate,
      endDate,
      gamesListPagination,
      exportGamesListLoader,
      exportGamesListSuccess,
      exportGamesList,
      preListedListLoader,
      preListedListSuccess,
      preListedList,
      preListedOffsetValue,
      preListedSearchValue,
      preListedSortBy,
      preListedSortOrder,
      preListedListPagination,
      addPreListedWordLoader,
      addPreListedWordSuccess,
      editPreListedWordLoader,
      editPreListedWordSuccess,
      editPreListedWordDetail,
      deletePreListedWordLoader,
      deletePreListedWordSuccess
    } = gamesState

    return {
      gamesListLoader,
      gamesListSuccess,
      gamesList,
      offsetValue,
      searchValue,
      sortBy,
      sortOrder,
      startDate,
      endDate,
      gamesListPagination,
      exportGamesListLoader,
      exportGamesListSuccess,
      exportGamesList,
      preListedListLoader,
      preListedListSuccess,
      preListedList,
      preListedOffsetValue,
      preListedSearchValue,
      preListedSortBy,
      preListedSortOrder,
      preListedListPagination,
      addPreListedWordLoader,
      addPreListedWordSuccess,
      editPreListedWordLoader,
      editPreListedWordSuccess,
      editPreListedWordDetail,
      deletePreListedWordLoader,
      deletePreListedWordSuccess
    }
  }
)